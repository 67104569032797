import { RootState } from '@/app/store';
import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  state: 'fullfilled' | 'unpaid' | 'endTrial' | 'outScope' | 'slotOutScope' | 'noSlot' | 'ended';
} = {
  state: 'fullfilled',
};

export const enterpriseSlice = createSlice({
  name: 'enterprise',
  initialState,
  reducers: {
    setEnterpriseUnpaid(state) {
      state.state = 'unpaid';
    },
    setEnterpriseEndTrial(state) {
      state.state = 'endTrial';
    },
    setEnterpriseNoSlot(state) {
      state.state = 'noSlot';
    },
    setEnterpriseEnded(state) {
      state.state = 'ended';
    },
    unSetEnterprise(state) {
      state.state = 'fullfilled';
    },
  },
});

export const selectEnterprise = (state: RootState): typeof initialState => state.enterprise;
const enterpriseReducer = enterpriseSlice.reducer;
export default enterpriseReducer;
export const {
  setEnterpriseUnpaid,
  setEnterpriseEndTrial,
  unSetEnterprise,
  setEnterpriseNoSlot,
  setEnterpriseEnded,
} = enterpriseSlice.actions;
