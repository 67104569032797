import { useCallback, useMemo, useState } from 'react';
import apiWrapper from '../api';
import { RTKResult } from './fiiErrorParser';

export default function UseIsAdmin(props?: { level: 'am' | 'admin' }) {
  const [getIsAdmin, { data: isAdmin, ...rest }] = apiWrapper.useLazyGetCanQuery();
  const [role, setRole] = useState<undefined | 'am' | 'admin' | 'triage'>();
  const level = useMemo(() => props?.level || 'triage', [props]);
  const [checkFinished, setCheckFinished] = useState(false);

  const get = useCallback(async () => {
    const adminRes = (await getIsAdmin(
      {
        namespace: 'admin',
        object: 'admin',
        relation: 'member',
      },
      false
    )) as RTKResult<boolean>;
    if (adminRes.data) {
      setCheckFinished(true);
      setRole('admin');
      return adminRes;
    } else if (adminRes.error || level === 'admin') {
      setCheckFinished(true);
      return adminRes;
    }

    const amRes = (await getIsAdmin(
      {
        namespace: 'am',
        object: 'am',
        relation: 'member',
      },
      false
    )) as RTKResult<boolean>;
    if (amRes.data) {
      setCheckFinished(true);
      setRole('am');
      return amRes;
    } else if (amRes.error || level === 'am') {
      setCheckFinished(true);
      return amRes;
    }

    const triageRes = (await getIsAdmin(
      {
        namespace: 'am',
        object: 'triage',
        relation: 'member',
      },
      false
    )) as RTKResult<boolean>;
    if (triageRes.data) {
      setRole('triage');
    }
    setCheckFinished(true);
    return triageRes;
  }, [getIsAdmin, level]);

  const hook = useMemo<
    [
      typeof get,
      { checkFinished: boolean; isAdmin: typeof isAdmin; role: typeof role } & typeof rest,
    ]
  >(
    () => [get, { checkFinished, isAdmin, role, ...rest }],
    [get, checkFinished, isAdmin, role, rest]
  );

  return hook;
}
