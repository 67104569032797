import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { TrackerTypes } from '@/pages/WatchListPage';

export const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState: { openAccordeon: TrackerTypes.BUILDING },
  reducers: {
    updateOpenAccordeon(state, action: PayloadAction<TrackerTypes>) {
      if (action.payload) state.openAccordeon = action.payload;
    },
  },
});

export const selectWatchlist = (state: RootState): any => state.watchlist.openAccordeon;

export const watchlistReducer = watchlistSlice.reducer;

export const { updateOpenAccordeon } = watchlistSlice.actions;
