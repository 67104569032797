import Pia from '../type';
import Portfolios from './types';
import Funds from '../funds/types';
import api from '../piaApi';
import { formatOverview } from './helpers';
import {
  formatDemographicAges,
  formatDemographicForeigners,
  formatDemographicGender,
} from '@/pages/sections/helper';
import { formatBuildingsRow, formatDwellingsRow } from '@/features/api/formatRowHelper';
import { TableSort } from '@/components/Funds/Detail/AssetList/type';

const portfolioApi = api
  .enhanceEndpoints({ addTagTypes: ['Portfolios', 'PortfoliosContains'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      listPortfolios: builder.query({
        query: (
          { page = 1, search: title }: { page?: number; search?: string } | void | undefined = {
            page: 1,
            search: '',
          }
        ) => ({ url: `portfolios`, params: title ? { page, title } : { page } }),
        transformResponse: (data: { portfolios: Array<{ id: string; name: string }> }) => {
          return data.portfolios;
        },
        providesTags: () => {
          return ['Portfolios'];
        },
      }),
      getPortfolioOverview: builder.query({
        query: (id: string) => ({ url: `portfolios/${id}/overview` }),
        transformResponse: formatOverview,
        providesTags: (result) => {
          return result?.id
            ? [{ type: 'Portfolios', id: result.id, section: 'Overview' }, 'Portfolios']
            : ['Portfolios'];
        },
        extraOptions: { oneAtATime: false },
      }),
      searchPortfolios: builder.query<
        Array<{ id: number; type: Pia.SearchTypes; label: string }>,
        string
      >({
        query: (text: string) => `search?type=portfolio&text=${text}`,
        transformResponse: (data: {
          results: Array<{ id: number; type: Pia.SearchTypes; label: string }>;
        }) => {
          return data.results;
        },
      }),
      deletePortfolio: builder.mutation<void, string | undefined>({
        query: (id) => ({ url: `portfolios/${id}`, method: 'DELETE' }),
        invalidatesTags: ['Portfolios'],
      }),
      removePortfolioAsset: builder.mutation<
        void,
        { id: string; buildings?: Array<number | string>; dwellings?: Array<number | string> }
      >({
        query: ({ id, buildings = [], dwellings = [] }) => ({
          url: `portfolios/${id}`,
          method: 'DELETE',
          body: { buildings, dwellings },
        }),
        invalidatesTags: (_result, error) =>
          error ? [] : [{ type: 'Portfolios', section: 'Assets' }],
      }),
      addPortfolioAsset: builder.mutation<
        void,
        {
          id: string;
          buildings?: Array<number | string>;
          dwellings?: Array<number | string>;
          buildingGroups?: Array<number | string>;
          funds?: Array<number | string>;
        }
      >({
        query: ({
          id,
          buildings = [],
          dwellings = [],
          buildingGroups = [],
          funds = [],
          type,
        }: {
          id: string;
          buildings?: Array<string | number>;
          dwellings?: Array<string | number>;
          funds?: Array<string | number>;
          buildingGroups?: Array<string | number>;
          type: 'asset' | 'transaction';
        }) => ({
          url: `portfolios/${id}`,
          method: 'PATCH',
          body: { buildings, dwellings, buildingGroups, funds, type },
        }),
        invalidatesTags: (_result, error) =>
          error ? [] : [{ type: 'Portfolios', section: 'Assets' }],
      }),
      getPortfolio: builder.query({
        query: (portfolioId: string) => `portfolios/${portfolioId}`,
        transformResponse: (data: { portfolio: Pia.Portfolio }) => {
          return data.portfolio;
        },
      }),
      getContainsDwellings: builder.mutation({
        query: ({
          portfolioId,
          buildingId = [],
          buildingGroup = [],
        }: {
          portfolioId: string;
          buildingId: number | Array<number>;
          buildingGroup?: number | Array<number>;
        }) => {
          const buildingIds = Array.isArray(buildingId) ? buildingId : [buildingId];
          const buildingGroupIds = Array.isArray(buildingGroup) ? buildingGroup : [buildingGroup];
          return {
            url: `portfolios/${portfolioId}/contains-dwellings`,
            params: {
              buildingId: buildingIds.length ? buildingIds : undefined,
              buildingGroup: buildingGroupIds.length ? buildingGroupIds : undefined,
            },
          };
        },
        transformResponse: (data: { contains_dwellings: Boolean }) => {
          return data.contains_dwellings;
        },
      }),
      getDwellingOfPortfolio: builder.query({
        query: ({
          id,
          page = 1,
          sort,
        }: {
          id: string | number;
          page?: number;
          sort?: TableSort;
        }) => ({
          url: `portfolios/${id}/assets/dwellings`,
          params: sort ? { page, sort: JSON.stringify(sort) } : { page },
        }),
        providesTags: (result) =>
          result ? [{ type: 'Portfolios', section: 'Assets' }, 'Portfolios'] : [],
        transformResponse: formatDwellingsRow,
      }),
      getBuildingOfPortfolio: builder.query({
        query: ({
          id,
          page = 1,
          sort,
        }: {
          id: string | number;
          page?: number;
          sort?: TableSort;
        }) => ({
          url: `portfolios/${id}/assets/buildings`,
          params: sort ? { page, sort: JSON.stringify(sort) } : { page },
        }),
        providesTags: (result) =>
          result ? [{ type: 'Portfolios', section: 'Assets' }, 'Portfolios'] : [],
        transformResponse: formatBuildingsRow,
      }),
      createPortfolio: builder.mutation({
        query: (data: {
          title: string;
          buildings?: Array<number>;
          dwellings?: Array<number>;
          buildingGroups?: Array<number>;
          funds?: Array<number | string>;
        }) => ({
          url: 'portfolios',
          method: 'POST',
          body: {
            title: data.title,
            buildings: data.buildings || [],
            dwellings: data.dwellings || [],
            buildingGroups: data.buildingGroups || [],
            funds: data.funds || [],
          },
        }),
        invalidatesTags: ['Portfolios'],
        transformResponse: (data: { portfolio: Pia.Portfolio }) => {
          return data.portfolio;
        },
      }),
      updatePortfolio: builder.mutation({
        query: (data: {
          id: string | number;
          title: string;
          buildings?: Array<number | string>;
          dwellings?: Array<number | string>;
          buildingGroups?: Array<number | string>;
        }) => ({
          url: `portfolios/${data.id}`,
          method: 'PATCH',
          body: {
            title: data.title,
            buildings: data.buildings,
            dwellings: data.dwellings,
            buildingGroups: data.buildingGroups,
          },
        }),
        invalidatesTags: ['Portfolios'],
      }),
      getPortfolioDesignation: builder.query<Portfolios.Designation, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/description/designation`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Designation' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioComposition: builder.query<Portfolios.Composition, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/description/composition`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Composition' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioDistribution: builder.query<Portfolios.Distribution, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/description/distribution`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Distribution' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioRevenue: builder.query<Funds.Financials, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/financials/rent`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Revenue' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioRevenueSeries: builder.query<Funds.FinancialsSerie, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/financials/rent-series`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Revenue' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioCharges: builder.query<Funds.Financials, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/financials/charges`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Charges' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioChargesSeries: builder.query<Funds.FinancialsSerie, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/financials/charges-series`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Charges' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioRisks: builder.query<Pia.Risks, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/environmental/risk`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Risks' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioHeating: builder.query<Pia.Heating, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/environmental/heating`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Heating' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioSolar: builder.query<Pia.Solar, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/environmental/solar`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Solar' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioIdc: builder.query<Pia.Idc, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/environmental/idc`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'idc' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioCo2: builder.query<Pia.Co2, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/environmental/co2`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'co2' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioPromiscuity: builder.query<Pia.Promiscuity, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/social/promiscuity`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Promiscuity' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioDemographicAges: builder.query<
        ReturnType<typeof formatDemographicAges>,
        { id: string }
      >({
        query: ({ id }) => ({
          url: `portfolios/${id}/social/demographic/ages-by-gender`,
        }),
        transformResponse: (ages: Pia.Ages) => {
          return formatDemographicAges(ages);
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Ages' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioDemographicGender: builder.query<
        ReturnType<typeof formatDemographicGender>,
        { id: string }
      >({
        query: ({ id }) => ({
          url: `portfolios/${id}/social/demographic/gender`,
        }),
        transformResponse: (gender: Pia.Gender) => {
          return formatDemographicGender(gender);
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Gender' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioDemographicForeigners: builder.query<
        ReturnType<typeof formatDemographicForeigners>,
        { id: string }
      >({
        query: ({ id }) => ({
          url: `portfolios/${id}/social/demographic/citizenship`,
        }),
        transformResponse: (citizenship: Pia.Foreigners) => {
          return formatDemographicForeigners(citizenship);
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Foreigners' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioAccessibility: builder.query<
        Funds.Accessibility & { score: Funds.Risks['index'] },
        { id: string }
      >({
        query: ({ id }) => ({
          url: `portfolios/${id}/social/accessibility`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Accessibility' },
            { type: 'Portfolios', id },
          ];
        },
      }),
    }),
  });

export default portfolioApi;
