import { Trend } from '@/components/Widget/types';
import { formatFullInt, formatPercentToNumber, formatFullFloat } from '../helpers/numbers';
import Funds from '../pia/funds/types';
import Pia from '../pia/type';
import { LotResult } from './screener/screenerTypes';

type TrendLabel = 'market_value' | 'net_rent' | 'charges';

const trendLabels: Array<TrendLabel> = ['market_value', 'net_rent', 'charges'];

function isDwellingRow(value: Pia.DwellingRows | Pia.BuildingRows): value is Pia.DwellingRows {
  return (value as Pia.DwellingRows).building_id !== undefined;
}

function isDwellingRows(
  value: Pia.DwellingRows[] | Pia.BuildingRows[]
): value is Pia.DwellingRows[] {
  return value.reduce((acc, row) => {
    return acc && isDwellingRow(row);
  }, true);
}

function isBuildingRow(value: Pia.DwellingRows | Pia.BuildingRows): value is Pia.BuildingRows {
  return (value as Pia.BuildingRows).lot_id !== undefined;
}

function isBuildingRows(
  value: Pia.DwellingRows[] | Pia.BuildingRows[]
): value is Pia.BuildingRows[] {
  return value.reduce((acc, row) => {
    return acc && isBuildingRow(row);
  }, true);
}

export function formatComparables(rows: { comparables: Pia.DwellingRows[] | Pia.BuildingRows[] }) {
  const tmp = rows?.comparables;
  if (isDwellingRows(tmp)) {
    return formatDwellingsRow({ dwellings: tmp });
  }
  if (isBuildingRows(tmp)) {
    return formatBuildingsRow({ buildings: tmp });
  }
}

export function formatDwellingsRow(rows: { dwellings: Pia.DwellingRows[] }) {
  return rows?.dwellings.map((dwelling) => formatDwellingRow(dwelling));
}

export function formatSameBuildingDwellingsRow(rows: { dwellings: Pia.DwellingRows[] }) {
  return rows?.dwellings.map((dwelling) => formatDwellingRow(dwelling, true));
}

function formatDwellingRow(result: Pia.DwellingRows, isSameBuilding?: boolean) {
  const {
    id,
    ewid,
    street_short,
    nb,
    construction_date,
    potential_net_rent,
    potential_charges,
    real_net_rent,
    real_charges,
    area,
    estimated_area,
    potential_net_rent_per_m2,
    potential_charges_per_m2,
    potential_market_value,
    potential_market_value_per_m2,
    potential_roa,
    estimated_co2_emission_per_m2,
    estimated_idc_per_m2,
    labels,
    ...rest
  } = result;

  const label = formatLabelWithSeperator([street_short, nb], ' ');
  // if isSameBuilding is true, we don't want to display the link
  // to the dwelling on the address but on the ewid as for most of
  // the case street_short is the same for all dwellings of a building

  return {
    id,
    ewid: isSameBuilding ? { label: ewid, link: `/asset/dwellings/${id}` } : ewid,
    street_short: isSameBuilding ? label : { label: label, link: `/asset/dwellings/${id}` },
    construction_date: construction_date ? new Date(construction_date).getFullYear() : null,
    potential_net_rent: formatFullInt(potential_net_rent || null),
    potential_net_rent_monthly: formatFullInt(potential_net_rent ? potential_net_rent / 12 : null),
    potential_charges: formatFullInt(potential_charges || null),
    potential_charges_monthly: formatFullInt(potential_charges ? potential_charges / 12 : null),
    real_net_rent: formatFullInt(real_net_rent || null),
    real_net_rent_monthly: formatFullInt(real_net_rent ? real_net_rent / 12 : null),
    real_charges: formatFullInt(real_charges || null),
    real_charges_monthly: formatFullInt(real_charges ? real_charges / 12 : null),
    area: formatFullInt(area),
    estimated_area: formatFullInt(estimated_area),
    potential_net_rent_per_m2: formatFullInt(potential_net_rent_per_m2),
    potential_charges_per_m2: formatFullInt(potential_charges_per_m2),
    potential_market_value: formatFullInt(potential_market_value),
    potential_market_value_per_m2: formatFullInt(potential_market_value_per_m2),
    potential_roa: formatPercentToNumber(potential_roa),
    estimated_co2_emission_per_m2: formatFullInt(estimated_co2_emission_per_m2),
    estimated_idc_per_m2: formatFullInt(estimated_idc_per_m2),
    labels: labels?.join(', '),
    ...rest,
  };
}

function isGroup(
  item: Funds.BuildingGroupRows | Funds.BuildingRows
): item is Funds.BuildingGroupRows {
  return (item as Funds.BuildingGroupRows).fund_id !== undefined;
}

export function formatFundBuildingsRow(rows: { buildings: Funds.BuildingRows[] }) {
  return rows?.buildings.map((item) => formatFundBuilding(item));
}

export function formatFundBuildingGroups(rows: { building_groups: Funds.BuildingGroupRows[] }) {
  return rows?.building_groups.map((item) => formatFundBuilding(item));
}

function formatFundBuildingAddress(toFormat: Funds.BuildingGroupRows | Funds.BuildingRows) {
  const address = toFormat.label;

  if (isGroup(toFormat)) return address;
  return {
    label: address,
    link: `/asset/buildings/${toFormat.building_id}`,
  };
}

function formatFundBuilding(toFormat: Funds.BuildingGroupRows | Funds.BuildingRows) {
  const trends = trendLabels.reduce(
    (acc: Record<`potential_${TrendLabel}`, Trend>, label: TrendLabel) => {
      return {
        ...acc,
        [`potential_${label}`]: {
          value: toFormat[`potential_${label}`],
          diff: toFormat[`potential_${label}_var`] ?? 0,
        },
      };
    },
    {} as Record<`potential_${TrendLabel}`, Trend>
  );
  return {
    ...toFormat,
    total_area: formatFullInt(toFormat.total_area),
    potential_roa: toFormat.potential_roa ? formatPercentToNumber(toFormat.potential_roa) : null,
    actual_market_value: formatFullInt(toFormat.actual_market_value),
    cost_price: formatFullInt(toFormat.cost_price),
    actual_rental_status_target: formatFullInt(toFormat.actual_rental_status_target),
    actual_target_roa: toFormat.actual_target_roa
      ? formatPercentToNumber(toFormat.actual_target_roa)
      : null,
    actual_rental_status_real: formatFullInt(toFormat.actual_rental_status_real),
    actual_real_roa: toFormat.actual_real_roa
      ? formatPercentToNumber(toFormat.actual_real_roa)
      : null,
    label: formatFundBuildingAddress(toFormat),
    construction_date: toFormat.construction_date
      ? new Date(toFormat.construction_date).getFullYear()
      : null,
    type: toFormat.type ? `buildings.type.${toFormat.type}` : null,
    ...trends,
  };
}

export function formatBuildingsRow(rows: { buildings: Pia.BuildingRows[] }) {
  return rows?.buildings.map(formatBuildingRow);
}

function formatBuildingRow(building: Pia.BuildingRows) {
  const {
    id,
    street_short,
    nb,
    construction_date,
    potential_roa,
    real_roa,
    total_area,
    ground_area,
    dwellings_nb,
    residential_area,
    commercial_area,
    estimated_co2_emission_per_m2,
    estimated_idc_per_m2,
    potential_market_value,
    potential_net_rent,
    potential_charges,
    real_market_value,
    real_net_rent,
    real_charges,
    fund_names,
    potential_net_rent_per_m2,
    potential_charges_per_m2,
    potential_market_value_per_m2,
    potential_gross_rent,
    potential_gross_rent_per_m2,
    labels,

    solar_existing,
    fund_legal_forms,
    ...rest
  } = building;

  // As var has been removed from `hs_building_rows` table, we don't need that code anymore

  //   const trends = trendLabels.reduce(
  //     (acc: Record<`${TrendType}_${TrendLabel}`, Trend>, label: TrendLabel) => {
  //       return {
  //         ...acc,
  // [`potential_${label}`]: {
  //   value: building[`potential_${label}`],
  //   diff: building[`potential_${label}_var`] ?? 0,
  // },
  // [`real_${label}`]: {
  //   value: building[`real_${label}`],
  //   diff: building[`real_${label}_var`] ?? 0,
  // },
  //       };
  //     },
  //     {} as Record<`${TrendType}_${TrendLabel}`, Trend>
  //   );

  return {
    id,
    street_short: {
      label: `${formatLabelWithSeperator([street_short, nb], ' ')}`,
      link: `/asset/buildings/${id}`,
    },
    construction_date: construction_date ? new Date(construction_date).getFullYear() : null,
    potential_roa: building.potential_roa ? formatPercentToNumber(building.potential_roa) : null,
    real_roa: building.real_roa ? formatPercentToNumber(building.real_roa) : null,
    total_area: Number(total_area),
    ground_area: formatFullInt(ground_area),
    dwellings_nb: formatFullInt(dwellings_nb),
    residential_area: formatFullInt(residential_area),
    commercial_area: formatFullInt(Number(commercial_area)),
    estimated_co2_emission_per_m2: formatFullInt(estimated_co2_emission_per_m2),
    estimated_idc_per_m2: formatFullInt(estimated_idc_per_m2),
    potential_market_value: formatFullInt(potential_market_value),
    potential_net_rent: formatFullInt(potential_net_rent),
    potential_charges: formatFullInt(potential_charges),
    real_market_value: formatFullInt(real_market_value),
    real_net_rent: formatFullInt(real_net_rent),
    real_charges: formatFullInt(real_charges),
    potential_net_rent_per_m2: formatFullInt(potential_net_rent_per_m2),
    potential_charges_per_m2: formatFullInt(potential_charges_per_m2),
    potential_market_value_per_m2: formatFullInt(potential_market_value_per_m2),
    potential_gross_rent: formatFullInt(potential_gross_rent),
    potential_gross_rent_per_m2: formatFullInt(potential_gross_rent_per_m2),
    fund_names: fund_names?.join(', '),
    labels: labels?.join(', '),
    solar_existing: formatFullInt(solar_existing),
    fund_legal_forms: fund_legal_forms?.join(', '),
    ...rest,
  };
}

export function formatLotsRow(rows: { lots: LotResult[] }) {
  return rows.lots.map(formatLotRow);
}

function formatLotRow(lots: LotResult) {
  const { id, municipality_name, sector_nb, nb, area, ...rest } = lots;

  return {
    id,
    municipality_name: {
      label: `${municipality_name} ${sector_nb ? `${sector_nb}:` : ''}${nb || 'N/A'}`,
      link: `/asset/lots/${id}`,
    },
    area: formatFullFloat(area),
    ...rest,
  };
}

export function formatLabelWithSeperator(
  stringArr: Array<string | number | null | undefined>,
  seperator: string
) {
  return stringArr.filter(Boolean).join(seperator);
}
