import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScreenerPresetId, ScreenerPresetName, ScreenerPresetState } from './presetsTypes';
import { RootState } from '../../../app/store';
import api from './api';

const savedPreset: ScreenerPresetState = {
  name: '',
  id: '',
};

const initialState: ScreenerPresetState = { ...savedPreset };

export const screenerPresetSlice = createSlice({
  name: 'screenerPreset',
  initialState,
  reducers: {
    updateScreenerPresetId: (state, action: PayloadAction<ScreenerPresetId>) => {
      state.id = action.payload.id;
    },
    updateScreenerPresetName: (state, action: PayloadAction<ScreenerPresetName>) => {
      state.name = action.payload.name;
    },
    hardResetScreenerPreset: (state) => {
      state.name = '';
      state.id = '';
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(api.endpoints.getPreset.matchFulfilled, (state, action) => {
        state.id = action.payload.id || '';
        state.name = action.payload.name || '';
      })
      .addMatcher(api.endpoints.getPreset.matchRejected, (state) => {
        state.id = initialState.id;
        state.name = initialState.name;
      });
  },
});

export const currentScreenerPreset = (state: RootState) => state.screenerPreset;

export const currentScreenerPresetName = (state: RootState) => state.screenerPreset.name;

export const { hardResetScreenerPreset, updateScreenerPresetName, updateScreenerPresetId } =
  screenerPresetSlice.actions;

export const screenerPresetReducer = screenerPresetSlice.reducer;
