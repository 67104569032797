import apiWrapper from './index';
import API from './types';

const apiWrapperWithTag = apiWrapper.enhanceEndpoints({ addTagTypes: ['Notifications'] });

const notificationsApi = apiWrapperWithTag.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query<Array<API.Notification>, void>({
      query: () => ({
        url: `notifications`,
        method: 'GET',
      }),
      providesTags: ['Notifications'],
      transformResponse: (data: { notifications: Array<API.Notification> }) => {
        return data.notifications;
      },
    }),
    deleteNotification: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `notifications/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
  overrideExisting: false,
});

export default notificationsApi;

export const { useLazyGetNotificationsQuery, useDeleteNotificationMutation } = notificationsApi;
