import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface InitialState {
  pathname: string[];
}

const initialState: InitialState = { pathname: [] };

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    appendNewPath(state, { payload: newPathName }: PayloadAction<string>) {
      const { pathname } = state;
      const lastPathName = pathname[pathname.length - 1];
      state.pathname = lastPathName !== newPathName ? [...pathname, newPathName] : pathname;
    },
  },
});

export const selectCurrentPath = (state: RootState) =>
  state.navigation.pathname[state.navigation.pathname.length - 1];

export const selectPathHistory = (state: RootState) => state.navigation.pathname;

const navigationReducer = navigationSlice.reducer;
export default navigationReducer;
export const { appendNewPath } = navigationSlice.actions;
