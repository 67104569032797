import { Suspense, useEffect, useState } from 'react';
import UseRoutes from './routes';
import './scss/styles.css';

// What's that again?
import { setAutoFreeze } from 'immer';
import Fallback from './layouts/Fallback';
import kratosApi from './features/kratos';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ParentLayout from './layouts/ParentLayout';

setAutoFreeze(false);

function App() {
  const routes = UseRoutes();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (routes.length > 1 || routes.some(({ children }) => children?.length)) {
      setIsMounted(true);
    }
  }, [routes]);

  kratosApi.useGetSessionQuery();

  const router = createBrowserRouter([{ element: <ParentLayout />, children: routes }]);

  return (
    <div className="App">
      <Suspense fallback={<Fallback />}>
        {isMounted ? (
          <RouterProvider router={router} fallbackElement={<Fallback />} />
        ) : (
          <Fallback />
        )}
      </Suspense>
    </div>
  );
}

export default App;
