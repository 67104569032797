import {
  de_default
} from "./chunk-OQFP2H3Y.mjs";
import {
  en_default
} from "./chunk-BASMPPU5.mjs";
import {
  fr_default
} from "./chunk-2T45W6YK.mjs";

// src/frontend/index.ts
var frontend_default = { en: en_default, fr: fr_default, de: de_default };

export {
  frontend_default
};
