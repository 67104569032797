import { formatBuildingsRow, formatDwellingsRow, formatLotsRow } from '../formatRowHelper';
import apiWrapper from '../index';
import API from '../types';
import { PresetCriterias } from '@/components/Inputs/FilterBar';

const apiWrapperWithTag = apiWrapper.enhanceEndpoints({
  addTagTypes: ['Presets'],
});

const screenerApi = apiWrapperWithTag.injectEndpoints({
  endpoints: (build) => ({
    getPresets: build.query<Array<API.Preset>, void>({
      query: () => ({
        url: `screener/presets`,
        method: 'GET',
      }),
      providesTags: ['Presets'],
      keepUnusedDataFor: 30,
      transformResponse: (data: { presets: Array<API.Preset> }) => {
        return data.presets;
      },
    }),
    getPreset: build.query<API.Preset & { hasWarning: boolean }, { id: string }>({
      query: ({ id }) => ({
        url: `screener/presets/${id}`,
        method: 'GET',
      }),
      providesTags: ['Presets'],
      keepUnusedDataFor: 30,
      transformResponse: (data: { preset: API.Preset; warning?: unknown }) => {
        return { ...data.preset, hasWarning: !!data.warning };
      },
    }),
    createPreset: build.mutation<API.Preset, { name: string | null; data: PresetCriterias }>({
      query: ({ name, data }) => ({
        url: `screener/presets`,
        method: 'POST',
        body: { name, data },
      }),
      invalidatesTags: ['Presets'],
      transformResponse: (data: { preset: API.Preset }) => {
        return data.preset;
      },
    }),
    editPreset: build.mutation<
      API.Preset,
      {
        id: string;
        name: string;
        data: PresetCriterias;
      }
    >({
      query: ({ id, name, data }) => ({
        url: `screener/presets/${id}`,
        method: 'PATCH',
        body: { name, data },
      }),
      invalidatesTags: ['Presets'],
      transformResponse: (data: { preset: API.Preset }) => {
        return data.preset;
      },
    }),
    deletePreset: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `screener/presets/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Presets'],
    }),

    getRegions: build.query<Array<{ value: string; label: string }>, { ids?: Array<string> }>({
      query: ({ ids }) => ({
        url: `infos/regions`,
        method: 'GET',
        params: { ids },
      }),

      transformResponse: (data: { regions: Array<{ value: string; label: string }> }) => {
        return data.regions;
      },
    }),
    getCantons: build.query<
      Array<{ value: string; label: string }>,
      { regionsIds?: Array<string | number>; text?: string; ids?: Array<string> }
    >({
      query: ({ regionsIds, text, ids }) => ({
        url: `infos/cantons`,
        method: 'GET',
        params: { regionsIds, text, ids },
      }),
      transformResponse: (data: { cantons: Array<{ value: string; label: string }> }) => {
        return data.cantons;
      },
    }),
    getLocalities: build.query<
      Array<{ value: string; label: string }>,
      {
        regionsIds?: Array<string | number>;
        cantonsIds?: Array<string | number>;
        text?: string;
        page?: number;
        ids?: Array<string>;
      }
    >({
      query: ({ regionsIds, cantonsIds, text, page, ids }) => ({
        url: `infos/localities`,
        method: 'GET',
        params: { regionsIds, cantonsIds, text, page, ids },
      }),
      transformResponse: (data: { localities: Array<{ value: string; label: string }> }) => {
        return data.localities;
      },
    }),
    ///double check after backend is done
    getMunicipalities: build.query<
      Array<{ value: string; label: string }>,
      {
        regionsIds?: Array<string | number>;
        cantonsIds?: Array<string | number>;
        text?: string;
        ids?: Array<string>;
        page?: number;
      }
    >({
      query: ({ regionsIds, cantonsIds, text, page, ids }) => ({
        url: `infos/municipalities`,
        method: 'GET',
        params: { regionsIds, cantonsIds, text, page, ids },
      }),
      transformResponse: (data: { municipalities: Array<{ value: string; label: string }> }) => {
        return data.municipalities;
      },
    }),
    getHeatings: build.query<Array<{ value: string; label: string }>, { ids?: Array<string> }>({
      query: ({ ids }) => ({
        url: `infos/heating`,
        method: 'GET',
        params: { ids },
      }),
      transformResponse: (data: { heatings: Array<{ value: string; label: string }> }) => {
        return data.heatings.map(({ value, label }) => {
          return { value: value, label: label };
        });
      },
    }),
    getFunds: build.query<Array<{ value: string; label: string }>, void>({
      query: () => ({
        url: `infos/funds`,
        method: 'GET',
      }),
      transformResponse: (data: { funds: Array<{ value: string; label: string }> }) => {
        return data.funds;
      },
    }),

    //get results from screener
    getLotResults: build.query({
      query: ({ body, page, sort }) => ({
        url: `screener/lots`,
        method: 'POST',
        params: { page, sort: JSON.stringify(sort) },
        body: body,
      }),
      transformResponse: formatLotsRow,
    }),
    getBuildingResults: build.query({
      query: ({ body, page, sort }) => ({
        url: `screener/buildings`,
        method: 'POST',
        params: { page, sort: JSON.stringify(sort) },
        body: body,
      }),
      transformResponse: formatBuildingsRow,
    }),
    getDwellingResults: build.query({
      query: ({ body, page, sort }) => ({
        url: `screener/dwellings`,
        method: 'POST',
        params: { page, sort: JSON.stringify(sort) },
        body: body,
      }),
      transformResponse: formatDwellingsRow,
    }),
  }),
  overrideExisting: false,
});

export default screenerApi;

export const {
  useGetPresetsQuery,
  useGetPresetQuery,
  useDeletePresetMutation,
  useEditPresetMutation,
  useCreatePresetMutation,
} = screenerApi;
