import { UiNode, UiNodeGroupEnum } from '@ory/kratos-client';

type UiNodeGroups = Record<UiNodeGroupEnum, Array<UiNode> | undefined>;

export default function groupNodes(nodes: Array<UiNode>): Array<Array<UiNode>> {
  const groups = nodes.reduce((groups: UiNodeGroups, node) => {
    const { group: groupName } = node;
    const { [groupName as UiNodeGroupEnum]: group = [] } = groups as UiNodeGroups;
    return { ...groups, [groupName]: [...group, node] };
  }, {} as UiNodeGroups);
  const { default: mainGroup = [], ...rest } = groups;

  const keys = Object.keys(rest);
  const finalgroups = keys
    .map((key) => {
      const { [key as UiNodeGroupEnum]: group } = rest as UiNodeGroups;
      if (group) return [...mainGroup, ...group] as Array<UiNode>;
      return [];
    })
    .filter((group) => group?.length);

  return finalgroups;
}
