import { useTranslation } from 'react-i18next';

export default function Impressum() {
  const { t } = useTranslation();

  return (
    <div>
      <p>{t('impressum.text')}</p>
      <p>
        {t('impressum.address')}
        <br />
        {t('impressum.mail')}
        <a href="mailto:contact@quanthome.com">contact@quanthome.com</a>
        <br />
        Tel : +41 (0)21 312 16 93
      </p>
    </div>
  );
}
