import { PasswordInput, PinInput, TextInput } from '@mantine/core';
import {
  UiNode,
  UiNodeImageAttributes,
  UiNodeInputAttributes,
  UiNodeTextAttributes,
} from '@ory/kratos-client';
import React, { ReactNode } from 'react';
import { Email, Phone } from '@carbon/icons-react';
import { TFunction } from 'i18next';

interface Props {
  className: string;
  label: string | undefined;
  name: string;
  type: 'email' | 'text' | 'password' | 'hidden';
  defaultValue: string | number;
  id: string;
  disabled: boolean;
  required: boolean | undefined;
  autoFocus: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement> | string): void;
}

export default function KratosInputs<V extends unknown>(props: {
  group: Array<UiNode>;
  parentIndex?: number;
  refetch?: () => void;
  identifier?: string;
  customRequired?: Array<string>;
  customDisabled?: Array<string>;
  ignore?: Array<string>;
  t?: TFunction<'translation', undefined>;
  withIcon?: boolean;
  setter?: (path: string | keyof V, value: string | V[keyof V]) => void;
}) {
  const {
    customDisabled,
    group,
    parentIndex = 0,
    identifier,
    setter,
    customRequired,
    ignore,
    t,
    withIcon,
  } = props;

  // 3: 1 element is the hidden crsf, 1 is the button, hence, all that is left are the actual inputs.
  if (group.length < 3) {
    props.refetch?.();
  }

  let alreadyHasFocus = false;

  return (
    <>
      {[...group]
        .sort((a: UiNode, b: UiNode) => {
          const aIsEmail = a.meta.label?.text === 'ID';
          const bIsEmail = b.meta.label?.text === 'ID';
          if (aIsEmail || bIsEmail) return aIsEmail ? -1 : 1;
          const typeA = (a.attributes as UiNodeInputAttributes).type === 'hidden';
          const typeB = (b.attributes as UiNodeInputAttributes).type === 'hidden';
          if (!typeA) return -1;
          else if (typeA === typeB) return 0;
          else return 1;
        })
        .map((node: UiNode, index: number) => {
          const {
            meta: { label },
            attributes,
            type: nodeType,
          } = node;
          const {
            name,
            type,
            value: defaultValue,
            required = customRequired?.includes(name),
            disabled,
          } = attributes as UiNodeInputAttributes;
          if (ignore?.includes(name)) return;
          if (nodeType === 'img') {
            const { src, id, width, height } = attributes as UiNodeImageAttributes;
            const finalName = name + '-' + (index + parentIndex);
            const nodeId = id ? id + '-' + finalName : finalName;
            return (
              <div key={nodeId} className={`${nodeType}-container`}>
                <img {...{ id, alt: id, src, width, height }} />
              </div>
            );
          }
          //TODO check security data-testid
          if (nodeType === 'text') {
            const { text: node, id: testId } = attributes as UiNodeTextAttributes;
            const { text, id } = node;
            const { text: labelText, type: className } = label || {};
            const finalName = name + '-' + (index + parentIndex);
            const nodeId = id ? id + '-' + finalName : finalName;
            if (setter && testId === 'lookup_secret_codes') {
              setter(testId, text);
            }
            return (
              <div key={nodeId} data-testid={testId} className={`${className}-container`}>
                {labelText && testId !== 'lookup_secret_codes' && <label>{labelText}</label>}
                {testId !== 'lookup_secret_codes' ? (
                  <p {...{ id: `${id}`, className }}>{text}</p>
                ) : (
                  text.split(',').map((code) => <p key={code}>{code}</p>)
                )}
              </div>
            );
          }
          if (nodeType === 'input') {
            if (type === 'submit') {
              if (setter && defaultValue && name) {
                setter(name, defaultValue);
              }
            }
            if (type === 'text' || type === 'hidden' || type === 'email' || type === 'password') {
              const id = label?.id;
              const text = label?.text;
              const htmlLabel = text && text === 'ID' ? 'Email' : text;
              const value = defaultValue || (name === 'identifier' ? identifier : undefined);
              const manualyDisabled =
                disabled ||
                customDisabled?.includes(name) ||
                (name === 'identifier' && identifier ? true : false);
              const finalName = name + '-' + (index + parentIndex);
              const nodeId = id ? id + '-' + finalName : finalName;

              const autoFocus = !alreadyHasFocus && type !== 'hidden';
              if (autoFocus) alreadyHasFocus = true;

              if (setter && value) {
                setter(name, value);
              }

              const props: Props = {
                className: `${type}-container ${name}`,
                label: t ? t(name) : htmlLabel,
                name,
                type,
                defaultValue: value,
                id: nodeId,
                disabled: manualyDisabled,
                required,
                autoFocus,
                onChange(event: React.ChangeEvent<HTMLInputElement> | string) {
                  if (setter) {
                    setter(name, typeof event === 'string' ? event : event.target.value);
                  }
                },
              };

              const icon = getIcon(name);

              return (
                <Input
                  {...props}
                  id={name}
                  withAsterisk={required}
                  key={nodeId}
                  leftSection={withIcon && icon}
                />
              );
            }
          }
        })
        .filter(Boolean)}
    </>
  );
}

function Input(props: Props & { withAsterisk?: boolean } & { leftSection?: ReactNode }) {
  const { type, defaultValue, withAsterisk, ...rest } = props;

  if (props.name === 'totp_code')
    return (
      <PinInput type="number" length={6} placeholder="-" {...rest} className="pin-container" />
    );
  if (props.name === 'lookup_secret')
    return (
      <PinInput length={8} placeholder="-" {...rest} className="pin-container recovery-code" />
    );
  if (type === 'password') return <PasswordInput {...rest} />;
  else return <TextInput {...props} />;
}

function getIcon(name: string) {
  if (name === 'traits.email' || name === 'traits.contact.public_email') return <Email />;
  else if (name === 'traits.contact.personal_phone' || name === 'traits.contact.office_phone')
    return <Phone />;
  else return undefined;
}
