import React from 'react';
import { Route, RouteObject, NonIndexRouteObject } from 'react-router-dom';

function hasChildren(route: RouteObject): route is NonIndexRouteObject {
  return typeof route.children !== 'undefined' && Array.isArray(route.children);
}

export default function RenderRoute({ route }: { route: RouteObject }): React.ReactElement {
  if (!hasChildren(route)) return <Route {...route} />;
  const { children, ...props } = route;

  return (
    <Route {...props}>
      {children?.map((route, index) => (
        <React.Fragment key={route.path || index + 1}>{RenderRoute({ route })}</React.Fragment>
      ))}
    </Route>
  );
}
