import { NotificationData, notifications } from '@mantine/notifications';
import { Fragment, useCallback, useEffect, useId, useMemo, useRef } from 'react';
import Timer from '@anlerandy/timer';

type FiiNotificationProps = NotificationData & { separate?: boolean };

const SECOND = 1000;
const THREE = 3 * SECOND;
const TEN = 10 * SECOND;

export default function UseNotifications() {
  const id = useId();
  const isOpen = useRef(false);

  const hide = useCallback(() => {
    notifications.hide(id);
    isOpen.current = false;
  }, [id]);

  const timer = useMemo(() => {
    return new Timer(THREE, { id, destroy: false, save: false });
  }, [id]);

  useEffect(() => {
    return () => timer?.abort();
  }, [id]);

  const show = useCallback(
    (props: Omit<FiiNotificationProps, 'id'>) => {
      const { onClose, message, radius = 'sm', autoClose, separate, ...rest } = props;

      const component =
        typeof message === 'string' ? (
          <h6>
            {message.split('\n').map((message, index) => (
              <Fragment key={`notification-${id}-${index + 1}`}>
                {index ? <br /> : <></>}
                {message}
              </Fragment>
            ))}
          </h6>
        ) : (
          message
        );

      if (!separate && timer && autoClose !== false) {
        timer.launchOrUpdate(hide);
        timer.time = typeof autoClose === 'number' ? autoClose : THREE;
      }

      notifications[isOpen.current && !separate ? 'update' : 'show']({
        ...rest,
        message: <div>{component}</div>,
        id: !separate ? id : `${Math.random()}`,
        radius,
        onClose: (props) => {
          onClose?.(props);
          isOpen.current = separate ? isOpen.current : false;
        },
        autoClose: separate ? autoClose : false,
        withCloseButton:
          separate || autoClose === false || (typeof autoClose === 'number' && autoClose >= TEN),
      });
      isOpen.current = separate ? isOpen.current : true;
    },
    [id]
  );

  const notification = useMemo(
    () => ({
      show,
      hide,
    }),
    [show, hide]
  );

  return notification;
}
