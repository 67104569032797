import React, { useEffect, useState } from 'react';

export default function UseResize(ref: React.RefObject<any>, observeParent?: boolean) {
  const [size, setSize] = useState<{ width?: number; height?: number }>({});
  const [current, setCurrent] = useState();
  const observer = new ResizeObserver((entries) => {
    const { contentRect, devicePixelContentBoxSize, target } = entries[0];
    if (target.localName === 'span') {
      const { offsetWidth: width, offsetHeight: height } = target as HTMLSpanElement;
      if (size.width === width && size.height === height) return;
      return setSize({ width, height });
    }
    if (contentRect) {
      const { width, height } = contentRect;
      if (size.width === width && size.height === height) return;
      return setSize({ width, height });
    }
    if (devicePixelContentBoxSize?.[0]) {
      const { inlineSize: width, blockSize: height } = devicePixelContentBoxSize?.[0] || {};
      if (size.width === width && size.height === height) return;
      return setSize({ width, height });
    }
  });

  useEffect(() => {
    if (ref.current) {
      const current = observeParent ? ref.current.parentElement || ref.current : ref.current;
      observer.observe(current);
      setCurrent(current);
    } else if (!ref.current && current) {
      observer.unobserve(current);
      setCurrent(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return size;
}
