import { Button } from '@mantine/core';
import '@/scss/pages/ErrorPage.css';
import { ArrowLeft } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/app/hooks';
import { selectPathHistory } from '@/features/api/navigationSlice';
import UseTitle from '@/features/helpers/UseTitle';
import { useEffect } from 'react';

export default function NotFoundPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pathname = useAppSelector(selectPathHistory);
  const location = useLocation();
  const updateTitle = UseTitle();

  const handleBack = () => {
    if (pathname.length === 1 && pathname[pathname.length - 1] === location.pathname) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    updateTitle({ element: 'error.notfound.title' });
  }, [updateTitle]);

  return (
    <div className="page-error">
      <div className="page-error-text">
        <span>{t('error.notfound.code')}</span>
        <h1>{t('error.notfound.title')}</h1>
        <h2>{t('error.notfound.subtitle')}</h2>
        <Button onClick={handleBack} variant="light">
          <span className={'icon'}>
            <ArrowLeft />
          </span>
          <span>{t('error.notfound.button')}</span>
        </Button>
      </div>
      <div className="page-error-logo">
        <img src="/logo_small_black_animated.svg" width={'100%'} />
      </div>
    </div>
  );
}
