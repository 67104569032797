import { useCallback, useMemo } from 'react';
import { Floorplan, Building } from '@carbon/icons-react';
import '@/scss/components/WatchList.css';
import { Accordion } from '@mantine/core';
import WatchlistDropdown from '@/components/Watchlist/WatchlistDropdown';
import { store } from '@/app/store';
import { selectWatchlist, updateOpenAccordeon } from '@/components/Watchlist/watchlistSlice';
import { useAppSelector } from '@/app/hooks';
import { useTranslation } from 'react-i18next';

export enum TrackerTypes {
  BUILDING = 'buildings',
  DWELLING = 'dwellings',
}

export default function WatchListPage() {
  const types: TrackerTypes[] = useMemo(() => [TrackerTypes.BUILDING, TrackerTypes.DWELLING], []);
  const { t } = useTranslation();

  const getIcon = useCallback((type: string) => {
    switch (type) {
      case TrackerTypes.BUILDING:
        return <Building />;
      case TrackerTypes.DWELLING:
        return <Floorplan />;
      default:
        return <></>;
    }
  }, []);

  const onChange = useCallback((value: TrackerTypes) => {
    store.dispatch(updateOpenAccordeon(value));
  }, []);

  return (
    <Accordion
      className="watchlist"
      transitionDuration={600}
      defaultValue={useAppSelector(selectWatchlist)}
      onChange={(value) => {
        if (value) onChange(value as TrackerTypes);
      }}
    >
      {types.map((type: TrackerTypes) => (
        <Accordion.Item className="accordion-item" value={type} key={type}>
          <Accordion.Control>
            <div className="accordion-title">
              {getIcon(type)}
              <h2>{t(`common.${type}.pascalcase`)}</h2>
            </div>
          </Accordion.Control>
          <Accordion.Panel>
            <WatchlistDropdown types={type} />
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}
