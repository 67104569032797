import { RouteObject } from 'react-router-dom';
import { lazy, useEffect, useState } from 'react';
import { selectSession } from '@/features/kratos/kratosSlice';
import { useAppSelector } from '@/app/hooks';
import Redirector from './Redirector';

const BoardingLayout = lazy(() => import('@/layouts/BoardingLayout'));

const ShowRecovery = lazy(() => import('@/pages/flows/ShowRecovery'));
const InputRecovery = lazy(() => import('@/pages/flows/InputRecovery'));
const Login = lazy(() => import('@/pages/flows/Login'));
const Recover = lazy(() => import('@/pages/flows/Recover'));
const Verify = lazy(() => import('@/pages/flows/Verify'));
const Register = lazy(() => import('@/pages/flows/Register'));
const OtpSetup = lazy(() => import('@/pages/flows/SetupOtp'));

export default function UseBoardingRoutes() {
  const [routes, setRoutes] = useState<RouteObject[]>([]);
  const session = useAppSelector(selectSession);

  useEffect(() => {
    if (session?.status === 'recovery')
      setRoutes([
        {
          path: '/',
          element: <BoardingLayout />,
          children: [
            {
              path: '/',
              element: <Recover />,
            },
            {
              path: '/recover',
              element: <Recover />,
            },
            {
              path: '*',
              element: <Redirector />,
            },
          ],
        },
      ]);
  }, [session]);

  useEffect(() => {
    if (
      session &&
      !['require-totp', 'require-totp-setup', 'onboarding', 'recovery'].includes(session.status)
    )
      setRoutes([
        {
          path: '/',
          element: <BoardingLayout />,
          children: [
            {
              path: '/',
              element: <Login />,
            },
            {
              path: '/recover',
              element: <Recover />,
            },
            {
              path: '/boarding',
              element: <Register />,
            },
            {
              path: '/verification',
              element: <Verify />,
            },
            {
              path: '/self-service/verification',
              element: <Verify />,
            },
            {
              path: '*',
              element: <Redirector />,
            },
          ],
        },
      ]);
  }, [session]);

  useEffect(() => {
    if (session?.status === 'onboarding')
      setRoutes([
        {
          path: '/',
          element: <BoardingLayout />,
          children: [
            {
              path: '/',
              element: <Register />,
            },
            {
              path: '*',
              element: <Redirector />,
            },
          ],
        },
      ]);
  }, [session]);

  useEffect(() => {
    if (session?.status === 'require-totp')
      setRoutes([
        {
          path: '/',
          element: <BoardingLayout />,
          children: [
            {
              path: '/',
              element: <Login aal2={true} />,
            },
            {
              path: '/two-factor-recovery',
              element: <InputRecovery />,
            },
            {
              path: '*',
              element: <Redirector />,
            },
          ],
        },
      ]);
  }, [session]);

  useEffect(() => {
    if (session?.status === 'require-totp-setup') {
      setRoutes([
        {
          path: '/',
          element: <BoardingLayout />,
          children: [
            {
              path: '/',
              element: <OtpSetup />,
            },
            {
              path: '/code-giveaway',
              element: <ShowRecovery />,
            },
            {
              path: '*',
              element: <Redirector />,
            },
          ],
        },
      ]);
    }
  }, [session]);

  return routes;
}
