import { Tooltip } from '@mantine/core';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@/scss/components/EllipsisText.css';

export default function EllipsisText(props: {
  label?: string;
  classname?: string;
  noTranslation?: boolean;
}) {
  const [openedLabel, setOpenedLabel] = useState(false);
  const refLabel = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  if (!props.label) return null;

  return (
    <Tooltip withinPortal label={t(props.label)} opened={openedLabel} zIndex={402}>
      <span
        ref={refLabel}
        onMouseEnter={() => {
          if (refLabel.current && refLabel.current?.offsetWidth < refLabel.current?.scrollWidth) {
            setOpenedLabel(true);
          }
        }}
        onMouseLeave={() => setOpenedLabel(false)}
        className={'label-component' + (props.classname ? ` ${props.classname}` : '')}
      >
        {props.noTranslation ? props.label : t(props.label)}
      </span>
    </Tooltip>
  );
}
