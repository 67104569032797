import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import fetchCustomQuery from '../helpers/reAuth';
import Pia from './type';
import { ResultTypeItem } from '@/components/SearchBar/type';
import { TrackerTypes } from '@/pages/WatchListPage';
import { formatBuildingsRow, formatSameBuildingDwellingsRow } from '../api/formatRowHelper';
import { formatCantonRent, formatIndicators, formatTrend } from './helper';
import { ItemNumType } from '@/components/Widget/types';
import { BarLineDataType } from '@/components/Charts/types';

const { VITE_API_URI } = import.meta.env;

const piaApi = createApi({
  reducerPath: 'piaApi',
  baseQuery: fetchCustomQuery({
    baseUrl: VITE_API_URI,
    credentials: 'include',
  }),
  tagTypes: ['Trackers', 'Asset'],
  endpoints: (builder) => ({
    getStatsFequencies: builder.query<Array<number>, number>({
      query: (cantonId) => `stats/frequencies/rooms/${cantonId}`,
      transformResponse: (data: { frequencies: Array<number> }) => {
        return data.frequencies.sort((a, b) => b - a);
      },
    }),
    getStats: builder.query<
      Array<{ label: string; value: number }>,
      { cantonId: number; timestamp: number }
    >({
      query: ({ cantonId, timestamp }) => `stats/rooms/${cantonId}/${timestamp}`,
      transformResponse: (data: { stats: Record<string, number> }) => {
        return Object.keys(data.stats).map((key) => ({ label: key, value: data.stats[key] }));
      },
    }),
    getStatsQuantilesM2: builder.query<
      Array<{ date: number; low: number; high: number; close: number; open: number }>,
      number
    >({
      query: (cantonId) => `stats/rent-m2/${cantonId}`,
      transformResponse: (data: {
        stats: Array<{ date: number; low: number; high: number; close: number; open: number }>;
      }) => {
        return data.stats;
      },
    }),
    getStatsQuantilesRooms: builder.query<
      Array<{ date: number; low: number; high: number; close: number; open: number }>,
      number
    >({
      query: (cantonId) => `stats/rent-room/${cantonId}`,
      transformResponse: (data: {
        stats: Array<{ date: number; low: number; high: number; close: number; open: number }>;
      }) => {
        return data.stats;
      },
    }),
    getBuilding: builder.query({
      query: (id: number | string) => `details/buildings/${id}`,
      transformResponse: (data: { building: Pia.RealEstateEntity }) => {
        return data.building;
      },
      providesTags: (_result, _error, id) => {
        return [{ type: 'Asset', id: `${id}-buildings` }];
      },
    }),
    getDwelling: builder.query({
      query: (id: number | string) => `details/dwellings/${id}`,
      transformResponse: (data: { dwelling: Pia.RealEstateEntity }) => {
        return data.dwelling;
      },
      providesTags: (_result, _error, id) => {
        return [{ type: 'Asset', id: `${id}-dwellings` }];
      },
    }),
    getDwellingOfBuilding: builder.query({
      query: (id: number | string) => `details/buildings/${id}/dwellings/rows`,
      transformResponse: formatSameBuildingDwellingsRow,
    }),
    getBuildingOfLot: builder.query({
      query: (id: number | string) => `details/lots/${id}/buildings/rows`,
      transformResponse: formatBuildingsRow,
    }),
    getLot: builder.query({
      query: (id: number | string) => `details/lots/${id}`,
      transformResponse: (data: { lot: Pia.RealEstateEntity }) => {
        return data.lot;
      },
    }),
    getSearchSuggestions: builder.query<ResultTypeItem, { type: Pia.SearchTypes; text: string }>({
      query: ({ type, text }: { type: Pia.SearchTypes; text: string }) =>
        `search?type=${type}&text=${text}`,
    }),
    getTrackers: builder.query({
      query: ({ page, type }: { page?: number; type?: TrackerTypes }) => ({
        params: { page, type },
        url: 'trackers',
      }),
      providesTags: ['Trackers'],
      keepUnusedDataFor: 60,
    }),
    addTrackers: builder.mutation({
      query: (data: {
        buildings?: Array<string | number>;
        dwellings?: Array<string | number>;
        buildingGroups?: Array<string | number>;
        type: 'asset' | 'transaction';
      }) => ({
        url: 'trackers',
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: (_r, _e, query) => {
        const mapper = (type: 'buildings' | 'dwellings') => (id: string | number) => {
          const tag: TagDescription<'Asset'> = { type: 'Asset', id: `${id}-${type}` };
          return tag;
        };

        return [
          ...(query.buildings?.map(mapper('buildings')) || []),
          ...(query.dwellings?.map(mapper('dwellings')) || []),
          'Trackers',
        ];
      },
    }),
    deleteTrackers: builder.mutation({
      query: (data: {
        buildings?: Array<string | number>;
        dwellings?: Array<string | number>;
      }) => ({
        url: 'trackers',
        method: 'DELETE',
        body: { dwellings: data.dwellings, buildings: data.buildings },
      }),
      invalidatesTags: (_r, _e, query) => {
        const mapper = (type: 'buildings' | 'dwellings') => (id: string | number) => {
          const tag: TagDescription<'Asset'> = { type: 'Asset', id: `${id}-${type}` };
          return tag;
        };

        return [
          ...(query.buildings?.map(mapper('buildings')) || []),
          ...(query.dwellings?.map(mapper('dwellings')) || []),
          'Trackers',
        ];
      },
    }),

    getDashboardIndicators: builder.query<
      {
        indicators: Array<ItemNumType>;
        indices: Array<ItemNumType>;
      },
      void
    >({
      query: () => ({
        url: '/dashboard/indicators',
      }),
      transformResponse: (data: { indicators: Array<Pia.Indicator> }) => {
        return formatIndicators(data.indicators);
      },
    }),
    getDashboardCantonRent: builder.query<BarLineDataType[], void>({
      query: () => ({
        url: '/dashboard/canton-rent',
      }),
      transformResponse: (data: { rent: Array<Pia.CantonRent> }) => {
        return formatCantonRent(data.rent);
      },
    }),
    getDashboardTrend: builder.query<BarLineDataType[], void>({
      query: () => ({
        url: '/dashboard/trend',
      }),
      transformResponse: (data: { trend: Array<Pia.TrendPoint> }) => {
        return formatTrend(data.trend);
      },
    }),
    getCountries: builder.query<Array<string>, void>({
      query: () => ({
        url: '/general/countries',
      }),
      transformResponse: (data: { countries: Array<string> }) => data.countries,
    }),
  }),
});

export default piaApi;
