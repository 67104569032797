import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { Criterias } from './screenerTypes';

const initialState: Criterias = {};
function setState<K extends keyof T, T, V extends T[K]>({
  state,
  key,
  value,
}: {
  state: T;
  key: K;
  value: V;
}) {
  return (state[key] = value);
}
export const screenerSlice = createSlice({
  name: 'screener',
  initialState,
  reducers: {
    updateScreener: (state, action: PayloadAction<Criterias & { searchName?: string }>) => {
      const keys = Object.keys(action.payload) as Array<keyof Criterias>;
      keys.map((key) => setState({ state, key, value: action.payload[key] }));
    },
    hardResetScreener: (state) => {
      const keys = Object.keys(state) as Array<keyof Criterias>;
      keys.map((key) => setState({ state, key, value: undefined }));
    },
  },
});

export const selectCriterias = (state: RootState) => state.screener;

export const { updateScreener, hardResetScreener } = screenerSlice.actions;

export const screenerReducer = screenerSlice.reducer;
