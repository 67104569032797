/**
 * Takes an array of elements and placed them in three different array depending on the passed function's result.
 * @param data
 * @param fn
 * @returns [TrueResults, FalseResults, UndefinedResults]
 */
export default function biFilter<T extends unknown>(
  data: Array<T>,
  fn: (data: T) => boolean | undefined
) {
  return data.reduce(
    (
      [TRUE, FALSE, UNDEFINED]: [Array<T>, Array<T>, Array<T>],
      element: T
    ): [Array<T>, Array<T>, Array<T>] => {
      const conditionResult = fn(element);
      if (conditionResult === true) return [[...TRUE, element], FALSE, UNDEFINED];
      else if (conditionResult === false) return [TRUE, [...FALSE, element], UNDEFINED];
      else return [TRUE, FALSE, [...UNDEFINED, element]];
    },
    [[], [], []]
  );
}
