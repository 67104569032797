const { VITE_API_URI } = import.meta.env;

export default function handleDownload(id?: string): void {
  if (!id) return;
  const anchor = document.createElement('a');
  const href = `${VITE_API_URI}downloads/${id}`;
  anchor.href = href;
  anchor.download = href;
  anchor.click();
}

export function customDownload(
  content: string | Blob,
  options?: { type?: string; name?: string }
): void {
  const anchor = document.createElement('a');
  const file =
    typeof content === 'string'
      ? new Blob([content], { type: options?.type || 'text/plain' })
      : content;
  const href = URL.createObjectURL(file);
  anchor.href = href;
  anchor.download = options?.name || href;
  anchor.click();
  URL.revokeObjectURL(href);
}
