import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import API from './types';
import notificationsApi from './notifications';
import biFilter from '../helpers/bifilter';
import { adminNotificationTypes } from '@/layouts/helper';

interface InitialState {
  appNotifications: Array<API.Notification>;
  boNotifications: Array<API.Notification>;
  isInitialized: boolean;
}

const initialState: InitialState = {
  appNotifications: [],
  boNotifications: [],
  isInitialized: false,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<API.Notification>) {
      if (action.payload.temporary) return;

      const type = adminNotificationTypes.includes(action.payload.type)
        ? 'boNotifications'
        : 'appNotifications';

      const index = state[type].findIndex(({ id }) => id === action.payload.id);
      if (index === -1) state[type] = [action.payload, ...state[type]];
      else state[type][index] = action.payload;
    },
    removeNotification(state, action: PayloadAction<API.Notification>) {
      state['boNotifications'] = state['boNotifications'].filter(
        ({ id }) => action.payload.id !== id
      );
      state['appNotifications'] = state['appNotifications'].filter(
        ({ id }) => action.payload.id !== id
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(notificationsApi.endpoints.getNotifications.matchFulfilled, (state, action) => {
        const [bo, app] = biFilter(action.payload, (notification: API.Notification) =>
          adminNotificationTypes.includes(notification.type)
        );
        state.boNotifications = bo;
        state.appNotifications = app;
        state.isInitialized = true;
      })
      .addMatcher(notificationsApi.endpoints.getNotifications.matchRejected, (state) => {
        state.boNotifications = [];
        state.appNotifications = [];
      });
  },
});

export const selectAPPNotifications = (state: RootState) => state.notifications.appNotifications;
export const selectBONotifications = (state: RootState) => state.notifications.boNotifications;

export const selectNotificationsIsInitialized = (state: RootState) =>
  state.notifications.isInitialized;

export const notificationsReducer = notificationsSlice.reducer;
export const { addNotification, removeNotification } = notificationsSlice.actions;
