import API from '../api/types';

export function isNotificationAdminType(
  item: API.Notification
): item is API.AdministrationNotification {
  return (
    item.type === 'administration' &&
    (item.value as API.EnterpriseStateValue)?.id !== undefined &&
    (item.value as API.EnterpriseStateValue)?.state !== undefined
  );
}
