import { useEffect } from 'react';
import { useNavigate, Location, useLocation } from 'react-router-dom';

export default function Redirector({
  to = '/',
  modal,
}: {
  to?: Location['pathname'];
  modal?: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (modal) {
      navigate(location, { state: { foregroundLocation: to } });
    } else {
      navigate(to);
    }
  }, []);

  return <></>;
}
