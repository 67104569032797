import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';
import API from '../types';
import kratosApi from './../../kratos';
import deepMerge from '@/features/helpers/deepMerge';

interface InitialState {
  view: API.ViewPreferences;
}

const initialState: InitialState = { view: {} };

export const viewPreferencesSlice = createSlice({
  name: 'view_preferences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(kratosApi.endpoints.getViewPreferences.matchFulfilled, (state, action) => {
      state.view = action.payload;
    });
    builder.addMatcher(
      kratosApi.endpoints.updateViewPreferences.matchFulfilled,
      (state, action) => {
        const body = action.meta.arg.originalArgs;
        const { table, type } = body;
        state.view = deepMerge(state.view, { [table]: type });
      }
    );
  },
});

export const selectViewPreference = (state: RootState) => state.view_preferences.view;

export const viewPreferencesReducer = viewPreferencesSlice.reducer;
