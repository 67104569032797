import '@/scss/components/ObjectList.css';
import { Area, Industry, AssemblyReference, Building, Floorplan } from '@carbon/icons-react';
import { formatFullInt } from '@/features/helpers/numbers';
import { useMemo } from 'react';
import { Badge } from '@mantine/core';
import UnitBadge from '../UnitBadge';

export default function ObjectLegend({
  legend,
}: {
  legend: {
    type: 'surface' | 'volume' | 'dwellings_nb' | 'buildings_nb' | 'lots_nb';
    value: number;
  };
}) {
  const [Icon, suffix] = useMemo(() => {
    if (legend.type === 'volume') {
      return [<Industry className="legend-icon" />, 'units.volume'];
    } else if (legend.type === 'surface') {
      return [<Area className="legend-icon" />, 'units.surface'];
    } else if (legend.type === 'buildings_nb') {
      return [<Building className="legend-icon" />, 'common.buildings.lowercase'];
    } else if (legend.type === 'dwellings_nb') {
      return [<Floorplan className="legend-icon" />, 'common.dwellings.lowercase'];
    } else {
      return [<AssemblyReference className="legend-icon" />, 'common.lots.lowercase'];
    }
  }, [legend]);

  return (
    <Badge className="legend" leftSection={Icon}>
      <span className="legend-value">{formatFullInt(legend.value)}</span>
      <UnitBadge unit={suffix as string} />
    </Badge>
  );
}
