// src/frontend/fr.json
var global = {
  chart: {
    legend: {
      legend: "L\xE9gende",
      amount: "Montant",
      date: "Date"
    },
    tooltip: {
      potential: "Estim\xE9",
      real: "List\xE9"
    }
  },
  assets: {
    dwelling: "appartement",
    building: "b\xE2timent"
  }
};
var units = {
  transport_accessibility: "pts",
  road_accessibility: "pts",
  green_density: "%",
  public_utility_density: "%",
  stock_volume: "Units",
  six_months_avg_volume: "Units",
  five_days_avg_volume: "Units",
  one_year_avg_volume: "Units",
  three_months_avg_volume: "Units",
  one_month_avg_volume: "Units",
  five_years_return: "%",
  one_year_return: "%",
  fifty_two_weeks_low: "CHF",
  six_months_return: "%",
  three_years_return: "%",
  unit_outstanding: "Units",
  turnover: "CHF",
  qtd_net_return: "%",
  mtd_net_return: "%",
  fifty_two_weeks_high: "CHF",
  r_cost_price_q3: "CHF/m\xB2",
  ytd_net_return: "%",
  three_months_return: "%",
  r_cost_price_median: "CHF/m\xB2",
  one_month_return: "%",
  r_total_cost_price: "CHF",
  c_market_value_q1: "CHF/m\xB2",
  r_cost_price_q1: "CHF/m\xB2",
  c_market_value_q3: "CHF/m\xB2",
  c_total_market_value: "CHF",
  r_residential_cost_price: "CHF",
  r_commercial_cost_price: "CHF",
  r_mixed_cost_price: "CHF",
  c_residential_market_value: "CHF",
  c_market_value_median: "CHF/m\xB2",
  c_mixed_market_value: "CHF",
  r_market_value_q1: "CHF/m\xB2",
  c_commercial_market_value: "CHF",
  r_market_value_q3: "CHF/m\xB2",
  r_mixed_market_value: "CHF",
  r_commercial_market_value: "CHF",
  r_total_market_value: "CHF",
  r_net_rent_q1: "CHF/m\xB2",
  r_net_rent_median: "CHF/m\xB2",
  r_net_rent_q3: "CHF/m\xB2",
  r_residential_market_value: "CHF",
  r_max_custodian_bank_fees: "%",
  r_max_construction_and_works_fees: "%",
  r_residential_net_rent: "CHF",
  r_max_share_buyback_commissions: "%",
  r_max_share_issue_commissions: "%",
  r_custodian_bank_fees: "%",
  r_ter_mv: "%",
  r_property_management_fees: "%",
  r_ter_nav: "%",
  r_share_issue_commissions: "%",
  r_purchase_and_sale_fees: "%",
  r_share_buyback_commissions: "%",
  r_max_management_fees: "%",
  r_ter_gav: "%",
  c_earnings_per_share: "CHF",
  r_estimated_liquidation_taxes: "CHF",
  c_operating_profit: "CHF",
  r_management_fees: "%",
  c_total_cash_per_share: "CHF",
  c_loan_to_value: "%",
  c_debt_ratio: "%",
  c_current_ratio: "%",
  c_weighted_average_maturity: "%",
  c_current_liabilities: "CHF",
  c_weighted_average_cost_of_debt: "%",
  c_payout_ratio: "%",
  c_roe: "%",
  c_price_to_book_value: "%",
  c_pe_ratio: "%",
  c_agio_at_year_end: "%",
  c_net_initial_yield: "%",
  c_roa: "%",
  c_roce: "%",
  r_total_dividend: "CHF",
  m_dividend_yield: "%",
  r_dividend_yield: "%",
  r_payout_ratio: "%",
  r_dividend_from_indirect_rental_income: "CHF",
  r_rental_losses: "%",
  r_performance: "%",
  r_roic: "%",
  r_roi: "%",
  r_debt_ratio: "%",
  r_roe: "%",
  r_ebit_margin: "%",
  c_mixed_net_rent: "CHF",
  c_resiential_net_rent: "CHF",
  c_total_net_rent: "CHF",
  c_net_rent_q3: "CHF/m\xB2",
  c_commercial_net_rent: "CHF",
  r_resiential_net_rent: "CHF",
  r_market_value_median: "CHF/m\xB2",
  c_net_rent_q1: "CHF/m\xB2",
  c_net_rent_median: "CHF/m\xB2",
  r_mixed_net_rent: "CHF",
  c_total_agio_at_year_end: "CHF",
  r_total_net_rent: "CHF",
  r_commercial_net_rent: "CHF",
  r_tna_after_distribution: "CHF",
  c_tga_compounded: "CHF",
  r_tna_before_distribution: "CHF",
  r_tga_after_distribution: "CHF",
  c_tna_compounded: "CHF",
  r_tga_before_distribution: "CHF",
  c_total_current_agio_compounded: "CHF",
  r_market_cap: "CHF",
  r_nav_before_distribution: "CHF",
  c_total_current_agio_after_distribution: "CHF",
  r_agio_at_year_end: "%",
  r_share_price: "CHF",
  c_current_agio_compounded: "%",
  c_nav_after_distribution: "CHF",
  r_gav_before_distribution: "CHF",
  c_gav_after_distribution: "CHF",
  c_nav_compounded: "CHF",
  c_current_agio_after_distribution: "%",
  m_share_price: "CHF",
  c_five_years_total_return: "%",
  c_three_years_total_return: "%",
  hist_volatility_30_days: "%",
  implied_volatility: "%",
  hist_volatility_90_days: "%",
  potential_net_rent_per_m2: "CHF",
  hist_volatility_250_days: "%",
  hist_volatility_180_days: "%",
  potential_charges_per_m2: "CHF",
  tx_price: "CHF",
  potential_roa: "%",
  estimated_area: "m\xB2",
  solar_existing: "kWh",
  duplex: "m\xB2",
  real_market_value: "CHF",
  potential_charges: "CHF",
  potential_market_value_per_m2: "CHF",
  real_roa: "CHF",
  potential_market_value: "CHF",
  r_capital_employed: "CHF",
  ground_area: "m\xB2",
  potential_total_income: "CHF",
  r_total_area: "m\xB2",
  r_residential_area: "m\xB2",
  commercial_spaces_area: "m\xB2",
  one_year_total_return: "%",
  m_share_price_var: "%",
  r_commercial_spaces_area: "m\xB2",
  open: "CHF",
  three_years_total_return: "%",
  five_years_total_return: "%",
  last: "CHF",
  m_market_cap: "CHF",
  low: "CHF",
  high: "CHF",
  r_wault: "ans",
  c_gav_compounded: "CHF",
  r_roce: "%",
  m_dividend: "CHF",
  r_other_revenue: "CHF",
  c_revenue_per_share: "CHF",
  r_other_short_term_liabilities: "CHF",
  r_roa: "%",
  r_other_interest: "CHF",
  r_other_expenses: "CHF",
  r_ordinary_income_carried_forward_from_previous_financial_year: "CHF",
  r_net_income: "CHF",
  r_other_long_term_liabilities: "CHF",
  r_net_assets_at_end_of_the_financial_year: "CHF",
  r_other_assets: "CHF",
  r_nopat: "CHF",
  r_net_income_available_for_distribution: "CHF",
  r_net_assets_at_beginning_of_the_financial_year: "CHF",
  r_net_assets_before_estimated_liquidation_taxes: "CHF",
  r_negative_interest: "CHF",
  r_long_term_liabilities: "CHF",
  r_ebit: "CHF",
  r_invested_capital: "CHF",
  r_long_term_interest_bearing_mortgages: "CHF",
  r_dividend_distribution: "CHF",
  r_mortgage_interest: "CHF",
  r_dividend_from_direct_rental_income: "CHF",
  c_median_maturity: "yr",
  c_weighted_average_interest_rate: "%",
  r_dividend_from_capital_gains: "CHF",
  c_ebit_margin: "%",
  r_max_purchase_and_sale_fees: "%",
  r_auditing_expenses: "CHF",
  r_max_property_management_fees: "%",
  r_valuation_expenses: "CHF",
  r_administration_of_buildings: "CHF",
  r_market_maker_fees: "CHF",
  r_total_net_assets: "CHF",
  r_absolute_custodian_bank_fees: "CHF",
  r_absolute_management_fees: "CHF",
  r_valuation_and_auditing_expenses: "CHF",
  r_unrealised_capital_gains_and_losses: "CHF",
  r_total_revenue: "CHF",
  r_total_properties: "CHF",
  r_total_liabilities: "CHF",
  r_total_interest: "CHF",
  r_total_expenses: "CHF",
  r_taxes: "CHF",
  r_total_cash: "CHF",
  r_total_income: "CHF",
  r_short_term_liabilities: "CHF",
  r_retained_earnings: "CHF",
  r_short_term_interest_bearing_mortgages: "CHF",
  r_rental_income: "CHF",
  r_realised_income: "CHF",
  r_revenue_from_postal_and_bank_accounts: "CHF",
  r_property_management_expenses: "CHF",
  r_property_tax: "CHF",
  r_realised_capital_gains_and_losses: "CHF",
  r_profit_and_capital_taxes: "CHF",
  r_maintenance_and_repairs_expenses: "CHF",
  r_maintenance_costs: "CHF",
  r_capital_distribution: "CHF",
  r_building_expenses: "CHF",
  r_construction_and_works_fees: "%",
  rental_losses: "%",
  energy: {},
  net_initial_yield: "%",
  gross_rent: "CHF",
  description: {
    distribution: "%"
  },
  rate: "%",
  market_value_per_m2: "CHF/m\xB2",
  portfolios: {
    hail_hazard_10_years_average: "%",
    accessibility: "m",
    overbank_sedimentation: "%",
    avalanches: "%",
    debris_flow: "%",
    rockfalls: "%",
    floods_50_years: "%",
    distribution: "%",
    landslides: "%"
  },
  wault: "ann\xE9es",
  share_class_nb: "Unit\xE9s",
  roic: "%",
  roi: "%",
  household_size: "Pers.",
  false: "Non",
  roe: "%",
  true: "Oui",
  immigration: "%",
  inhabitants_per_100_m2: "Pers.",
  emigration: "%",
  inhabitants_per_room: "Pers.",
  accessibility_index: "%",
  actual_rental_status: "CHF",
  income_per_taxpayer: "CHF",
  cost_price: "CHF",
  charges_per_m2: "CHF/m\xB2",
  rent_per_m2: "CHF/m\xB2",
  price_per_m2: "CHF/m\xB2",
  total_tenant_charges: "CHF",
  residential_net_rent_m2: "CHF/m\xB2",
  commercial_net_rent_m2: "CHF/m\xB2",
  funds: {
    transactions: {
      value: "CHF"
    },
    overbank_sedimentation: "%",
    hail_hazard_10_years: "%",
    sia261: "%",
    rockfalls: "%",
    debris_flow: "%",
    landslides: "%",
    avalanches: "%",
    floods_50_years: "%"
  },
  roa: "%",
  energy_intensity: "kWh/m\xB2/an",
  ter_mv: "%",
  ter_nav: "%",
  climate_risk_index: "%",
  market_price_var: "%",
  market_price: "CHF",
  five_years_avg_dividend_yield: "%",
  return_on_asset: "%",
  total_expense_ratio_ref_nav: "%",
  return_on_capital_employed: "%",
  dividend_yield: "%",
  dividend: "CHF",
  potential_net_rent: "CHF",
  built_up: {
    area: "m\xB2",
    ratio: "%"
  },
  real_charges: "CHF",
  demographic: {
    ages: "%"
  },
  real_net_rent: "CHF",
  yearly_management_fee: "%",
  ebit_margin: "%",
  rent_default_rate: "%",
  total_expense_ratio_ref_mv: "%",
  total_expense_ratio_ref_gav: "%",
  debt_financing_ratio: "%",
  return_on_equity: "%",
  return_on_invested_capital: "%",
  distribution_yield: "%",
  return_on_investment: "%",
  relative_agio: "%",
  distribution_value: "CHF",
  market_capitalization: "CHF",
  payout_ratio: "%",
  total_gross_assets: "CHF",
  share_class_nav: "CHF",
  share_class_gav: "CHF",
  swiss_net_rent: "CHF",
  canton_rent: "CHF",
  share_market_price: "CHF",
  press_score: "/10",
  birth: "%",
  death: "%",
  marriage: "%",
  volume: "m\xB3",
  divorce: "%",
  lot_area: "m\xB2",
  area: "m\xB2",
  surface: "m\xB2",
  hail_hazard_10_years_average: "cm",
  climate_normals_precipitation_1991_2020: "mm/yr",
  climate_normals_sunshine_duration_1991_2020: "%",
  market_cap: "CHF",
  ter_gav: "%",
  market_value: "CHF",
  total_co2: "kg/m\xB2/an",
  charges: "CHF",
  total_net_assets: "CHF",
  climate_normals_temperature_1991_2020: "\xB0C",
  decibels: "dB",
  total_area: "m\xB2",
  industrial_area: "m\xB2",
  agio: "%",
  debt_ratio: "%",
  net_rent: "CHF",
  office_area: "m\xB2",
  commercial_area: "m\xB2",
  idc_m2: "kWh/m\xB2/an",
  co2_m2: "kg/m\xB2/an",
  solar_potential: "kWh/yr",
  co2: "kg/an",
  residential_area: "m\xB2",
  idc: "kWh/an",
  solar_existing_per_m2: "kWh/m\xB2"
};
var funds = {
  environmental: {
    evolution: {
      chart: {}
    },
    idc: {
      idc_by_m2: {}
    },
    co2: {
      co2_scope_1_2_by_m2: {}
    },
    climate: {
      risk: {}
    },
    total_estimated_idc: "Intensit\xE9 \xE9nerg\xE9tique totale (estim\xE9)",
    estimated_idc_per_m2: "Intensit\xE9 \xE9nerg\xE9tique par m\xB2 (estim\xE9)",
    total_estimated_co2_emission: "CO\u2082 total (estim\xE9)",
    estimated_co2_emission_per_m2: "CO\u2082 par m\xB2 (estim\xE9)",
    tile: {
      idc_and_co2: "Intensit\xE9 \xE9nerg\xE9tique & CO\u2082",
      environmental_risks: "Risques environnementaux",
      energy_source: "Source d'\xE9nergie",
      solar: "Solaire",
      climate_risk_index: "Indice de risque climatique"
    },
    solar_existing_per_m2: "Puissance solaire install\xE9e par m\xE8tre carr\xE9",
    title: "Environnement",
    building_with_solar_existing: "B\xE2timents avec panneaux solaires",
    energy: {
      title: "\xC9nergie"
    },
    hail_hazard_10_years: "Taille de la gr\xEAle",
    overbank_sedimentation: "S\xE9dimentation en zone inond\xE9e",
    risk: {
      title: "Risque"
    },
    landslides: "Glissements de terrain",
    floods_50_years: "Inondations",
    solar_existing: "Puissance solaire install\xE9e",
    sia261: "SIA261",
    rockfalls: "Chutes de pierres",
    debris_flow: "\xC9coulement de d\xE9bris",
    avalanches: "Avalanches",
    solar_potential: "Potentiel solaire total"
  },
  social: {
    transport_accessibility: {
      transport_accessibility: {},
      road_accessibility: {}
    },
    well_being: {
      green_density: {},
      public_utility_density: {}
    },
    density: {
      job_density: {},
      population_density: {}
    },
    accessibility: {
      primary_education: "Enseignement primaire",
      shops: "Commerces",
      cultural_venues: "Lieux culturels",
      transport_train: "Train",
      transport_bus: "Bus",
      restaurants: "Restaurants",
      score: "Score",
      early_years: "Petite enfance",
      secondary_education: "Enseignement secondaire",
      aged_care: "Soins aux personnes \xE2g\xE9es",
      healthcare: "Soins de sant\xE9",
      mean_healthcare: "Soins de sant\xE9",
      mean_aged_care: "Soins aux personnes \xE2g\xE9es",
      mean_secondary_education: "Enseignement secondaire",
      mean_early_years: "Petite enfance",
      title: "Accessibilit\xE9",
      mean_score: "Score",
      mean_restaurants: "Restaurants",
      mean_transport_bus: "Bus",
      mean_cultural_venues: "Lieux culturels",
      mean_transport_train: "Train",
      mean_shops: "Commerces",
      mean_primary_education: "Enseignement primaire"
    },
    tile: {
      accessibility_score: "Accessibilit\xE9",
      accessibility_to_services: "Accessibilit\xE9 aux services"
    },
    promiscuity: {
      per_100_m2: "Par 100 m\xB2",
      departure_per_inhabitant: "D\xE9parts par habitant",
      title: "Densit\xE9 de population",
      per_bedroom: "Par chambre",
      arrival_per_inhabitant: "Arriv\xE9es par habitant",
      tile: {
        migration: "Migration",
        occupancy: "Occupation"
      }
    },
    demographic: {
      tile: {
        ages: "\xC2ges",
        gender: "Genre",
        foreigners: "\xC9trangers"
      },
      title: "D\xE9mographie"
    },
    title: "Social"
  },
  financials: {
    evolution: {
      chart: {}
    },
    metrics: {
      balance_sheet: {},
      income_statement: {},
      liquidity: {},
      quantitative_valuation_ratios: {},
      dividends: {},
      ratios: {}
    },
    side_menu: {},
    report: {},
    stock: {
      evolution: {},
      risk: {},
      trade: {},
      history: {}
    },
    stock_performance: {},
    portfolio: {
      c_market_value_by_m2: {},
      c_market_value: {},
      r_market_value_by_m2: {},
      r_market_value: {},
      r_cost_price_by_m2: {},
      r_cost_price: {}
    },
    fees: {
      maximum: {},
      effective: {}
    },
    rental_income: {
      estimated_by_m2: {},
      reported_by_m2: {},
      estimated: {},
      reported: {}
    },
    performance: {
      current_total_value: {},
      year_end_total_value: {},
      year_end_value_per_share: {},
      current_value_per_share: {}
    },
    debts: {},
    tile: {
      measures: "Mesures d'\xE9valuation",
      dividends: "Dividendes",
      trade: "Statistiques de trading",
      risk_statistics: "Statistiques de risque",
      stock_history: "Historique du cours de l'action",
      rental_income: "Revenus locatifs",
      key_performance: "Principaux indicateurs de performance",
      key_financials_per_share: "Performance financi\xE8re (par action)",
      evolution: "\xC9volution",
      key_financials: "Performance financi\xE8re (total)",
      return: "Rendement",
      charges: "Charges locataires",
      losses: "Pertes",
      fees: "Frais"
    },
    trading: {
      title: "Informations de trading"
    },
    highlights: {
      title: "Donn\xE9es financi\xE8res"
    },
    revenue: {
      title: "Revenu"
    },
    charges: {
      title: "Charges locataires"
    },
    title: "Finances"
  },
  governance: {
    tile: {
      certification: "Certifications",
      auditors: "Auditeurs",
      valuation_expert: "Experts immobiliers",
      property_management: "G\xE9rance immobili\xE8re",
      ownership: "D\xE9tention"
    },
    minergie_buildings: "Proportions de b\xE2timents labellis\xE9s Minergie",
    ranking: "Classement",
    esg: "ESG",
    governance: "Gouvernance",
    social: "Social",
    environmental: "Environnemental",
    custodian_bank: "Banque d\xE9positaire",
    director: "Direction de fonds",
    manager: "Gestion de fonds",
    certificates_rating: {},
    organisation: {}
  },
  composition: {
    semi_annual_report_date: "Dernier rapport (semestriel)",
    annual_report_date: "Dernier rapport (annuel)",
    report_date: "Dernier rapport",
    total_area: "Superficie totale",
    lots_nb: "Nombre de parcelles",
    lot_area: "Superficie du terrain",
    office_area: "Superficie totale de bureau",
    fund_age: "\xC2ge du portefeuille immobilier",
    residential_area: "Superficie totale r\xE9sidentielle",
    industrial_area: "Superficie totale industrielle",
    commercial_area: "Superficie totale commerciale",
    buildings_nb: "Nombre de b\xE2timents",
    report_age: "\xC2ge du rapport immobilier actuel"
  },
  environment: {
    energy_distribution: {}
  },
  description: {
    distribution: {},
    tile: {
      distribution: "Distribution",
      administrative: "Administratif",
      designation: "D\xE9signation",
      stock: "Stock",
      composition: "Composition"
    },
    composition: {
      title: "Composition"
    },
    title: "Description",
    identification: {
      title: "Identification"
    }
  },
  overview: {
    ter_gav: "TERrefGAV",
    total_co2: "CO\u2082",
    total_net_assets: "Fortune nette",
    net_rent: "Revenus locatifs",
    agio: "Agio"
  },
  transactions: {
    value: {
      purchase: "achet\xE9",
      sale: "vendu"
    },
    title: "Transactions"
  },
  page: {
    title: "Tous les fonds suisses"
  },
  performance: {
    total_expense_ratio_ref_nav: "Quote-part des charges d\u2019exploitation du fonds (TER_NAV)",
    return_on_capital_employed: "ROCE",
    return_on_asset: "ROA",
    five_y_avg_dividend_yield: "Rendement de dividende moyen sur 5 ans",
    r2: "R\xB2",
    beta: "Beta (5A mensuel)",
    alpha: "Alpha (5A mensuel)",
    volume_suffix: "Unit\xE9s",
    wault_suffix: "ans",
    mtd_net_return: "Mois \xE0 ce jour (MTD)",
    debt_financing_ratio: "Coefficient d\u2019endettement",
    total_gross_assets: "Fortune totale",
    return_on_equity: "Rendement des capitaux propres (ROE)",
    distribution_yield: "Rendement de distribution",
    rent_default_rate: "Pertes de location",
    ebit_margin: "Marge EBIT",
    yearly_management_fee: "Frais de gestion",
    total_expense_ratio_ref_mv: "Quote-part des charges d\u2019exploitation (TER_MV)",
    payout_ratio: "Ratio de distribution",
    relative_agio: "Agio",
    total_net_assets: "Fortune nette",
    market_capitalization: "Capitalisation boursi\xE8re",
    qtd_net_return: "Trimestre \xE0 ce jour (QTD)",
    total_expense_ratio_ref_gav: "Quote-part des charges d\u2019exploitation (TER_GAV)",
    return_on_investment: "Retour sur investissement (ROI)",
    ytd_net_return: "Ann\xE9e \xE0 ce jour (YTD)",
    wault_label: "Dur\xE9e moyenne pond\xE9r\xE9e des baux non \xE9chus (WAULT)",
    return_on_invested_capital: "Rendement du capital investi (ROIC)"
  },
  noright: {},
  financials_per_share: {
    share_market_price: "Cours de l\u2019action",
    share_class_gav: "Fortune totale part part",
    last_distribution: "Derni\xE8re distribution",
    distribution_value: "Valeur de distribution",
    share_class_nb: "Parts en circulation",
    share_class_nav: "Valeur nette des actifs (NAV)"
  },
  designation: {
    valor_no: "Num\xE9ro de valeur",
    bloomberg_ticker: "Ticker Bloomberg",
    instrument_name: "Nom de l'instrument",
    ticker: "Nom d\u2019usage",
    isin: "ISIN",
    currency: "Devise"
  },
  stock: {
    last_distribution: "Derni\xE8re distribution",
    distribution_frequency: "Fr\xE9quence de distribution",
    units_class: "Cat\xE9gorie d'unit\xE9s",
    units_nb: "Parts en circulation",
    year_end: "Date de fin d'ann\xE9e",
    distribution_date: "Date de distribution",
    launch_date: "Date de lancement"
  },
  administrative: {
    name: "Registre des actions",
    benchmark: "Benchmark",
    manager: "Gestionnaire de fonds",
    custodian_bank: "Banque d\xE9positaire",
    director: "Directeur de fonds",
    domicile: "Domicile du fonds",
    benchmark_bloomberg_ticker: "Ticker de r\xE9f\xE9rence"
  },
  assets: {
    title: "Actifs"
  }
};
var portfolios = {
  environmental: {
    evolution: {
      chart: {}
    },
    estimated_co2_emission_per_m2: "CO\u2082 par m\xB2 (estim\xE9)",
    total_estimated_co2_emission: "CO\u2082 total (estim\xE9)",
    estimated_idc_per_m2: "Intensit\xE9 \xE9nerg\xE9tique par m\xB2 (estim\xE9)",
    total_estimated_idc: "Intensit\xE9 \xE9nerg\xE9tique totale (estim\xE9)",
    overbank_sedimentation: "S\xE9dimentation en zone inond\xE9e",
    rockfalls: "Chutes de rochers",
    solar_potential: "Potentiel solaire total",
    floods_50_years: "Inondations",
    building_with_solar_existing: "B\xE2timents avec panneaux solaires",
    solar_existing_per_m2: "Puissance solaire install\xE9e par m\xE8tre carr\xE9",
    debris_flow: "\xC9coulement de d\xE9bris",
    landslides: "Glissements de terrain",
    solar_existing: "Puissance solaire install\xE9e",
    avalanches: "Avalanches",
    hail_hazard_10_years_average: "Taille de la gr\xEAle"
  },
  financials: {
    evolution: {
      chart: {}
    },
    performance: {
      evolution: {}
    }
  },
  environment: {
    energy_distribution: {}
  },
  description: {
    distribution: {}
  },
  noright: {},
  overview: {
    type: {
      empty: "Vide",
      dwelling: "Logement",
      mixed: "Mixte",
      building: "B\xE2timents"
    }
  },
  modal: {
    confirm_delete: {
      notification: {
        text: "Le portefeuille a \xE9t\xE9 supprim\xE9"
      },
      cancel: "Retour",
      proceed: "Continuer",
      text: "\xCAtes-vous s\xFBr de vouloir supprimer ce portefeuille ?",
      title: "Supprimer le portefeuille"
    }
  },
  create_modal: {},
  notification: {
    success_create: {}
  },
  widget: {
    energy: {},
    risk: {},
    identification: {
      title: "Identification"
    }
  },
  tiles: {
    solar: {},
    energy_source: {},
    risk_index: {},
    risks: {},
    composition: {
      title: "Cmposition"
    },
    designation: {
      title: "D\xE9signation"
    },
    distribution: {
      title: "Dtribution"
    },
    idc_and_co2: {
      title: "Intensit\xE9 \xE9nerg\xE9tique & CO\u2082"
    }
  },
  designation: {
    created_at: "Date de cr\xE9ation",
    updated_at: "Derni\xE8re mis \xE0 jour",
    name: "Nom de l'instrument",
    fund_age: "\xC2ge du portefeuille immobilier",
    commercial_area: "Superficie totale commerciale",
    industrial_area: "Superficie industrielle",
    individual_dwellings_nb: "Nombre de logements individuels",
    office_area: "Superficie totale de bureau",
    dwellings_nb: "Nombre de logements",
    buildings_nb: "Nombre de b\xE2timents",
    total_area: "Superficie totale",
    residential_area: "Superficie totale r\xE9sidentielle",
    type: "Composition des actifs du portefeuille"
  }
};
var portfolio = {
  environmental: {
    evolution: {}
  }
};
var actions = {
  screener: {
    per_page: {},
    preset: {
      reset: "R\xE9initialiser les pr\xE9r\xE9glages",
      save: "Enregistrer les pr\xE9r\xE9glages"
    },
    filter: {
      search: "Filtres",
      edit: "Editer les filtres",
      save: "Enregistrer les filtres",
      remove: "R\xE9initialiser les filtres"
    }
  },
  add_to_watchlist: {},
  update: {
    processing: {
      done: "L'op\xE9ration s'est d\xE9roul\xE9e avec succ\xE8s. La page sera bient\xF4t mise \xE0 jour."
    }
  },
  watchlist: {
    remove: {
      button: {
        label: "Ne plus suivre"
      },
      success: {
        message: "Actif retir\xE9 des favoris"
      }
    },
    add: {
      button: {
        label: "Suivre"
      },
      success: {
        message: "Actif ajout\xE9 aux favoris"
      }
    }
  },
  companies: {
    create: "Cr\xE9er une entreprise",
    delete: "Supprimer"
  },
  portfolios: {
    remove: {
      success: {
        message: "{{ asset }}(s) retir\xE9 de votre portfolio"
      }
    }
  },
  request_download: {
    csv: "T\xE9l\xE9charger CSV",
    message: "Requ\xEAte cr\xE9\xE9e"
  },
  add_to_portfolios: {
    adding_portfolio: {
      message: "Ajout de {{asset}}(s) \xE0 votre portefeuille",
      title: "Ajout"
    },
    success_add_portfolio: {
      message: "Ajout de {{asset}} \xE0 votre {{portfolio_state}} portefeuille",
      title: "R\xE9ussi"
    },
    success_create_portfolio: {
      title: "R\xE9ussi",
      message: "Nouveau portefeuille a \xE9t\xE9 cr\xE9\xE9"
    },
    confrm_modal: {
      title: "Logements d\xE9j\xE0 inclus",
      buttons: {
        cancel: "Retour",
        confirm: "Continuer"
      }
    },
    confirm_modal: {
      text_1: "Le portefeuille contient d\xE9j\xE0 le(s) logement(s) de {{building_label}}.",
      text_2: "Voulez-vous les remplacer par le b\xE2timent \xE0 la place ?"
    },
    modal: {
      portfolio_name: "Nom du portfolio",
      add_to_existing: "Ajouter \xE0 un existant",
      name: "Nom",
      create_button: "Cr\xE9er",
      new: "Nouveau"
    },
    title: "Ajouter au portefeuille"
  }
};
var candle_stick_chart = {
  unit: {
    chf: "CHF"
  },
  label: {
    close: "Close",
    high: "High",
    low: "Low",
    open: "Open",
    start_date: "Beginning",
    end_date: "Ending",
    volume: "Volume",
    turnover: "Turnover",
    date: "Date"
  }
};
var balance_sheet = {
  label: {},
  unit: {},
  legend: {
    group: {}
  }
};
var screener = {
  filters: {
    labels: {
      undefined: "-",
      columns_to_ignore: "Colonnes",
      sort: "Trier",
      roa: "ROA",
      charges: "Charges locataires",
      price: "Prix",
      rent: "Loyer",
      net_rent: "Loyer net",
      idc: "Intensit\xE9 \xE9nerg\xE9tique ",
      co2: "CO\u2082",
      label: "Label",
      energy_source_heating: "Source de chauffage",
      rooms_nb: "Nombre de pi\xE8ces",
      floor: "\xC9tage",
      elevator: "Ascenseur",
      ground_area: "Emprunte au sol",
      floors_nb: "Nombre d\u2019\xE9tages",
      dwellings_nb: "Nombre de logements",
      construction_year: "Ann\xE9e de construction",
      buildings_nb: "Nombre de b\xE2timents",
      plot_surface: "Surface de la parcelle ",
      municipality: "Municipalit\xE9",
      street: "Rue",
      locality: "Localit\xE9",
      region: "R\xE9gion",
      canton: "Canton"
    },
    multi_select: {
      options: {
        unlisted_fund: "Fonds non cot\xE9"
      }
    },
    groups: {},
    panel: {
      show: "Montrer"
    }
  },
  tabs: {
    financial: "Finances",
    energy: "\xC9nergie",
    dimension: "Dimensions",
    location: "Localisation"
  }
};
var table_header = {
  canton: "Canton",
  real_market_value: "Valeur marchande (annonc\xE9)",
  estimated_area: "Surface",
  zip: "Code postal",
  value: "Valeur",
  real_roa: "ROA (report\xE9)",
  egrid: "EGRID",
  has_building: "A un b\xE2timent",
  actual_target_roa: "ROA (cible report\xE9)",
  floor: "\xC9tage",
  real_net_rent_monthly: "Revenu locatif mensuel (annonc\xE9)",
  actual_market_value: "Valeur marchande (report\xE9)",
  address: "Adresse",
  municipality_name: "Municipalit\xE9",
  debt_ratio: "Coefficient d\u2019endettement",
  roa: "ROA",
  total_net_assets: "Fortune nette",
  actual_rental_status_target: "Statut de location r\xE9el cible actuel",
  actual_rental_status_real: "Revenu locatif (report\xE9)",
  ter_gav: "TERrefGAV",
  ewid: "Ewid",
  street_short: "Adresse",
  cost_price: "Prix d'achat",
  floors_nb: "\xC9tages",
  rooms_nb: "Nombre de pi\xE8ces",
  locality: "Localit\xE9",
  counterpart_fund: "Fonds correspondant",
  label: "Libell\xE9",
  construction_date: "Ann\xE9e de construction",
  id: "ID",
  rent_defaults: "D\xE9fauts de paiement de loyer",
  agio: "Agio",
  actual_real_roa: "ROA (report\xE9)",
  last_renovation_year: "Derni\xE8re ann\xE9e de r\xE9novation",
  commercial_area: "Surface commerciale",
  market_cap: "Capitalisation boursi\xE8re",
  duplex: "Duplex",
  date: "Ann\xE9e",
  real_charges_monthly: "Charges locataires mensuels (annonc\xE9)",
  egid: "EGID",
  rights: "Droits",
  role: "R\xF4le",
  actions: "Plus d\u2019actions",
  area: "Surface",
  fund_name: "Nom du fonds",
  market_price: "Cours de l\u2019action",
  state: "Processus d\u2019invitation",
  companies: "Compagnies",
  subscribed_date: "Abonn\xE9 depuis",
  last_active: "Derni\xE8re activit\xE9",
  value_net_asset: "Valeur des actifs nets",
  value_gross_asset: "Valeur des actifs brutes",
  share_class_nb: "Nombre de parts",
  environment: {
    score: "Score environmental"
  },
  total_gross_assets: "Total des actifs brutes",
  co2: "CO\u2082 ",
  ter_nav: "TERrefNAV",
  dividend_date: "Date de distribution",
  dividend: "Dividende",
  payout_ratio: "Ratio de distribution",
  ter_mv: "TERrefMV",
  roe: "Rentabilit\xE9 des capitaux propres",
  share_class_gav: "Valeur des actifs bruts",
  yearly_management_fee: "Frais de gestion",
  ticker: "Ticker",
  rent_default_rate: "Taux de perte sur loyer",
  roic: "RCI",
  roi: "RSI",
  share_class_nav: "Valeur des actifs nets",
  real_net_rent: "Revenu locatif (annonc\xE9)",
  dividend_yield: "Rendement du dividende",
  estimated_co2_emission_per_m2: "CO\u2082 par m\xB2",
  fund_names: "Propri\xE9taire",
  real_charges: "Charges locataires (annonc\xE9)",
  estimated_idc_per_m2: "Intensit\xE9 \xE9nerg\xE9tique par m\xB2",
  created_at: "Date de cr\xE9ation",
  fund_legal_forms: "Type de propri\xE9taire",
  rental_losses: "Pertes sur loyers",
  potential_charges_monthly: "Charges locataires mensuels",
  potential_net_rent: "Revenu locatif",
  potential_roa: "ROA",
  potential_market_value: "Valeur marchande",
  potential_net_rent_monthly: "Revenu locatif mensuel",
  potential_charges: "Charges locataires",
  potential_market_value_per_m2: "Valeur marchande par m\xB2"
};
var table = {
  fund_type: {},
  fund_legal_form: {}
};
var setting = {
  preference: {
    notification: {
      unsaved_screen: {}
    },
    unsaved_screen_notification: {},
    language: {
      subtitle: "S\xE9lectionnez votre langue pr\xE9f\xE9r\xE9e.",
      title: "Langue",
      success_message: "La pr\xE9f\xE9rence linguistique a \xE9t\xE9 enregistr\xE9e."
    }
  },
  profile: {
    email_preferences: {
      technical: {},
      marketing: {}
    },
    notification: {
      send_email_duplicated_msg: "Un email de v\xE9rification a \xE9t\xE9 envoy\xE9 dans la minute qui suit, veuillez v\xE9rifier votre bo\xEEte aux lettres.",
      send_email_success_msg: "Un email de v\xE9rification a \xE9t\xE9 envoy\xE9, veuillez v\xE9rifier votre bo\xEEte aux lettres.",
      wrong_first_name_msg: "Veuillez saisir votre pr\xE9nom exact.",
      change_pp_success_msg: "Votre photo de profil a \xE9t\xE9 modifi\xE9e avec succ\xE8s.",
      error: "Un probl\xE8me s\u2019est produit",
      success: "R\xE9ussi"
    },
    picture: {
      format_error: "Probablement un mauvais format ou une mauvaise taille.",
      error: "L\u2019image n\u2019a pas pu \xEAtre t\xE9l\xE9vers\xE9e"
    },
    confirm_crop: "Confirmer",
    email_verification_note: {
      button: "Renvoyer le code de v\xE9rification",
      msg: "Veuillez v\xE9rifier votre adresse \xE9lectronique avant d'utiliser la plateforme.",
      title: "V\xE9rifiez votre e-mail"
    },
    confirm_delete_pp: {
      delete_button: "Supprimer le compte",
      cancel_button: "Annuler",
      msg: "Pour confirmer que vous souhaitez supprimer votre compte, veuillez saisir votre pr\xE9nom ci-dessous et cliquer sur le bouton Supprimer le compte.",
      modal_title: "Confirmer la suppression"
    },
    click_to_upload_file_constraints: "ou glisser-d\xE9poser PNG, JPEG ou JPG",
    click_to_upload: "Cliquez pour t\xE9l\xE9verser",
    save_changes: "Sauvegarder les changements",
    delete_my_account_title_sub: "Il n'y a pas de retour possible.",
    delete_my_account_title: "Supprimer mon compte",
    profile_picture_title_sub: "T\xE9l\xE9chargez votre photo de profil.",
    profile_picture_title: "Photo de profil",
    personal_info_title_sub: "Mettez \xE0 jour vos donn\xE9es personnelles.",
    personal_info_title: "Informations personnelles"
  },
  connections: {
    modal: {
      warning: {},
      delete: {},
      create: {},
      name: {},
      expiration: {}
    },
    options: {},
    days: {},
    name: {},
    table_name: {},
    tokens_table: {
      col_name: {}
    }
  },
  seats: {
    payment_warning: {
      msg: {
        owner: "Veuillez contacter votre gestionnaire de compte.",
        user: "Veuillez contacter ce chef d'entreprise."
      },
      button: "Contacter le service client\xE8le",
      title: "Il y a eu un probl\xE8me avec votre paiement"
    },
    member_table: {
      col_name: {
        last_activity_date: "Actif depuis",
        right: "Droits",
        role: "R\xF4le",
        name: "Nom"
      }
    },
    no_slot_warning: {},
    delete_user: {
      multiple: {
        confirm_msg: "\xCAtes-vous s\xFBr de vouloir supprimer ces {{number}} utilisateurs ?"
      },
      success_msg: "L\u2019utilisateur a \xE9t\xE9 supprim\xE9",
      confirm_msg: "\xCAtes-vous s\xFBr de vouloir supprimer cet utilisateur {{firstName}} {{lastName}} ?"
    },
    am_table: {
      col_name: {
        phone: "Num\xE9ro de t\xE9l\xE9phone du gestionnaire de comptes",
        email: "Adresse email du gestionnaire de comptes",
        name: "Gestionnaires de comptes"
      }
    },
    plan_card: {
      info: "{{used}} des {{total}} si\xE8ges utili\xE9s"
    },
    institutional: {
      name: "Institutionel"
    },
    investor: {
      name: "Investisseur"
    },
    consumer: {
      name: "Consommateur"
    }
  },
  security: {
    link_device: {},
    warning_note: {},
    renegerate_code: {},
    password: {
      disclaimer: "Les meilleurs mots de passe contiennent souvent des majuscules, des symboles et des chiffres.",
      success_message: "Mot de passe mis \xE0 jour avec succ\xE8s",
      error_message: "Le mot de passe doit \xEAtre identique",
      save_changes: "Enregistrer les modifications",
      confirm: "Confirmer le nouveau mot de passe",
      subtitle: "Changer votre mot de passe",
      title: "Mot de passe"
    }
  },
  company: {
    set_contact_user: {}
  },
  organization: {
    details: {
      contact_name: "Utilisateur de contact",
      phone: "Num\xE9ro de t\xE9l\xE9phone",
      email: "Adresse email",
      zip: "NPA",
      city: "Ville",
      address: "Adresse",
      country: "Pays",
      name: "Nom de l\u2019entreprise",
      subtitle: "Mettre \xE0 jour les d\xE9tails de votre entreprise.",
      title: "Informations de l\u2019entreprise"
    },
    notification: {
      update_success: "Les mises \xE0 jour ont \xE9t\xE9 appliqu\xE9es avec succ\xE8s."
    },
    logo: {
      subtitle: "Mettre \xE0 jour le logo de votre entreprise.",
      title: "Logo de l\u2019entreprise"
    }
  },
  collaborator: {
    modal: {
      link_companies: {}
    },
    col: {
      companies: "Compagnie",
      invitation_process: "Processus d\u2019invitation "
    },
    table_name: "Membres d\u2019\xE9quipe",
    reinvite_user: "Un email d'invitation a \xE9t\xE9 envoy\xE9 avec succ\xE8s."
  },
  companies: {
    table_name: "Membres de la compagnie"
  }
};
var unsaved_screener = {
  tooltip: {},
  warning: {},
  modal: {},
  button: {}
};
var management = {
  nav: {
    preferences: {},
    connections: {},
    edit_company: {},
    create_company: {},
    company: {},
    requests: {
      subtitle: "Toutes les demandes seront rassembl\xE9es ici.",
      title: "Requ\xEAtes"
    },
    contracts: {
      title: "Contrats"
    },
    users: {
      subtitle: "Tous les utilisateurs de toutes les entreprises se retrouvent ici.",
      title: "Utilisateurs"
    },
    teams: {
      subtitle: "Voici tout ce que vous devez savoir sur les personnes qui acc\xE8dent \xE0 la plateforme dans votre entreprise.",
      title: "\xC9quipe"
    },
    companies: {
      title: "Entreprises",
      subtitle: "Voici toutes les entreprises qui souscrivent \xE0 la plateforme."
    },
    profile: {
      subtitle: "Mettre \xE0 jour les param\xE8tres de profil",
      title: "Profil"
    },
    security: {
      subtitle: "G\xE9rez votre mot de passe pour acc\xE9der \xE0 la plateforme.",
      title: "S\xE9curit\xE9"
    },
    languages: {
      subtitle: "G\xE9rer les langues"
    },
    language: {
      title: "Langue"
    },
    organization: {
      subtitle: "Voici toutes les informations sur l'entreprise dans laquelle vous travaillez.",
      title: "Organisation"
    },
    seats: {
      subtitle: "Voici tout ce qu'il faut savoir sur les si\xE8ges et la facturation",
      title: "Si\xE8ges"
    },
    collaborators: {
      subtitle: "Voici tout ce que vous devez savoir sur les personnes qui acc\xE8dent \xE0 la plateforme dans votre entreprise.",
      title: "Collaborateur"
    }
  },
  email: {
    addresses: {
      empty: "Vos utilisateurs seront affich\xE9s ici",
      description: "Faites-le un par un ou passez une liste d\u2019emails s\xE9par\xE9s par une virgule et validez avec entr\xE9e",
      title: "Adresses email"
    }
  },
  category: {
    communication: {
      title: "Communication"
    },
    administration: {
      title: "Administration"
    },
    customers: {
      title: "Clients"
    },
    personal: {
      title: "Personnel"
    },
    company: {
      title: "Entreprise"
    },
    team: {
      title: "\xC9quipe"
    }
  },
  header: {
    gotoplatform: "Acc\xE9der \xE0 la plateforme",
    logout: "D\xE9connexion"
  }
};
var companies = {
  button: {
    check_states: "V\xE9rifier les \xE9tats",
    give_am_rights: "Assigner les droits AM",
    refresh_roles: "Rafra\xEEchir les slots",
    give_default_roles: "Assigner les r\xF4les par d\xE9fault"
  },
  modal: {
    confirm_delete: {}
  },
  notifications: {}
};
var asset_page = {
  financials: {
    units: {
      commercial_rent: "CHF",
      residential_rent: "CHF",
      total_rent: "CHF"
    },
    ratio_metric: {
      commercial_spaces: {
        label: "Espaces commerciaux"
      },
      dwellings: {
        label: "Logements"
      },
      total_tenant_charges: "Total des charges locataires",
      market_value: "Valeur de march\xE9",
      charges_per_m2: "Charges locataires par m\xB2",
      price_per_m2: "Prix par m\xB2",
      rent_per_m2: "Loyer par m\xB2",
      charges: {
        title: "Charges locataires"
      },
      rent: {},
      price: {}
    },
    unit: {
      chf: "CHF"
    },
    charges: {
      title: "Charges locataires"
    },
    total: {
      label: "Total"
    },
    commercial_spaces: {
      label: "Espaces commerciaux"
    },
    dwellings: {
      label: "Logements"
    },
    performance: {
      title: "Performance"
    },
    metrics: {
      title: "M\xE9trique par m\xE8tre carr\xE9"
    },
    tile: {
      charges: "Charges locataires",
      evolution: "\xC9volution",
      rental_income: "Revenus locatifs"
    },
    revenue: {
      title: "Revenus"
    },
    comparables: {},
    title: "Finances"
  },
  description: {
    tile: {
      buildings: {},
      usage: "Utilisation",
      geometry: "G\xE9om\xE9trie",
      dwellings: {},
      description: "Description",
      location: "Emplacement",
      addresses: "Adresses",
      administrative_information: "Informations administratives",
      amenities: "Commodit\xE9s"
    },
    title: "Description",
    identification: {
      title: "Identification"
    },
    general_information: {
      title: "Informations g\xE9n\xE9rales"
    }
  },
  social: {
    accessibility: {
      chart: {
        suffix: "/10"
      },
      goods_and_services: {
        title: "Biens et services"
      },
      transportation: {
        title: "Transport"
      },
      health_and_edu: {
        title: "Sant\xE9 & \xC9ducation"
      },
      title: "Accessibilit\xE9",
      distance: {}
    },
    demographic: {
      migration: {
        title: "Emigration"
      },
      emigration: {
        subject: "Emigration"
      },
      immigration: {
        subject: "Immigration"
      },
      death: {
        subject: "Mortalit\xE9"
      },
      birth: {
        subject: "Natalit\xE9"
      },
      vital_statistics: {
        title: "Statistiques vitales"
      },
      civil_status: {
        death: "D\xE9c\xE8s",
        birth: "Naissances",
        marriage: "Mariages"
      },
      foreigners: {
        title: "\xC9trangers"
      },
      gender: {
        title: "Genre"
      },
      ages: {
        title: "Ages"
      },
      title: "D\xE9mographie"
    },
    density: {
      occupancy_per_bedroom: {
        subject: "Habitants",
        title: "Taux d\u2019occupation par chambre"
      },
      occupancy_per_m2: {
        subject: "Habitants"
      },
      occupancy_per_100_m2: {
        title: "Taux d\u2019occupation par 100 m\xB2"
      },
      occupancy: {},
      migration: {},
      income: {}
    },
    income_and_taxes: {
      income: {
        subject: "Revenu par contribuable",
        title: "Revenus",
        label: "Revenus"
      },
      profit: {
        label: "Profits"
      },
      capital: {
        label: "Capital"
      },
      wealth: {
        label: "Fortune"
      },
      municipality_tax: {
        title: "Multiplicateurs d'imp\xF4t de la municipalit\xE9"
      },
      canton_tax: {
        title: "Multiplicateurs d'imp\xF4t du canton"
      },
      title: "Revenus et imp\xF4ts"
    },
    title: "Social"
  },
  environmental: {
    energy: {
      idc: {
        estimated_idc: "Intensit\xE9 \xE9nerg\xE9tique estim\xE9e",
        total_official_idc: "Intensit\xE9 \xE9nerg\xE9tique totale (officiel)",
        estimated_idc_per_m2: "Intensit\xE9 \xE9nerg\xE9tique par m\xB2 (estim\xE9)",
        total_estimated_idc: "Intensit\xE9 \xE9nerg\xE9tique totale (estim\xE9)",
        official_idc_per_m2: "Intensit\xE9 \xE9nerg\xE9tique par m\xB2 (officiel)",
        title: "Intensit\xE9 \xE9nerg\xE9tique"
      },
      co2: {
        estimated_co2: "CO\u2082 estim\xE9",
        official_co2_emission_per_m2: "CO\u2082 par m\xB2 (officiel)",
        total_official_co2_emission: "CO\u2082 total (officiel)",
        estimated_co2_emission_per_m2: "CO\u2082 par m\xB2 (estim\xE9)",
        total_estimated_co2_emission: "CO\u2082 total (estim\xE9)",
        title: "\xC9missions de CO\u2082"
      },
      title: "\xC9nergie",
      heating: {
        energy_generator_water_1: "G\xE9n\xE9rateur d\u2019eau chaude",
        energy_source_water_1: "Source d'\xE9nergie de l'eau chaude",
        energy_source_heating_1: "Source d'\xE9nergie de chauffage",
        energy_generator_heating_1: "G\xE9n\xE9rateur de chaleur",
        title: "Production de chaleur"
      },
      solar: {
        solar_existing: "Production solaire install\xE9e",
        solar_potential: "Potentiel de production solaire",
        title: "Solaire"
      }
    },
    risk: {
      title: "Risques",
      risk: {
        title: "Risques environnementaux"
      },
      climate_risk_index: {
        title: "Indice de risque climatique"
      },
      rockfalls: "Chutes de pierres",
      sia261: "Zone sismique SIA 261",
      hail_hazard_10_years_average: "Taille des gr\xEAlons",
      avalanches: "Avalanches",
      floods_50_years: "Innondations"
    },
    title: "Environnemental",
    environment: {
      climate: {
        temperature: {},
        sunlight: {},
        rain: {}
      },
      pollution: {
        rail_noise_day: {},
        rail_noise_night: {},
        road_noise_day: {},
        road_noise_night: {}
      },
      title: "Environnement"
    }
  },
  valuation: {
    cap_rate_method: {
      income_inputs: {
        other: {
          suffix: "CHF",
          label: "Autres revenus"
        },
        parking: {
          label: "Parking",
          suffix: "CHF"
        },
        title: "Donn\xE9es sur les revenus",
        office_net_income: {
          label: "Revenu net des bureaux",
          suffix: "CHF"
        },
        commercial: {
          label: "Commercial",
          suffix: "CHF"
        },
        industrial: {
          label: "Industriel",
          suffix: "CHF"
        },
        residential_net_income: {
          suffix: "CHF",
          label: "Revenu net r\xE9sidentiel"
        },
        dwelling_net_rent: {
          suffix: "CHF"
        }
      },
      cap_rate_inputs: {
        title: "Donn\xE9es sur le taux de capitalisation",
        capitalization_rate: {
          label: "Taux de capitalisation",
          suffix: "%"
        }
      },
      result: {
        total_acquisition_cost: {
          suffix: "CHF",
          label: "Co\xFBts d\u2019acquisition totaux"
        },
        purchase_price: {
          label: "Prix d\u2019achat",
          suffix: "CHF"
        },
        title: "R\xE9sultat"
      },
      title: "M\xE9thode du taux de capitalisation",
      description: {
        content: "La m\xE9thode du taux de capitalisation (cap rate) appliqu\xE9e \xE0 l'immobilier permet d'estimer la valeur potentielle d'un bien. Elle est calcul\xE9e en divisant les revenus locatifs par le rendement vis\xE9. Les taux de capitalisation ne tiennent pas compte de l'appr\xE9ciation future de la valeur ni des co\xFBts de financement.",
        title: "Description"
      }
    },
    parameters: {
      buyer_seller: {
        other_fees_chf: {
          suffix: "CHF",
          label: "Autres frais"
        },
        other_fees_pts: {
          suffix: "%",
          label: "Autres frais"
        },
        asset_manager_fees: {
          label: "Frais de gestion",
          suffix: "%"
        },
        share_buyers_seller: {
          label: "Part acheteur / vendeur",
          suffix: "%"
        },
        title: "Acheteur / Vendeur"
      },
      transaction_fees: {
        property_transfert_fees: {
          suffix: "%",
          label: "Droit de mutation"
        },
        title: "Frais de transaction",
        land_registration_fees: {
          label: "Frais de registre foncier",
          suffix: "%"
        },
        notary_fees: {
          label: "Frais de notaire",
          suffix: "%"
        }
      },
      due_diligence_cost: {
        other_valuations: {
          suffix: "CHF",
          label: "Autres \xE9valuations"
        },
        valuation: {
          suffix: "CHF",
          label: "\xC9valuation"
        },
        title: "Co\xFBt d\u2019expertise"
      },
      title: "Param\xE8tres"
    },
    qh_method: {
      title: "M\xE9thode Quanthome",
      result: {
        total_acquisition_cost: {
          label: "Co\xFBt d\u2019acquisition total",
          suffix: "CHF"
        },
        purchase_price: {
          suffix: "CHF",
          label: "Prix d\u2019achat"
        },
        title: "R\xE9sultat"
      },
      description: {
        title: "Description",
        content: "Les algorithmes de machine learning de Quanthome estiment le rendement cible attendu pour le b\xE2timent et pr\xE9disent une valeur bas\xE9e sur les flux de tr\xE9sorerie estim\xE9s."
      }
    },
    title: "\xC9valuation"
  },
  governance: {
    item: {
      ownership: {
        registry: "Registre foncier",
        owner: "Propri\xE9taire"
      },
      certification: {
        minergy: "Minergie"
      },
      management: {
        website: "Site web",
        name: "Nom"
      }
    },
    tile: {
      certification: "Certification",
      management: "G\xE9rance immobili\xE8re",
      ownership: "Propri\xE9taire"
    },
    organisation: {
      title: "Organisation"
    },
    title: "Gouvernance"
  },
  overview: {
    description: {
      market_value: "Valeur marchande",
      idc: "Intensit\xE9 \xE9nerg\xE9tique",
      net_rent: "Revenu locatif",
      co2: "CO\u2082",
      roa: "ROA",
      charges: "Charges locataires"
    }
  },
  assets: {
    title: "Actifs"
  }
};
var income_statement = {
  label: {},
  legend: {
    group: {}
  },
  unit: {}
};
var double_range_input = {};
var overview = {
  property: {
    unlisted_fund: "Fonds non cot\xE9",
    listed_fund: "Fonds cot\xE9"
  }
};
var source_badge = {
  forecasted: "Projet\xE9",
  computed: "Calcul\xE9",
  expected: "Estim\xE9",
  listed: "List\xE9",
  reported: "Report\xE9",
  official: "Officiel"
};
var common = {
  real_estate_firm: {
    pascalcase: "Soci\xE9t\xE9 immobili\xE8re"
  },
  foundation: {
    pascalcase: "Fondation de placement"
  },
  unlisted_fund: {
    pascalcase: "Fonds non cot\xE9"
  },
  listed_fund: {
    pascalcase: "Fonds cot\xE9"
  },
  transactions: {},
  enterprise_state: {},
  developer_mode: {},
  institutions: {
    pascalcase: "Institutions"
  },
  connection_lost: {
    reload_button: "Recharger",
    message_2: "L'application ne peut pas fonctionner comme pr\xE9vu",
    message_3: "Veuillez recharger la page.",
    title: "La connexion a \xE9t\xE9 perdue",
    message_1: "Il est possible que vous ne soyez plus connect\xE9 \xE0 Internet"
  },
  financials: {
    array: {
      total: "Total",
      residential: "R\xE9sidentiel",
      office: "Bureau",
      commercial: "Cmmercial",
      industrial: "Idustriel"
    }
  },
  edit: "\xC9diter",
  slot: {},
  role: {
    owner: "Propri\xE9taire"
  },
  removing: "suppression",
  error: "Un probl\xE8me s\u2019est produit",
  success: "R\xE9ussi",
  delete_user: "Supprimer l\u2019utilisateur",
  cancel_button: "Annuler",
  confirm_button: "Confirmer",
  notification: {
    success: {
      title: "R\xE9ussi"
    }
  },
  lots: {
    pascalcase: "Parcelles",
    lowercase: "parcelles"
  },
  error_message: "Un probl\xE8me s\u2019est produit",
  buildings: {
    lowercase: "b\xE2timents",
    pascalcase: "B\xE2timents"
  },
  dwellings: {
    lowercase: "Logements",
    pascalcase: "Logements"
  },
  portfolios: {
    pascalcase: "Portefeuilles"
  },
  funds: {
    pascalcase: "Fonds"
  }
};
var multiple_select = {
  placeholder: {}
};
var single_select = {};
var screener_bar = {
  main_button: {}
};
var modal = {
  notifications: {
    title_recoverycode: "G\xE9n\xE9rer \xE0 nouveau vos codes de r\xE9cup\xE9ration",
    title_download: "T\xE9l\xE9chargement",
    title_administration: "Administration",
    title: "Notifications",
    empty_message: "Pas de notifications"
  },
  downloads: {
    not_available: "La fonction de t\xE9l\xE9chargement n'est pas disponible avec votre plan tarifaire actuel.",
    subtitle: {
      file_failed: "Votre fichier a \xE9chou\xE9 en raison d'une erreur - Veuillez contacter votre administrateur.",
      file_expired: "Votre fichier a expir\xE9",
      file_ready: "Votre fichier est pr\xEAt \xE0 \xEAtre t\xE9l\xE9charg\xE9",
      file_be_ready_soon: "Votre fichier sera bient\xF4t pr\xEAt \xE0 \xEAtre t\xE9l\xE9charg\xE9"
    },
    empty_message: "Pas de t\xE9l\xE9chargements",
    title: "T\xE9l\xE9chargements"
  },
  notification: {
    title_download: "T\xE9l\xE9chargement"
  },
  history: {
    title: "Historic",
    empty_history: "Aucun historique"
  },
  preset: {
    empty_message: "Pas de pr\xE9r\xE9glages",
    title: "Pr\xE9r\xE9glages"
  },
  historic: {},
  leaving: {
    registry: {},
    buttons: {}
  },
  support: {
    submit_button: "Demander notre support",
    select: {
      placeholder: "Choisir",
      title: "Probl\xE8me, message, besoin, id\xE9e...",
      need: "Besoin",
      message: "Message",
      problem: "Probl\xE8me"
    },
    message: "Message",
    complete_title: "Support / Contact",
    error_message: {
      no_message: "Vous devez \xE9crire un message",
      too_many_char: "Trop de caract\xE8res {{char_nb}} > 500",
      bad_category: "Vous devez s\xE9lectionner une cat\xE9gorie"
    },
    success: {
      message: "Votre rapport a \xE9t\xE9 envoy\xE9 avec succ\xE8s \xE0 notre service client",
      title: "Rapport envoy\xE9"
    },
    title: "Aide",
    upload: "T\xE9l\xE9charger"
  }
};
var line_chart = {
  placeholder: {}
};
var empty_chart = {};
var administration = {
  companies: {
    col_name: {
      used_consumer_seat: "Comptes utilisateurs activ\xE9s",
      total_consumer_seat: "Nombre total de comptes",
      used_all_seats: "Licenses assign\xE9s",
      total_all_seats: "Licenses totales",
      license_use: "Utilisation de la license",
      all_users: "Membres de l\u2019\xE9quipe",
      contact_user_phone: "Num\xE9ro de t\xE9l\xE9phone du contact",
      contact_user_email: "Email du contact",
      contact_user: "Personne de contact",
      related_am: "Gestionnaire de compte associ\xE9",
      expiration_date: "Date de fin du contrat",
      subscription_start: "Date de souscription",
      days_left: "Jours restants",
      state: "Type de contrat",
      name: "Entreprise"
    },
    headers: {
      companies_count: "{{count}} Entreprises",
      member_count: "{{ count }} membres"
    },
    actions: {
      update_company_slots: "Mettre \xE0 jour ",
      update_company: "Modifier les param\xE8tres de l'entreprise"
    },
    cells: {
      user_count: "{{ count }} utilisateurs"
    }
  },
  collaborators: {
    headers: {
      members_count: "{{ count }} Membres "
    }
  },
  breadcrumb: {}
};
var treemap = {
  label: {},
  unit: {}
};
var action = {
  label: {
    invite_member: "Ajouter un membre"
  },
  portfolios: {
    name: {
      change_success: {
        message: "Vous avez modifi\xE9 avec succ\xE8s le nom du portefeuille",
        title: "Le nom a \xE9t\xE9 modifi\xE9"
      }
    },
    remove: {
      loading: {
        message: "Suppression de(s) entit\xE9e(s) de votre portefeuille"
      },
      confirmation: {
        question: "\xCAtes-vous s\xFBr de vouloir retirer l'entit\xE9 de votre portefeuille ?",
        title: "Suppression depuis portefeuille"
      }
    }
  },
  users: {
    remove: {
      confirmation: {
        question: "\xCAtes-vous s\xFBr de vouloir supprimer ces utilisateurs ?"
      }
    }
  },
  companies: {}
};
var pyramid_chart = {
  label: {}
};
var asset = {
  description: {
    dwellings: {
      rooms_nb: "Nombre de pi\xE8ces",
      ewid: "EWID",
      surface: "Surface",
      floor: "\xC9tage",
      construction_date: "Date de construction",
      type: "Type",
      category: "Cat\xE9gorie",
      class: "Classe",
      status: "Statut",
      land_use: "Usage",
      city: "Ville",
      egid: "EGID"
    },
    lots: {
      built_up_ratio: "Taux de surface construite",
      built_up_area: "Surface construite",
      municipality: "Municipalit\xE9",
      egrid: "EGRID",
      surface: "Surface",
      sector: "Secteur",
      number: "Num\xE9ro",
      label: "Label",
      type: "Type",
      land_use: "Usage",
      city: "Ville"
    },
    amenities: {
      dwellings: {
        imputed_mansarde: "Mansarde",
        imputed_attique: "Attique",
        imputed_balcony: "Blcon",
        imputed_cellar: "Cave"
      },
      buildings: {
        imputed_cachet: "Cachet",
        imputed_bike_room: "Garage \xE0 v\xE9lo",
        imputed_elevator: "Ascenseur",
        fallout_shelter: "Abri antiatomique"
      }
    },
    buildings: {
      dwellings_nb: "Nombre de logements",
      floors_nb: "Nombre d\u2019\xE9tages",
      volume: "Volume total",
      area: "Surface au sol",
      total_area: "Surface totale",
      industrial_area: "Surface industrielle",
      commercial_area: "Surface commerciale",
      office_area: "Surface de bureaux",
      residential_area: "Surface r\xE9sidentielle",
      construction_date: "Date de construction",
      type: "Type",
      category: "Cat\xE9gorie",
      class: "Classe",
      status: "Statut",
      land_use: "Usage",
      official_nb: "Num\xE9ro officiel",
      city: "Ville",
      egid: "EGID"
    }
  }
};
var errors = {
  fii: {
    fii_portfolio_duplicated_dwelling_id: "Tous les logements font d\xE9j\xE0 partie de ce portefeuille.",
    fii_invalid_dwelling_id: "ID du logement invalide.",
    fii_portfolio_duplicated_building_id: "Tous les b\xE2timents font d\xE9j\xE0 partie de ce portefeuille.",
    fii_invalid_building_id: "ID du b\xE2timent invalide."
  }
};
var buildings = {
  type: {
    commercial: "Commercial",
    residential: "R\xE9sidentiel",
    mixed: "Mixte"
  }
};
var items = {
  charts: {
    gradient: {
      subject: {
        index: "Index",
        indice: "Indice"
      }
    }
  },
  array: {
    columns: {
      title: {
        difference: "Diff\xE9rence",
        potential: "Estim\xE9",
        real: "Report\xE9 / Annonc\xE9"
      }
    }
  }
};
var tile = {
  side_menu: {
    country: "Pays",
    canton: "Canton",
    municipality: "Localit\xE9"
  }
};
var gradient = {
  quartile_3: "3e quartile",
  quartile_2: "M\xE9diane",
  quartile_1: "1er quartile",
  chart: {
    no_data: "Pas de donn\xE9es"
  },
  label: {}
};
var badge = {
  beta: "Beta"
};
var valuations = {
  actions: {
    reset: "R\xE9initialiser"
  }
};
var header = {
  navbar: {
    institutions: "Institutions",
    map: "Carte",
    funds: "March\xE9s",
    portfolios: "Portefeuilles",
    watchlist: "Favoris",
    screener: "Recherche",
    dashboard: "Tableau de bord"
  },
  dropdown_menu: {
    my_company: "Mon entreprise",
    settings: "Param\xE8tres",
    logout: "D\xE9connexion",
    back_office: "Administration",
    profile: "Mon profil"
  }
};
var input = {
  select: "s\xE9lectionner",
  edit: "\xE9diter"
};
var view = {
  switch: {
    cards: "Voir comme cartes",
    table: "Voir comme tableau"
  }
};
var unit = {
  chf_per_m2: "CHF/m\xB2"
};
var boarding_page = {
  show_recovery: {
    explain: {},
    label: {},
    button: {}
  },
  register: {
    repeat_password: "R\xE9p\xE9ter le mot de passe",
    error: {},
    notification: {
      changed_email: {},
      flow_expiration: {}
    }
  },
  verify_mail: {
    step: {
      sent_email: {
        success: {}
      },
      choose_method: {
        success: {}
      }
    }
  },
  otp: {
    failed: {},
    button: {},
    step: {}
  },
  forgot_password: {
    success: {},
    button: {}
  },
  login: {
    aal2: {},
    aal1: {},
    failed: {}
  },
  recovery_code: {
    faq: {
      lock: {},
      what_is_recovery: {}
    },
    form: {},
    reset: {
      success: {}
    }
  }
};
var policy = {
  cookies: {
    types_list: {}
  },
  eea_rights_list: {},
  data_list: {},
  data_usage: {},
  sharing_list: {}
};
var login = {};
var watchlist = {
  buildings: {},
  dwellings: {}
};
var download = {};
var profile_train = {
  tooltip: {
    more: "{{count}} de plus"
  }
};
var impressum = {
  mail: "Adresse e-mail",
  address: "Adresse postale : Avenue Mon-Repos 24, 1005 Lausanne"
};
var notification = {
  terminated_session: {}
};
var paywall = {
  button: {
    logout: "Se d\xE9connecter"
  },
  message: {
    end_trial: "Votre essai est termin\xE9."
  }
};
var dashboard = {
  trend: {},
  rent: {},
  indicators: {},
  indices: {
    title: "Indices"
  },
  error: {
    feature: {}
  },
  empty: {}
};
var widget = {
  side_menu: {
    country: "Pays",
    canton: "Canton",
    municipality: "Municipalit\xE9"
  }
};
var status = {};
var option = {
  user: "Utilisateur",
  owner: "Propri\xE9taire",
  triage: "Triage"
};
var boarding = {};
var footer = {};
var potfolios = {
  sections: {
    assets: {
      title: "Biens"
    },
    social: {
      title: "Social"
    },
    environmental: {
      title: "Environnement"
    },
    financials: {
      title: "Finances"
    },
    description: {
      title: "Description"
    }
  },
  tiles: {
    charges: {},
    evolution: {},
    revenue: {}
  },
  widget: {
    charges: {},
    revenue: {}
  }
};
var inputs = {
  placeholder: {
    input_name: "Nom de la saisie",
    preset_name: "Nom des pr\xE9r\xE9glages",
    choose_option: "Choisir une option"
  }
};
var entity_selector = {
  search: "Recherche",
  button: {
    save: "Enregistrer les changements ",
    cancel: "Annuler"
  }
};
var error = {
  email: {
    format: "Votre courriel est incorrect"
  },
  notfound: {
    button: "Retour",
    subtitle: "D\xE9sol\xE9, mais la page que vous avez demand\xE9 n\u2019existe pas",
    title: "Page non trouv\xE9e",
    code: "Erreur 404"
  }
};
var users = {
  role: {
    user: "User"
  },
  roles: {
    triage: "Triage",
    owner: "Propri\xE9taire"
  }
};
var general = {
  app_wall: {
    redirect: {
      message: "Vous n'avez pas acc\xE8s \xE0 l'application actuellement. Nous allons vous rediriger vers les param\xE8tres\u2026"
    }
  }
};
var password = "Nouveau mot de passe";
var traits = {
  contact: {
    office_phone: "Num\xE9ro de t\xE9l\xE9phone professionnel"
  },
  email: "Adresse email",
  name: {
    last: "Nom",
    first: "Pr\xE9nom"
  }
};
var search = {
  title: "R\xE9sultat de recherche",
  nothing_found: "Rien trouv\xE9",
  placeholder: "Adresse, emplacement ou une r\xE9f\xE9rence..."
};
var fr_default = {
  global,
  units,
  funds,
  portfolios,
  portfolio,
  actions,
  candle_stick_chart,
  balance_sheet,
  screener,
  table_header,
  table,
  setting,
  unsaved_screener,
  management,
  companies,
  asset_page,
  income_statement,
  double_range_input,
  overview,
  source_badge,
  common,
  multiple_select,
  single_select,
  screener_bar,
  modal,
  line_chart,
  empty_chart,
  administration,
  treemap,
  action,
  pyramid_chart,
  asset,
  errors,
  buildings,
  items,
  tile,
  gradient,
  badge,
  valuations,
  header,
  input,
  view,
  unit,
  boarding_page,
  policy,
  login,
  watchlist,
  download,
  profile_train,
  impressum,
  notification,
  paywall,
  dashboard,
  widget,
  status,
  option,
  boarding,
  footer,
  potfolios,
  inputs,
  entity_selector,
  error,
  users,
  general,
  password,
  traits,
  search
};

export {
  global,
  units,
  funds,
  portfolios,
  portfolio,
  actions,
  candle_stick_chart,
  balance_sheet,
  screener,
  table_header,
  table,
  setting,
  unsaved_screener,
  management,
  companies,
  asset_page,
  income_statement,
  double_range_input,
  overview,
  source_badge,
  common,
  multiple_select,
  single_select,
  screener_bar,
  modal,
  line_chart,
  empty_chart,
  administration,
  treemap,
  action,
  pyramid_chart,
  asset,
  errors,
  buildings,
  items,
  tile,
  gradient,
  badge,
  valuations,
  header,
  input,
  view,
  unit,
  boarding_page,
  policy,
  login,
  watchlist,
  download,
  profile_train,
  impressum,
  notification,
  paywall,
  dashboard,
  widget,
  status,
  option,
  boarding,
  footer,
  potfolios,
  inputs,
  entity_selector,
  error,
  users,
  general,
  password,
  traits,
  search,
  fr_default
};
