import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Modal } from '@mantine/core';
import { selectEnterprise, unSetEnterprise } from '@/features/keto/ketoSlice';
import { cancelExpiration } from '@/features/kratos/kratosSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';

export default function CustomModal({ backgroundLocation }: { backgroundLocation: string }) {
  const location = useLocation();
  const enterprise = useAppSelector(selectEnterprise);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onClose = useMemo(() => {
    const bgLocation = backgroundLocation;
    const currentLocation = location.pathname;
    return () => {
      if (currentLocation === '/') dispatch(cancelExpiration());
      if (['outScope', 'slotOutScope'].includes(enterprise.state)) dispatch(unSetEnterprise());
      bgLocation && navigate(backgroundLocation);
    };
  }, [backgroundLocation, location, enterprise]);

  const ModalSettings = useMemo(
    () => ({
      zIndex: 500,
      centered: true,
      lockScroll: true,
      children: <Outlet />,
      opened: true,
      onClose,
      className: 'modal-route-container',
    }),
    [onClose]
  );

  return (
    <Modal {...ModalSettings} size="auto">
      <Outlet />
    </Modal>
  );
}
