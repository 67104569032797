import React, { useState } from 'react';
import { modals } from '@mantine/modals';
import { Button, Tabs, TextInput } from '@mantine/core';
import portfolioApi from '@/features/pia/portfolios';
import '@/scss/components/PortfolioModal.css';
import { useFocusTrap } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';

function PortfolioModal({
  onCreateClick,
  onItemClick,

  hasPortfolioList,
}: {
  onCreateClick?: (value: string) => void;
  onItemClick?: (value: string) => void;
  hasPortfolioList?: boolean;
}) {
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const { data: portfolios, isFetching, isLoading } = portfolioApi.useListPortfoliosQuery();

  function handlePortfolioCreate<
    T extends React.ChangeEvent<HTMLFormElement> & React.MouseEvent<HTMLButtonElement>,
  >(e: T) {
    e.preventDefault();
    if (onCreateClick) onCreateClick(value);
    modals.closeAll();
  }

  function handlePortfolioListClick(id: string) {
    if (onItemClick) onItemClick(id);
    modals.closeAll();
  }
  const focusTrapRef = useFocusTrap();

  return (
    <div className="portfolio-modal">
      {isLoading ? (
        <div className="portfolio-modal-fetching">
          <Loading />
        </div>
      ) : (
        <Tabs
          defaultValue={hasPortfolioList && portfolios && portfolios?.length > 0 ? 'add' : 'new'}
        >
          <Tabs.List>
            <Tabs.Tab value="new">{t('actions.add_to_portfolios.modal.new')}</Tabs.Tab>
            {hasPortfolioList && portfolios && portfolios?.length > 0 && (
              <Tabs.Tab value="add">
                {t('actions.add_to_portfolios.modal.add_to_existing')}
              </Tabs.Tab>
            )}
          </Tabs.List>
          <Tabs.Panel value="new">
            <form onSubmit={handlePortfolioCreate}>
              <TextInput
                ref={focusTrapRef}
                value={value}
                onChange={(event) => setValue(event.currentTarget.value)}
                label={t('actions.add_to_portfolios.modal.portfolio_name')}
                placeholder={t('actions.add_to_portfolios.modal.name')}
                data-autofocus
              />
              <Button fullWidth onClick={handlePortfolioCreate} mt="md">
                {t('actions.add_to_portfolios.modal.create_button')}
              </Button>
            </form>{' '}
          </Tabs.Panel>
          {hasPortfolioList && (
            <Tabs.Panel value="add">
              <div className="portfolio-modal-list">
                {!isFetching && portfolios ? (
                  <>
                    {!isFetching &&
                      portfolios?.map((portfolio) => (
                        <div
                          key={portfolio.id}
                          onClick={() => handlePortfolioListClick(portfolio.id)}
                          className="portfolio-modal-list-card"
                        >
                          {portfolio.name}
                        </div>
                      ))}
                  </>
                ) : (
                  <div className="portfolio-modal-list-fetching">
                    <Loading />
                  </div>
                )}
              </div>
            </Tabs.Panel>
          )}
        </Tabs>
      )}
    </div>
  );
}

export default PortfolioModal;
