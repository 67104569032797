import {
  formatFundBuildingGroups,
  formatDwellingsRow,
  formatBuildingsRow,
  formatFundBuildingsRow,
} from '@/features/api/formatRowHelper';
import api from '../piaApi';
import { TableSort } from '@/components/Funds/Detail/AssetList/type';

const assetFundsApi = api.enhanceEndpoints({ addTagTypes: ['Funds'] }).injectEndpoints({
  endpoints: (build) => ({
    getBuildingGroups: build.query({
      query: ({ id, page, sort }: { id: string | number; page?: number; sort?: TableSort }) => ({
        url: `/funds/${id}/asset/building-groups`,
        params: { page, sort: JSON.stringify(sort) },
      }),
      transformResponse: formatFundBuildingGroups,
    }),
    getBuildingsFromGroup: build.query({
      query: ({ id }: { id: number | string }) => ({
        url: `/funds/${id}/asset/building-groups/buildings`,
      }),
      transformResponse: formatFundBuildingsRow,
    }),
    getFundBuildings: build.query({
      query: ({ id, page, sort }: { id: string | number; page?: number; sort?: TableSort }) => ({
        url: `/funds/${id}/asset/buildings`,
        params: { page, sort: JSON.stringify(sort) },
      }),
      transformResponse: formatBuildingsRow,
    }),
    getLotGroups: build.query({
      query: ({ id, page }: { id: number; page?: number }) => ({
        url: `/funds/${id}/asset/lot-groups?page=${page}`,
      }),
    }),
    getLotsFromGroup: build.query({
      query: ({ id }: { id: number }) => ({
        url: `/funds/${id}/asset/lots`,
      }),
    }),
    getFundDwellings: build.query({
      query: ({ id, page, sort }: { id: string | number; page?: number; sort?: TableSort }) => ({
        url: `/funds/${id}/asset/dwellings`,
        params: { page, sort: JSON.stringify(sort) },
      }),
      transformResponse: formatDwellingsRow,
    }),
  }),
});

export default assetFundsApi;
