import downloadApi from '../api/downloads';
import API from '../api/types';
import { store } from '../../app/store';
import handleDownload from '../helpers/download';

export function getConsumeCallback(
  downloadNotif: API.Notification
): ((id?: string) => void) | undefined {
  switch (downloadNotif.label) {
    case 'done':
      return handleDownload;
  }
}

export function getMessage(downloadNotif: API.Notification) {
  switch (downloadNotif.label) {
    case 'aboutToExpire':
      return 'modal.downloads.subtitle.file_about_to_expire';
    case 'done':
      return 'modal.downloads.subtitle.file_ready';
    case 'expired':
      return 'modal.downloads.subtitle.file_expired';
    case 'failed':
      return 'modal.downloads.subtitle.file_failed';
  }
}

export function handle(downloadNotif: API.Notification) {
  switch (downloadNotif.label) {
    default:
      store.dispatch(downloadApi.util.invalidateTags(['Downloads']));
      break;
  }
}
