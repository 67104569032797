import { createApi } from '@reduxjs/toolkit/query/react';
import fetchCustomQuery from '../helpers/reAuth';
import API from './types';
import { EnterpriseDetails } from '@/components/Inputs/types';

const { VITE_API_URI } = import.meta.env;

const apiWrapper = createApi({
  reducerPath: 'api',
  baseQuery: fetchCustomQuery({
    baseUrl: VITE_API_URI,
    credentials: 'include',
  }),
  tagTypes: ['Reports', 'Enterprises', 'Enterprise', 'Rights', 'Slots'],
  endpoints: (build) => ({
    getConfiguration: build.query<Record<string, any>, void>({
      query: () => ({
        url: 'user/configuration',
        headers: {
          accept: 'application/json',
        },
      }),
      transformResponse: (data: { configuration: Record<string, any> }) => {
        return data.configuration;
      },
    }),
    getUsers: build.query<Array<API.User>, void>({
      query: () => ({
        url: 'users',
        headers: {
          accept: 'application/json',
        },
      }),
      transformResponse: (data: { users: Array<API.User> }) => {
        return data.users;
      },
    }),
    getEnterprises: build.query<
      { enterprises: Array<API.Enterprise>; count: number; totalCount: number },
      {
        page: number;
        text?: string;
        sort?: { column: string; order: 'ASC' | 'DESC' };
      }
    >({
      query: ({
        page,
        text,
        sort,
      }: {
        page?: number;
        text?: string;
        sort?: { column: string; order: 'ASC' | 'DESC' };
      }) => ({
        url: 'enterprises',
        headers: {
          accept: 'application/json',
        },
        params: { page, text, sort: JSON.stringify(sort) },
      }),
      providesTags: ['Enterprises'],
    }),
    getEnterprise: build.query<API.Enterprise, string | void>({
      query: (id?: string) => ({
        url: id ? `enterprises/${id}` : '/enterprise',
        headers: {
          accept: 'application/json',
        },
      }),
      providesTags: (_result, _error, id) => {
        return id ? [{ type: 'Enterprises', id }] : ['Enterprise'];
      },
      transformResponse: (data: { enterprise: API.Enterprise }) => {
        return data.enterprise;
      },
    }),
    updateEnterprise: build.mutation<API.Enterprise, EnterpriseDetails & { id?: string }>({
      query: ({ id, ...params }) => ({
        url: id ? `/enterprises/${id}` : `/enterprise`,
        method: 'PATCH',
        body: params,
      }),
      invalidatesTags: (_result, _error, { id }) => {
        return id ? [{ type: 'Enterprises', id }, 'Enterprises'] : ['Enterprise'];
      },
      transformResponse: (data: { enterprise: API.Enterprise }) => {
        return data.enterprise;
      },
    }),
    updateEnterpriseState: build.mutation<void, { id: string; state: API.State }>({
      query: ({ id, state }) => ({
        url: `/enterprises/${id}/state`,
        method: 'POST',
        body: { state },
      }),
      invalidatesTags: (_result, _error, { id }) => {
        return [{ type: 'Enterprises', id }, 'Enterprises'];
      },
    }),
    updateEnterprisePP: build.mutation({
      query: ({ photo, id }: { photo: FormData; id?: string }) => ({
        url: id ? `enterprises/${id}/profile-picture` : 'enterprise/profile-picture',
        body: photo,
        method: 'PUT',
        formData: true,
      }),
      invalidatesTags: (_result, _error, { id }) => {
        return id ? [{ type: 'Enterprises', id }] : ['Enterprise'];
      },
    }),
    deleteEnterprisePP: build.mutation<void, { id?: string } | void | undefined>({
      query: (params?: { id?: string }) => ({
        url: params?.id ? `enterprises/${params.id}/profile-picture` : 'enterprise/profile-picture',
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, params) => {
        return params?.id ? [{ type: 'Enterprises', id: params.id }] : ['Enterprise'];
      },
    }),
    getEnterpriseContactUser: build.query<API.User | undefined, string | void>({
      query: (id?: string) => ({
        url: id ? `enterprises/${id}/contact-user` : '/enterprise/contact-user',
        headers: {
          accept: 'application/json',
        },
      }),
      transformResponse(data: { contact_user: API.User }) {
        return data.contact_user;
      },
      providesTags: (_result, _error, id) => {
        return id
          ? [{ type: 'Enterprises', id, section: 'ContactUser' }]
          : [{ type: 'Enterprise', section: 'ContactUser' }];
      },
    }),
    updateEnterpriseContactUser: build.mutation<
      API.Enterprise,
      { id?: string; userId: string } | void
    >({
      query: ({ id, userId }: { id?: string; userId: string }) => ({
        url: id ? `enterprises/${id}/contact-user` : '/enterprise/contact-user',
        method: 'PUT',
        headers: {
          accept: 'application/json',
        },
        body: { id: userId },
      }),
      invalidatesTags: (_result, _error, params) => {
        return params?.id
          ? [{ type: 'Enterprises', id: params.id, section: 'ContactUser' }, 'Enterprises']
          : [{ type: 'Enterprise', section: 'ContactUser' }];
      },
    }),

    getEnterpriseManagers: build.query<Array<API.User>, string | void>({
      query: (id?: string) => ({
        url: id ? `enterprises/${id}/managers` : 'enterprise/managers',
        headers: {
          accept: 'application/json',
        },
      }),
      transformResponse: (data: { managers: Array<API.User> }) => {
        return data.managers;
      },
    }),
    getEnterpriseMembers: build.query<
      { users: Array<API.User>; totalCount: number; count: number },
      {
        id?: string;
        page?: number;
        filter?: { column: string; value: string };
        sort?: { column: string; value: string };
        text?: string;
      }
    >({
      query: ({
        id,
        page,
        text,
        filter,
        sort,
      }: {
        id?: string;
        page?: number;
        filter?: { column: string; value: string };
        text?: string;
        sort?: { column: string; value: string };
      }) => ({
        url: id ? `enterprises/${id}/members` : 'enterprise/members',
        headers: {
          accept: 'application/json',
        },
        params: { page, text, filter: JSON.stringify(filter), sort: JSON.stringify(sort) },
      }),
      providesTags: (_result, _error, { id }) => {
        return id
          ? [{ type: 'Enterprises', id, section: 'Users' }]
          : [{ type: 'Enterprise', section: 'Users' }];
      },
    }),
    getEnterprisSlots: build.query<Array<API.User>, string | undefined>({
      query: (id: string) => ({
        url: `users/rights/enterprises/${id}/slots`,
        headers: {
          accept: 'application/json',
        },
      }),
      providesTags: (_result, _error, id) => {
        return [{ type: 'Enterprises', id, section: 'Slots' }];
      },
    }),
    createEnterprise: build.mutation<API.Enterprise, any>({
      query: (params) => ({
        url: `enterprises/`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Enterprises'],
      transformResponse: (data: { enterprise: API.Enterprise }) => {
        return data.enterprise;
      },
    }),
    deleteEnterprise: build.mutation({
      query: (id: string) => ({
        url: `enterprises/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => {
        return [{ type: 'Enterprises', id }, 'Enterprises'];
      },
      transformResponse: () => {
        return true;
      },
    }),
    deleteEnterpriseUser: build.mutation<boolean, { id?: string; userId: string }>({
      query: ({ id, userId }: { id?: string; userId: string }) => ({
        url: id ? `enterprises/${id}/members/${userId}` : `enterprise/members/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { id }) => {
        return id
          ? [
              { type: 'Enterprises', id, section: 'Users' },
              { type: 'Enterprises', id, section: 'Slots' },
            ]
          : [
              { type: 'Enterprise', section: 'Users' },
              { type: 'Enterprise', section: 'Slots' },
              'Enterprise',
            ];
      },
      transformResponse: () => {
        return true;
      },
    }),
    deleteUserSelf: build.mutation<boolean, void>({
      query: () => ({
        url: `user/`,
        method: 'DELETE',
      }),
      transformResponse: () => {
        return true;
      },
    }),
    getUserRights: build.query<any, void>({
      query: (id) => ({
        url: `users/${id}/rights`,
        headers: {
          accept: 'application/json',
        },
      }),
    }),
    getRights: build.query<any, void>({
      query: () => ({
        url: `user/rights`,
        headers: {
          accept: 'application/json',
        },
      }),
      transformResponse: (value: any) => {
        return value.rights;
      },
    }),
    getCan: build.query<boolean, Omit<API.CheckParams, 'subject'>>({
      query: ({ object, relation, namespace }) => ({
        url: `user/rights/check?object=${object}&relation=${relation}&namespace=${namespace}`,
        headers: {
          accept: 'application/json',
        },
      }),
      providesTags: ['Rights'],
      transformResponse: (data: { can: boolean }) => {
        return data.can;
      },
    }),
    inviteUser: build.mutation<
      API.User,
      { email: string; first_name?: string; last_name?: string }
    >({
      query: (params) => ({
        url: 'user/enterprise/invite',
        method: 'POST',
        body: params,
      }),
      transformResponse: (data: { user: API.User }) => {
        return data.user;
      },
      invalidatesTags: () => {
        return [
          'Enterprise',
          { type: 'Enterprise', section: 'Users' },
          { type: 'Enterprise', section: 'Slots' },
        ];
      },
    }),
    reinviteEnterpriseUser: build.mutation<void, string>({
      query: (id) => ({
        url: `/enterprise/members/${id}`,
        method: 'POST',
      }),
    }),
    reinviteEnterprisesUser: build.mutation<void, { enterpriseId: string; id: string }>({
      query: ({ enterpriseId, id }) => ({
        url: `/enterprises/${enterpriseId}/members/${id}`,
        method: 'POST',
      }),
    }),
    promoteUser: build.mutation<
      Array<API.Warning> | undefined,
      { enterpriseId?: string; role: string; userIds: Array<string> }
    >({
      query: ({ enterpriseId, role, userIds }) => ({
        url: enterpriseId ? `enterprises/${enterpriseId}/members/role` : 'enterprise/members/role',
        method: 'POST',
        body: { role, user_ids: userIds },
      }),
      invalidatesTags: (_result, _error, { enterpriseId }) => {
        return enterpriseId
          ? [{ type: 'Enterprises', id: enterpriseId, section: 'Users' }, 'Rights']
          : [{ type: 'Enterprise', section: 'Users' }, 'Rights'];
      },
      transformResponse: (data: { warnings?: Array<API.Warning> }) => {
        return data.warnings;
      },
    }),
    unpromoteUser: build.mutation<
      Array<API.Warning> | undefined,
      { enterpriseId?: string; role: string; userIds: Array<string> }
    >({
      query: ({ enterpriseId, role, userIds }) => ({
        url: enterpriseId ? `enterprises/${enterpriseId}/members/role` : 'enterprise/members/role',
        method: 'DELETE',
        body: { role, user_ids: userIds },
      }),
      invalidatesTags: (_result, _error, { enterpriseId }) => {
        return enterpriseId
          ? [{ type: 'Enterprises', id: enterpriseId, section: 'Users' }, 'Rights']
          : [{ type: 'Enterprise', section: 'Users' }, 'Rights'];
      },
      transformResponse: (data: { warnings?: Array<API.Warning> }) => {
        return data.warnings;
      },
    }),
    createEnterpriseUser: build.mutation<
      Array<API.Warning> | undefined,
      {
        emails: string[];
        lang?: 'en' | 'fr' | 'de';
        role?: 'owner' | 'manager';
        slot?: 'consumer' | 'institutional' | 'investor';
        enterprise_id?: string;
      }
    >({
      query: ({ enterprise_id, ...params }) => ({
        url: enterprise_id ? `enterprises/${enterprise_id}/members` : 'enterprise/members',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (_result, _error, { enterprise_id }) => {
        return enterprise_id
          ? [
              { type: 'Enterprises', id: enterprise_id, section: 'Users' },
              { type: 'Enterprises', id: enterprise_id, section: 'Slots' },
            ]
          : [
              { type: 'Enterprise', section: 'Users' },
              { type: 'Enterprise', section: 'Slots' },
            ];
      },

      transformResponse: (data: { user: API.User; warnings?: Array<API.Warning> }) => {
        return data.warnings;
      },
    }),
    getAms: build.query<{ ams: API.User[] }, { enterprise_id: string }>({
      query: ({ enterprise_id }) => ({
        url: `enterprises/${enterprise_id}/ams`,
        headers: {
          accept: 'application/json',
        },
      }),
    }),
  }),
});

export default apiWrapper;

export const {
  endpoints,
  useGetUsersQuery,
  useGetUserRightsQuery,
  useGetRightsQuery,
  useGetCanQuery,
  useDeleteUserSelfMutation,
  useGetEnterpriseQuery,
  useLazyGetEnterpriseQuery,
  useGetEnterprisesQuery,
  useLazyGetEnterprisesQuery,
  useCreateEnterpriseMutation,
  useDeleteEnterpriseMutation,
  useDeleteEnterpriseUserMutation,
  useGetEnterpriseManagersQuery,
  useGetEnterpriseMembersQuery,
  useLazyGetEnterpriseMembersQuery,
  useInviteUserMutation,
  useCreateEnterpriseUserMutation,
  useReinviteEnterprisesUserMutation,
  useUpdateEnterpriseMutation,
  useUpdateEnterprisePPMutation,
  useDeleteEnterprisePPMutation,
  usePromoteUserMutation,
  useUnpromoteUserMutation,
  useLazyGetAmsQuery,
} = apiWrapper;
