import { createSlice } from '@reduxjs/toolkit';
import api from '.';
import { RootState } from '../../app/store';
import API from './types';

export const usersSlice = createSlice({
  name: 'users',
  initialState: { status: 'initialising', users: [] as Array<API.User> },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getUsers.matchFulfilled, (state, action) => {
        state.users = action.payload;
        state.status = 'fulfilled';
      })
      .addMatcher(api.endpoints.getUsers.matchRejected, (state) => {
        state.users = [];
        state.status = 'failed';
      })
      .addMatcher(api.endpoints.getUsers.matchPending, (state) => {
        state.status = 'pending';
      });
  },
});

export const selectUsers = (state: RootState) => state.users.users;

export const usersReducer = usersSlice.reducer;
