import { formatPercentToString } from '@/features/helpers/numbers';
import '@/scss/components/VariationBadge.css';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  value: number;
  suffix: string;
  invertColors?: boolean;
}

export default function VariationBadge(props: IProps) {
  const { t } = useTranslation();

  const { value, suffix, invertColors } = props;

  const color = useMemo(() => {
    if (invertColors) {
      if (value > 0) return 'red';
      if (value < 0) return 'green';
    }
    if (value > 0) return 'green';
    if (value < 0) return 'red';
  }, [invertColors, value]);

  const label = useMemo(() => {
    if (value > 0) return `+${formatPercentToString(value)} ${t(suffix)}`;
    return `${formatPercentToString(value)} ${t(suffix)}`;
  }, [value, suffix, t]);

  return <span className={`variation-badge ${color}`}>{label}</span>;
}
