import Pia from './type';

const indicatorMap = new Map([
  ['SNB_policy_rate', 'dashboard.indicators.snb_policy_rate'],
  ['IPC_LIK', 'dashboard.indicators.ipc_lik'],
  ['switzerland_vacancy_rate', 'dashboard.indicators.switzerland_vacancy_rate'],
  ['reference_interest_rate', 'dashboard.indicators.reference_interest_rate'],

  ['swiss_residential_property_price', 'dashboard.indices.swiss_residential_property_price'],
  ['construction_price_index_building', 'dashboard.indices.construction_price_index_building'],
  [
    'construction_price_index_civil_engineering',
    'dashboard.indices.construction_price_index_civil_engineering',
  ],
  ['construction_price_index_total', 'dashboard.indices.construction_price_index_total'],
]);

export function formatIndicators(data: Array<Pia.Indicator>) {
  const indicators = data.map((indicator) => ({
    label: (indicatorMap.get(indicator.name) || indicator.name) as string,
    value: indicator.unit === '%' ? indicator.value * 100 : indicator.value,
    suffix: indicator.unit,
  }));
  const indicatorsKeys = {
    'dashboard.indicators.snb_policy_rate': 0,
    'dashboard.indicators.ipc_lik': 1,
    'dashboard.indicators.switzerland_vacancy_rate': 2,
    'dashboard.indicators.reference_interest_rate': 3,
  };
  const indicesKeys = {
    'dashboard.indices.swiss_residential_property_price': 0,
    'dashboard.indices.construction_price_index_building': 1,
    'dashboard.indices.construction_price_index_civil_engineering': 2,
    'dashboard.indices.construction_price_index_total': 3,
  };
  return {
    indicators: indicators
      .filter((indicator) => Object.keys(indicatorsKeys).indexOf(indicator.label) > -1)
      .sort(
        (item1, item2) =>
          indicatorsKeys[item1.label as keyof typeof indicatorsKeys] -
          indicatorsKeys[item2.label as keyof typeof indicatorsKeys]
      ),
    indices: indicators
      .filter((indicator) => Object.keys(indicesKeys).indexOf(indicator.label) > -1)
      .sort(
        (item1, item2) =>
          indicesKeys[item1.label as keyof typeof indicesKeys] -
          indicesKeys[item2.label as keyof typeof indicesKeys]
      ),
  };
}

export function formatCantonRent(data: Array<Pia.CantonRent>) {
  return data.map(({ name, code, rent_per_m2 }) => ({
    category: name,
    categoryAbbr: code,
    value: rent_per_m2,
  }));
}

export function formatTrend(data: Array<Pia.TrendPoint>) {
  const formated = data.map(({ date, value }) => {
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    return { group: 'group1', value, year, month, day };
  });
  return formated;
}
