export function formatPercentToStringWithUnits(value: number | null): string {
  if (value === null) return '-';
  return Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  }).format(value);
}

export function formatPercentToString(value: number | null): string {
  if (typeof value != 'number') return '-';
  const fixed = value * 100;
  return Intl.NumberFormat('de-CH', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(fixed);
}

export function formatPercentToNumber(value: number | null) {
  if (value === null) return null;
  const fixed = (value * 100).toFixed(2);
  return fixed;
}

export function formatInt(value: number | null, digits?: { minimum?: number; maximum?: number }) {
  if (typeof value === 'object') return null;
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: digits?.minimum || 0,
    maximumFractionDigits: digits?.maximum || 0,
  }).format(value);
}

export function formatCHF(value: number | null, digits?: { minimum?: number; maximum?: number }) {
  if (typeof value === 'object') return null;
  return Intl.NumberFormat('de-CH', {
    minimumFractionDigits: digits?.minimum || 0,
    maximumFractionDigits: digits?.maximum || 0,
  })
    .format(value)
    .concat(' CHF');
}

export function formatScore(value: number | null) {
  if (value === null) return '-';
  return (
    Intl.NumberFormat('de-CH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value) + '/10'
  );
}

export function formatFullInt<T extends number | null | undefined>(
  value: T,
  digits?: { minimum?: number; maximum?: number }
): T extends number | null ? string : (value: number | null) => string {
  if (value === undefined) {
    return ((value: number | null) => {
      return formatFullInt(value, { minimum: digits?.minimum, maximum: digits?.maximum });
    }) as T extends number | null ? string : (value: number | null) => string;
  }
  if (value === null)
    return '-' as T extends number | null ? string : (value: number | null) => string;
  return Intl.NumberFormat('de-CH', {
    minimumFractionDigits: digits?.minimum ?? 0,
    maximumFractionDigits: digits?.maximum ?? 0,
  }).format(value) as T extends number | null ? string : (value: number | null) => string;
}

export function formatFloat(value: number) {
  return Intl.NumberFormat('de-CH', {
    maximumFractionDigits: 4,
  }).format(value);
}

export function formatFullFloat<T extends number | null | undefined>(
  value: T
): T extends number | null ? string : (value: number | null) => string {
  return formatFullInt(value, { minimum: 2, maximum: 2 });
}

export function formatDate(date: Date | string | number | null, withHour?: boolean): string {
  if (!date) return '';
  const d = new Date(date);
  const year = d.getFullYear().toString();
  // Add 0 if month or day is less than 10
  const month = ('0' + (d.getMonth() + 1)).slice(-2);
  const day = ('0' + d.getDate()).slice(-2);
  const local = [day, month, year].join('/');
  if (withHour) {
    const hour = d.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${local} - ${hour}`;
  }
  return local;
}

export function formatDateWithoutYear(date: Date | string | number | null): string {
  if (!date) return '';
  const d = new Date(date);
  if (!isNaN(d.getDate())) {
    // Add 0 if month or day is less than 10
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    const local = [day, month].join('/');
    return local;
  } else {
    // date string formate support mm/dd mm-dd
    const parsedDate = typeof date === 'string' ? date.split(/[-/]/g) : [];
    return parsedDate.reverse().join('/');
  }
}

export function getYear(date: Date | string | number | null): string | null {
  if (!date) return '';
  const d = new Date(date);
  const year = d.getFullYear();
  return !isNaN(year) ? year.toString() : null;
}

export function isNumber(nb: unknown): nb is number {
  return typeof nb === 'number' && !Number.isNaN(nb);
}

export function verifyNumber(nb: unknown) {
  if (!nb) return;
  if (!isNumber(nb)) {
    const converted = Number(nb);
    if (!isNumber(converted)) {
      return;
    }
    return converted;
  }
  return nb;
}

export function verifyString(str: unknown) {
  if (!str) return;
  if (typeof str !== 'string') {
    return str.toString();
  }
  return str;
}

// @sigFigs: number of significant figures
export function roundNumber(value: number | string | null | undefined, sigFigs: number) {
  if (value === null || value === undefined) return null;
  return Number(Number(value).toFixed(sigFigs));
}
