import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MantineProvider } from '@mantine/core';
import './i18n';
import React from 'react';
import '@mantine/core/styles.css';
import * as Sentry from '@sentry/react';

const { VITE_SENTRY_DSN, VITE_USER_NODE_ENV } = import.meta.env;

Sentry.init({
  dsn: VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  environment: VITE_USER_NODE_ENV || 'development',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  enabled: VITE_USER_NODE_ENV === 'production' || VITE_USER_NODE_ENV === 'staging',
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (import.meta.env.VITE_USER_NODE_ENV === 'development') {
  import('@spotlightjs/spotlight').then((Spotlight) => Spotlight.init({ injectImmediately: true }));
}

const container = document.getElementById('root')!;

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <MantineProvider
      theme={{
        colors: {
          brand: [
            '#1f51ff',
            '#1f51ff',
            '#1f51ff',
            '#1f51ff',
            '#1f51ff',
            '#F71FA7',
            '#1f51ff',
            '#1f51ff',
            '#1f51ff',
            '#1f51ff',
          ],
        },
        primaryColor: 'brand',
        cursorType: 'pointer',
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
