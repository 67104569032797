import React, { useEffect, useId, useMemo, useState } from 'react';
import '@/scss/components/ObjectList.css';
import { Accordion, ActionIcon, Skeleton, Tooltip } from '@mantine/core';
import { CollapsibleObj, ObjectListItemType, ObjectListType } from './type';
import { Checkmark, Close, Edit } from '@carbon/icons-react';
import { Link, useLocation } from 'react-router-dom';

import { useFocusTrap } from '@mantine/hooks';
import portfolioApi from '@/features/pia/portfolios';
import { UseParseError } from '@/features/helpers/fiiErrorParser';
import UseNotifications from '@/features/helpers/useNotifications';
import TrendItem from '../Widget/TrendItem';
import DropdownCol from './DropdownCol';
import ObjectLegend from './ObjectLegend';
import { useTranslation } from 'react-i18next';
import AssetSpecialStatus from './AssetSpecialStatus';
import MarketValueBadge from './marketValueBadge';
import { formatDate } from '@/features/helpers/numbers';
import EllipsisText from '../EllipsisText';

export default function ObjectListItem(
  props: Omit<ObjectListType, 'objectItems'> & { item: ObjectListItemType }
) {
  const {
    type,
    icon,
    id,
    title,
    subtitle,
    reportDate,
    isAnnual,
    legends,
    description,
    isTitleEditable,
    status,
    marketValue,
  } = props.item;
  const location = useLocation();
  const focusTrapRef = useFocusTrap();
  const [setNewName, { isSuccess: isNameChangeDone, error: nameChangeError }] =
    portfolioApi.useUpdatePortfolioMutation();
  const actions = props.actions;
  const [collapsible, setCollapsible] = useState<CollapsibleObj>([]);
  const [editOn, setEditOn] = useState(false);
  const value = useId();
  const [inputTitle, setInputTitle] = useState(title);
  const path = useMemo(
    () => `/${['funds', 'portfolios'].includes(type) ? '' : 'asset/'}${type}/${id}`,
    [type, id]
  );
  const notification = UseNotifications();

  useEffect(() => {
    if (title) setInputTitle(title);
  }, [title]);

  function handleNameChange<
    T extends React.ChangeEvent<HTMLFormElement> & React.MouseEvent<HTMLButtonElement>,
  >(event: T) {
    event.preventDefault();
    setNewName({ id, title: inputTitle, buildings: [], dwellings: [] });
    setEditOn(false);
  }
  const { t } = useTranslation();
  const { message: nameErrorMessage } = UseParseError(nameChangeError);

  useEffect(() => {
    if (nameErrorMessage && !isNameChangeDone) {
      notification.show({
        title: t('common.error'),
        message: nameErrorMessage,
        color: 'yellow',
      });
      setInputTitle(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameErrorMessage]);

  useEffect(() => {
    if (isNameChangeDone) {
      notification.show({
        title: t('action.portfolios.name.change_success.title'),
        message: t('action.portfolios.name.change_success.message'),
        color: 'green',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNameChangeDone]);

  const titleLink = useMemo(() => {
    if (location.pathname === path) {
      return (
        <div className="title object-list-item-upper-section">
          <span className="title-main">
            {isTitleEditable ? (
              <>
                {editOn ? (
                  <>
                    <form onSubmit={handleNameChange}>
                      <input
                        ref={focusTrapRef}
                        className="title-main-edit"
                        value={inputTitle}
                        onChange={(e) => setInputTitle(e.target.value)}
                      />

                      <ActionIcon onClick={handleNameChange} component="button" formAction="submit">
                        <Checkmark />
                      </ActionIcon>
                      <ActionIcon
                        onClick={() => {
                          setEditOn(false);
                          setInputTitle(title);
                        }}
                      >
                        <Close />
                      </ActionIcon>
                    </form>
                  </>
                ) : (
                  <>
                    <span>{inputTitle}</span>
                    <ActionIcon onClick={() => setEditOn(true)}>
                      <Edit />
                    </ActionIcon>
                  </>
                )}
              </>
            ) : (
              <span>{inputTitle}</span>
            )}
          </span>
        </div>
      );
    } else {
      return (
        <Link to={path} className="title object-list-item-upper-section">
          <span className="title-main">
            <EllipsisText label={title} noTranslation={true} />
          </span>
        </Link>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, path, title, subtitle, editOn, inputTitle]);

  const isOneRow = useMemo(() => {
    return description === undefined || description.length === 0;
  }, [description]);

  return (
    <>
      <Accordion.Item value={`${value}`} className="object-list-item">
        <div className="object-list-item-left object-list-item-icon">{icon}</div>
        <div className="object-list-item-right">
          <div className={`object-list-item-upper${isOneRow ? '-empty' : ''}`}>
            <div className="asset-information">
              {titleLink}
              {subtitle && (
                <span className="title-sub object-list-item-upper-section">{subtitle}</span>
              )}
              {status && <AssetSpecialStatus {...status} />}
              {marketValue?.value && (
                <div className="object-list-item-upper-section">
                  <MarketValueBadge {...marketValue} />
                </div>
              )}
              {reportDate && (
                <span className="date-badge object-list-item-upper-section">
                  <EllipsisText
                    label={
                      isAnnual
                        ? 'funds.composition.annual_report_date'
                        : 'funds.composition.semi_annual_report_date'
                    }
                  />
                  <p className="data">{formatDate(reportDate)}</p>
                </span>
              )}
            </div>
            <div className="object-list-item-upper-section-empty"></div>
            <div className="legend-and-buttons">
              {legends && legends.length > 0 && (
                <span className="legends object-list-item-upper-section">
                  {legends.map((legend, index) => (
                    <ObjectLegend legend={legend} key={index} />
                  ))}
                </span>
              )}
              {actions && actions.length > 0 && (
                <span className="actions">
                  {actions.map((item) => (
                    <Tooltip
                      withinPortal
                      label={item.label}
                      key={item.label}
                      disabled={!item.enabled}
                    >
                      <ActionIcon
                        onClick={() => item.function?.({ type, id })}
                        variant="transparent"
                        size="xs"
                        disabled={!item.enabled}
                      >
                        {item.icon}
                      </ActionIcon>
                    </Tooltip>
                  ))}
                </span>
              )}
              {collapsible &&
                props.openableChildren &&
                legends?.some(
                  (legend) =>
                    (legend.type === 'buildings_nb' || legend.type === 'dwellings_nb') &&
                    legend.value > 0
                ) && (
                  <Accordion.Control
                    onClick={async () => {
                      if (props.onOpen) {
                        const collapsible = await props.onOpen();
                        setCollapsible(collapsible);
                      }
                    }}
                    className="collapsible-control"
                  />
                )}
            </div>
          </div>
          <Accordion.Panel className="object-list-item-collapsible">
            {collapsible.length > 0 && props.openableChildren ? (
              <div key={collapsible.length} className="assets-dropdown">
                {collapsible.map((col, index) => (
                  <React.Fragment key={index}>
                    {col.contents.length > 0 && <DropdownCol collapsibleCol={col} />}
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <Skeleton height={178} />
            )}
          </Accordion.Panel>
          {!isOneRow && (
            <div className="object-list-item-lower">
              <div className="lower-flex">
                {description &&
                  description.length > 0 &&
                  description.map((item, index) => (
                    <span key={`trend-${index}`} className="description-col">
                      <TrendItem item={item} />
                    </span>
                  ))}
              </div>
            </div>
          )}
        </div>
      </Accordion.Item>
    </>
  );
}
