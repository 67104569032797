import API from '../api/types';
import { isNotificationAdminType } from './helper';

export function getMessage(adminNotif: API.Notification) {
  if (isNotificationAdminType(adminNotif)) {
    if (['subscribed', 'trial'].includes(adminNotif.value.state))
      return {
        key: 'modal.notifications.administration_days_left',
        param: {
          enterprise: adminNotif.value.name,
          currentState: adminNotif.value.state,
          daysLeft: adminNotif.value.days_left,
        },
      };
    if (adminNotif.value.state === 'examination')
      return {
        key: 'modal.notifications.administration_examination',
        param: {
          enterprise: adminNotif.value.name,
        },
      };
    if (adminNotif.value.state === 'linked')
      return {
        key: 'modal.notifications.administration_linked',
        param: {
          enterprise: adminNotif.value.name,
          currentState: adminNotif.value.state,
          daysLeft: adminNotif.value.days_left,
        },
      };
    if (adminNotif.value.state === 'unlinked')
      return {
        key: 'modal.notifications.administration_unlinked',
        param: {
          enterprise: adminNotif.value.name,
        },
      };
  }
  return;
}

export function handle(adminNotif: API.Notification) {
  switch (adminNotif.label) {
    default:
      break;
  }
}
