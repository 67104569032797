import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';
import API from '../types';
import kratosApi from '@/features/kratos';

interface InitialState {
  valuation: API.ValuationPreferences;
}

const initialState: InitialState = { valuation: { dwellings: {}, buildings: {} } };

export const valuationPreferencesSlice = createSlice({
  name: 'valuation_preferences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(kratosApi.endpoints.getValuationPreferences.matchFulfilled, (state, action) => {
        state.valuation = action.payload;
      })
      .addMatcher(kratosApi.endpoints.updateValuationPreferences.matchPending, (state, action) => {
        const type = action.meta.arg.originalArgs.type;
        const payload = action.meta.arg.originalArgs.preferences;
        state.valuation[type] = payload;
      });
  },
});

export const selectValuationPreference = (state: RootState) =>
  state.valuation_preferences.valuation;

export const valuationPreferencesReducer = valuationPreferencesSlice.reducer;
