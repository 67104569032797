// src/frontend/de.json
var global = {
  chart: {
    legend: {},
    tooltip: {}
  },
  assets: {}
};
var units = {
  transport_accessibility: "pts",
  road_accessibility: "pts",
  green_density: "%",
  public_utility_density: "%",
  stock_volume: "Units",
  six_months_avg_volume: "Units",
  five_days_avg_volume: "Units",
  one_year_avg_volume: "Units",
  three_months_avg_volume: "Units",
  one_month_avg_volume: "Units",
  five_years_return: "%",
  one_year_return: "%",
  fifty_two_weeks_low: "CHF",
  six_months_return: "%",
  three_years_return: "%",
  unit_outstanding: "Units",
  turnover: "CHF",
  qtd_net_return: "%",
  mtd_net_return: "%",
  fifty_two_weeks_high: "CHF",
  r_cost_price_q3: "CHF/m\xB2",
  ytd_net_return: "%",
  three_months_return: "%",
  r_cost_price_median: "CHF/m\xB2",
  one_month_return: "%",
  r_total_cost_price: "CHF",
  c_market_value_q1: "CHF/m\xB2",
  r_cost_price_q1: "CHF/m\xB2",
  c_market_value_q3: "CHF/m\xB2",
  c_total_market_value: "CHF",
  r_residential_cost_price: "CHF",
  r_commercial_cost_price: "CHF",
  r_mixed_cost_price: "CHF",
  c_residential_market_value: "CHF",
  c_market_value_median: "CHF/m\xB2",
  c_mixed_market_value: "CHF",
  r_market_value_q1: "CHF/m\xB2",
  c_commercial_market_value: "CHF",
  r_market_value_q3: "CHF/m\xB2",
  r_mixed_market_value: "CHF",
  r_commercial_market_value: "CHF",
  r_total_market_value: "CHF",
  r_net_rent_q1: "CHF/m\xB2",
  r_net_rent_median: "CHF/m\xB2",
  r_net_rent_q3: "CHF/m\xB2",
  r_residential_market_value: "CHF",
  r_max_custodian_bank_fees: "%",
  r_max_construction_and_works_fees: "%",
  r_residential_net_rent: "CHF",
  r_max_share_buyback_commissions: "%",
  r_max_share_issue_commissions: "%",
  r_custodian_bank_fees: "%",
  r_ter_mv: "%",
  r_property_management_fees: "%",
  r_ter_nav: "%",
  r_share_issue_commissions: "%",
  r_purchase_and_sale_fees: "%",
  r_share_buyback_commissions: "%",
  r_max_management_fees: "%",
  r_ter_gav: "%",
  c_earnings_per_share: "CHF",
  r_estimated_liquidation_taxes: "CHF",
  c_operating_profit: "CHF",
  r_management_fees: "%",
  c_total_cash_per_share: "CHF",
  c_loan_to_value: "%",
  c_debt_ratio: "%",
  c_current_ratio: "%",
  c_weighted_average_maturity: "%",
  c_current_liabilities: "CHF",
  c_weighted_average_cost_of_debt: "%",
  c_payout_ratio: "%",
  c_roe: "%",
  c_price_to_book_value: "%",
  c_pe_ratio: "%",
  c_agio_at_year_end: "%",
  c_net_initial_yield: "%",
  c_roa: "%",
  c_roce: "%",
  r_total_dividend: "CHF",
  m_dividend_yield: "%",
  r_dividend_yield: "%",
  r_payout_ratio: "%",
  r_dividend_from_indirect_rental_income: "CHF",
  r_rental_losses: "%",
  r_performance: "%",
  r_roic: "%",
  r_roi: "%",
  r_debt_ratio: "%",
  r_roe: "%",
  r_ebit_margin: "%",
  c_mixed_net_rent: "CHF",
  c_resiential_net_rent: "CHF",
  c_total_net_rent: "CHF",
  c_net_rent_q3: "CHF/m\xB2",
  c_commercial_net_rent: "CHF",
  r_resiential_net_rent: "CHF",
  r_market_value_median: "CHF/m\xB2",
  c_net_rent_q1: "CHF/m\xB2",
  c_net_rent_median: "CHF/m\xB2",
  r_mixed_net_rent: "CHF",
  c_total_agio_at_year_end: "CHF",
  r_total_net_rent: "CHF",
  r_commercial_net_rent: "CHF",
  r_tna_after_distribution: "CHF",
  c_tga_compounded: "CHF",
  r_tna_before_distribution: "CHF",
  r_tga_after_distribution: "CHF",
  c_tna_compounded: "CHF",
  r_tga_before_distribution: "CHF",
  c_total_current_agio_compounded: "CHF",
  r_market_cap: "CHF",
  r_nav_before_distribution: "CHF",
  c_total_current_agio_after_distribution: "CHF",
  r_agio_at_year_end: "%",
  r_share_price: "CHF",
  c_current_agio_compounded: "%",
  c_nav_after_distribution: "CHF",
  r_gav_before_distribution: "CHF",
  c_gav_after_distribution: "CHF",
  c_nav_compounded: "CHF",
  c_current_agio_after_distribution: "%",
  m_share_price: "CHF",
  c_five_years_total_return: "%",
  c_three_years_total_return: "%",
  hist_volatility_30_days: "%",
  implied_volatility: "%",
  hist_volatility_90_days: "%",
  potential_net_rent_per_m2: "CHF",
  hist_volatility_250_days: "%",
  hist_volatility_180_days: "%",
  potential_charges_per_m2: "CHF",
  tx_price: "CHF",
  potential_roa: "%",
  estimated_area: "m\xB2",
  solar_existing: "kWh",
  duplex: "m\xB2",
  real_market_value: "CHF",
  potential_charges: "CHF",
  potential_market_value_per_m2: "CHF",
  real_roa: "CHF",
  potential_market_value: "CHF",
  r_capital_employed: "CHF",
  ground_area: "m\xB2",
  potential_total_income: "CHF",
  r_total_area: "m\xB2",
  r_residential_area: "m\xB2",
  commercial_spaces_area: "m\xB2",
  one_year_total_return: "%",
  m_share_price_var: "%",
  r_commercial_spaces_area: "m\xB2",
  open: "CHF",
  three_years_total_return: "%",
  five_years_total_return: "%",
  last: "CHF",
  m_market_cap: "CHF",
  low: "CHF",
  high: "CHF",
  r_wault: "yr",
  c_gav_compounded: "CHF",
  r_roce: "%",
  m_dividend: "CHF",
  r_other_revenue: "CHF",
  c_revenue_per_share: "CHF",
  r_other_short_term_liabilities: "CHF",
  r_roa: "%",
  r_other_interest: "CHF",
  r_other_expenses: "CHF",
  r_ordinary_income_carried_forward_from_previous_financial_year: "CHF",
  r_net_income: "CHF",
  r_other_long_term_liabilities: "CHF",
  r_net_assets_at_end_of_the_financial_year: "CHF",
  r_other_assets: "CHF",
  r_nopat: "CHF",
  r_net_income_available_for_distribution: "CHF",
  r_net_assets_at_beginning_of_the_financial_year: "CHF",
  r_net_assets_before_estimated_liquidation_taxes: "CHF",
  r_negative_interest: "CHF",
  r_long_term_liabilities: "CHF",
  r_ebit: "CHF",
  r_invested_capital: "CHF",
  r_long_term_interest_bearing_mortgages: "CHF",
  r_dividend_distribution: "CHF",
  r_mortgage_interest: "CHF",
  r_dividend_from_direct_rental_income: "CHF",
  c_median_maturity: "yr",
  c_weighted_average_interest_rate: "%",
  r_dividend_from_capital_gains: "CHF",
  c_ebit_margin: "%",
  r_max_purchase_and_sale_fees: "%",
  r_auditing_expenses: "CHF",
  r_max_property_management_fees: "%",
  r_valuation_expenses: "CHF",
  r_administration_of_buildings: "CHF",
  r_market_maker_fees: "CHF",
  r_total_net_assets: "CHF",
  r_absolute_custodian_bank_fees: "CHF",
  r_absolute_management_fees: "CHF",
  r_valuation_and_auditing_expenses: "CHF",
  r_unrealised_capital_gains_and_losses: "CHF",
  r_total_revenue: "CHF",
  r_total_properties: "CHF",
  r_total_liabilities: "CHF",
  r_total_interest: "CHF",
  r_total_expenses: "CHF",
  r_taxes: "CHF",
  r_total_cash: "CHF",
  r_total_income: "CHF",
  r_short_term_liabilities: "CHF",
  r_retained_earnings: "CHF",
  r_short_term_interest_bearing_mortgages: "CHF",
  r_rental_income: "CHF",
  r_realised_income: "CHF",
  r_revenue_from_postal_and_bank_accounts: "CHF",
  r_property_management_expenses: "CHF",
  r_property_tax: "CHF",
  r_realised_capital_gains_and_losses: "CHF",
  r_profit_and_capital_taxes: "CHF",
  r_maintenance_and_repairs_expenses: "CHF",
  r_maintenance_costs: "CHF",
  r_capital_distribution: "CHF",
  r_building_expenses: "CHF",
  r_construction_and_works_fees: "%",
  rental_losses: "%",
  energy: {},
  net_initial_yield: "%",
  gross_rent: "CHF",
  description: {
    distribution: "%"
  },
  rate: "%",
  market_value_per_m2: "CHF/m\xB2",
  portfolios: {
    hail_hazard_10_years_average: "%",
    accessibility: "m",
    overbank_sedimentation: "%",
    avalanches: "%",
    debris_flow: "%",
    rockfalls: "%",
    floods_50_years: "%",
    distribution: "%",
    landslides: "%"
  },
  roic: "%",
  roi: "%",
  roe: "%",
  funds: {
    transactions: {},
    overbank_sedimentation: "%",
    hail_hazard_10_years: "%",
    sia261: "%",
    rockfalls: "%",
    debris_flow: "%",
    landslides: "%",
    avalanches: "%",
    floods_50_years: "%"
  },
  market_price_var: "%",
  market_price: "CHF",
  five_years_avg_dividend_yield: "%",
  return_on_asset: "%",
  total_expense_ratio_ref_nav: "%",
  return_on_capital_employed: "%",
  dividend_yield: "%",
  dividend: "CHF",
  potential_net_rent: "CHF",
  built_up: {},
  real_charges: "CHF",
  demographic: {
    ages: "%"
  },
  real_net_rent: "CHF",
  yearly_management_fee: "%",
  ebit_margin: "%",
  rent_default_rate: "%",
  total_expense_ratio_ref_mv: "%",
  total_expense_ratio_ref_gav: "%",
  debt_financing_ratio: "%",
  return_on_equity: "%",
  return_on_invested_capital: "%",
  distribution_yield: "%",
  return_on_investment: "%",
  relative_agio: "%",
  distribution_value: "CHF",
  market_capitalization: "CHF",
  payout_ratio: "%",
  total_gross_assets: "CHF",
  share_class_nav: "CHF",
  share_class_gav: "CHF",
  swiss_net_rent: "CHF",
  canton_rent: "CHF",
  share_market_price: "CHF",
  press_score: "/10",
  hail_hazard_10_years_average: "cm"
};
var funds = {
  environmental: {
    evolution: {
      chart: {}
    },
    idc: {
      idc_by_m2: {}
    },
    co2: {
      co2_scope_1_2_by_m2: {}
    },
    climate: {
      risk: {}
    },
    tile: {},
    energy: {},
    risk: {}
  },
  social: {
    transport_accessibility: {
      transport_accessibility: {},
      road_accessibility: {}
    },
    well_being: {
      green_density: {},
      public_utility_density: {}
    },
    density: {
      job_density: {},
      population_density: {}
    },
    accessibility: {},
    tile: {},
    promiscuity: {
      tile: {}
    },
    demographic: {
      tile: {}
    }
  },
  financials: {
    evolution: {
      chart: {}
    },
    metrics: {
      balance_sheet: {},
      income_statement: {},
      liquidity: {},
      quantitative_valuation_ratios: {},
      dividends: {},
      ratios: {}
    },
    side_menu: {},
    report: {},
    stock: {
      evolution: {},
      risk: {},
      trade: {},
      history: {}
    },
    stock_performance: {},
    portfolio: {
      c_market_value_by_m2: {},
      c_market_value: {},
      r_market_value_by_m2: {},
      r_market_value: {},
      r_cost_price_by_m2: {},
      r_cost_price: {}
    },
    fees: {
      maximum: {},
      effective: {}
    },
    rental_income: {
      estimated_by_m2: {},
      reported_by_m2: {},
      estimated: {},
      reported: {}
    },
    performance: {
      current_total_value: {},
      year_end_total_value: {},
      year_end_value_per_share: {},
      current_value_per_share: {}
    },
    debts: {},
    tile: {},
    trading: {},
    highlights: {},
    revenue: {},
    charges: {}
  },
  governance: {
    tile: {},
    certificates_rating: {},
    organisation: {}
  },
  composition: {},
  environment: {
    energy_distribution: {}
  },
  description: {
    distribution: {},
    tile: {},
    composition: {},
    identification: {}
  },
  overview: {},
  transactions: {
    value: {}
  },
  page: {},
  performance: {
    r2: "R"
  },
  noright: {},
  financials_per_share: {},
  designation: {},
  stock: {},
  administrative: {},
  assets: {}
};
var portfolios = {
  environmental: {
    evolution: {
      chart: {}
    }
  },
  financials: {
    evolution: {
      chart: {}
    },
    performance: {
      evolution: {}
    }
  },
  environment: {
    energy_distribution: {}
  },
  description: {
    distribution: {}
  },
  noright: {},
  overview: {
    type: {}
  },
  modal: {
    confirm_delete: {
      notification: {}
    }
  },
  create_modal: {},
  notification: {
    success_create: {
      message: "Das Portfolio wurde erfolgreich erstellt",
      title: "Erfolgreich erstellt"
    }
  },
  widget: {
    energy: {},
    risk: {},
    identification: {}
  },
  tiles: {
    solar: {},
    energy_source: {},
    risk_index: {},
    risks: {},
    composition: {},
    designation: {},
    distribution: {},
    idc_and_co2: {}
  },
  designation: {
    created_at: "Erstellungsdatum",
    updated_at: "Letzte Aktualisierung"
  }
};
var portfolio = {
  environmental: {
    evolution: {}
  }
};
var actions = {
  screener: {
    per_page: {},
    preset: {},
    filter: {}
  },
  add_to_watchlist: {},
  update: {
    processing: {}
  },
  watchlist: {
    remove: {
      button: {},
      success: {}
    },
    add: {
      button: {},
      success: {}
    }
  },
  companies: {},
  portfolios: {
    remove: {
      success: {}
    }
  },
  request_download: {
    message: "Anfrage erstellt"
  },
  add_to_portfolios: {
    adding_portfolio: {},
    success_add_portfolio: {},
    success_create_portfolio: {
      message: "Ein neues Portfolio wurde erstellt"
    },
    confrm_modal: {
      buttons: {}
    },
    confirm_modal: {},
    modal: {}
  }
};
var candle_stick_chart = {
  unit: {},
  label: {}
};
var balance_sheet = {
  label: {
    total_liabilities: "Gesamtverbindlichkeiten"
  },
  unit: {},
  legend: {
    group: {}
  }
};
var screener = {
  filters: {
    labels: {},
    multi_select: {
      options: {}
    },
    groups: {},
    panel: {}
  },
  tabs: {
    saved_screens: "Gespeicherte Bildschirme"
  }
};
var table_header = {
  environment: {},
  created_at: "Erstellungsdatum",
  net_initial_yield: "Nettoanfangsrendite",
  rental_losses: "Verluste bei Vermietung"
};
var table = {
  fund_type: {},
  fund_legal_form: {}
};
var setting = {
  preference: {
    notification: {
      unsaved_screen: {}
    },
    unsaved_screen_notification: {},
    language: {}
  },
  profile: {
    email_preferences: {
      technical: {},
      marketing: {}
    },
    notification: {},
    picture: {},
    email_verification_note: {},
    confirm_delete_pp: {}
  },
  connections: {
    modal: {
      warning: {},
      delete: {},
      create: {
        subtitle: "Nachdem dieser Schl\xFCssel erstellt wurde, wird er niemals wieder sichtbar sein.\nWenn er kompromittiert wird, muss der Schl\xFCssel gel\xF6scht und ein neuer erstellt werden."
      },
      name: {},
      expiration: {}
    },
    options: {},
    days: {},
    name: {},
    table_name: {},
    tokens_table: {
      col_name: {
        created_at: "Erstellungsdatum"
      }
    }
  },
  seats: {
    payment_warning: {
      msg: {}
    },
    member_table: {
      col_name: {}
    },
    no_slot_warning: {},
    delete_user: {
      multiple: {}
    },
    am_table: {
      col_name: {}
    },
    plan_card: {},
    institutional: {},
    investor: {},
    consumer: {}
  },
  security: {
    link_device: {},
    warning_note: {},
    renegerate_code: {},
    password: {}
  },
  company: {
    set_contact_user: {}
  },
  organization: {
    details: {},
    notification: {},
    logo: {}
  },
  collaborator: {
    modal: {
      link_companies: {}
    },
    col: {}
  },
  companies: {}
};
var unsaved_screener = {
  tooltip: {},
  warning: {},
  modal: {},
  button: {}
};
var management = {
  nav: {
    preferences: {},
    connections: {},
    edit_company: {},
    create_company: {},
    company: {},
    requests: {},
    contracts: {},
    users: {},
    teams: {},
    companies: {},
    profile: {},
    security: {},
    languages: {},
    language: {},
    organization: {},
    seats: {},
    collaborators: {}
  },
  email: {
    addresses: {}
  },
  category: {
    communication: {},
    administration: {},
    customers: {},
    personal: {},
    company: {},
    team: {}
  },
  header: {}
};
var companies = {
  button: {},
  modal: {
    confirm_delete: {}
  },
  notifications: {
    success_create_users: "Benutzer erfolgreich erstellt"
  }
};
var asset_page = {
  financials: {
    units: {},
    ratio_metric: {
      commercial_spaces: {},
      dwellings: {},
      charges: {},
      rent: {},
      price: {}
    },
    unit: {},
    charges: {},
    total: {},
    commercial_spaces: {},
    dwellings: {},
    performance: {},
    metrics: {},
    tile: {},
    revenue: {},
    comparables: {}
  },
  description: {
    tile: {
      buildings: {},
      dwellings: {}
    },
    identification: {},
    general_information: {}
  },
  social: {
    accessibility: {
      chart: {},
      goods_and_services: {},
      transportation: {},
      health_and_edu: {},
      distance: {}
    },
    demographic: {
      migration: {},
      emigration: {},
      immigration: {},
      death: {},
      birth: {},
      vital_statistics: {},
      civil_status: {},
      foreigners: {},
      gender: {},
      ages: {}
    },
    density: {
      occupancy_per_bedroom: {},
      occupancy_per_m2: {},
      occupancy_per_100_m2: {},
      occupancy: {},
      migration: {},
      income: {}
    },
    income_and_taxes: {
      income: {},
      profit: {},
      capital: {},
      wealth: {},
      municipality_tax: {},
      canton_tax: {}
    }
  },
  environmental: {
    energy: {
      idc: {},
      co2: {},
      heating: {},
      solar: {}
    },
    risk: {
      risk: {},
      climate_risk_index: {}
    },
    environment: {
      climate: {
        temperature: {},
        sunlight: {},
        rain: {}
      },
      pollution: {
        rail_noise_day: {},
        rail_noise_night: {},
        road_noise_day: {},
        road_noise_night: {}
      }
    }
  },
  valuation: {
    cap_rate_method: {
      income_inputs: {
        other: {
          suffix: "CHF"
        },
        parking: {},
        office_net_income: {},
        commercial: {},
        industrial: {},
        residential_net_income: {},
        dwelling_net_rent: {}
      },
      cap_rate_inputs: {
        capitalization_rate: {}
      },
      result: {
        total_acquisition_cost: {},
        purchase_price: {}
      },
      description: {}
    },
    parameters: {
      buyer_seller: {
        other_fees_chf: {
          suffix: "CHF"
        },
        other_fees_pts: {
          suffix: "%"
        },
        asset_manager_fees: {},
        share_buyers_seller: {}
      },
      transaction_fees: {
        property_transfert_fees: {},
        land_registration_fees: {},
        notary_fees: {}
      },
      due_diligence_cost: {
        other_valuations: {},
        valuation: {}
      }
    },
    qh_method: {
      result: {
        total_acquisition_cost: {},
        purchase_price: {}
      },
      description: {}
    }
  },
  governance: {
    item: {
      ownership: {},
      certification: {},
      management: {}
    },
    tile: {},
    organisation: {}
  },
  overview: {
    description: {}
  },
  assets: {}
};
var income_statement = {
  label: {
    total_net_income_available_for_distribution: "Gesamtes Nettoeinkommen f\xFCr Verteilung",
    total_expenses: "Gesamtausgaben",
    total_revenues: "Gesamte Einnahmen"
  },
  legend: {
    group: {}
  },
  unit: {}
};
var double_range_input = {};
var overview = {
  property: {}
};
var source_badge = {};
var common = {
  real_estate_firm: {
    pascalcase: "Immobilienfirma"
  },
  foundation: {
    pascalcase: "Anlagestiftungen"
  },
  unlisted_fund: {
    pascalcase: "Unlistedes Fonds"
  },
  listed_fund: {
    pascalcase: "Auflistung von Fonds"
  },
  transactions: {},
  enterprise_state: {},
  developer_mode: {},
  institutions: {},
  connection_lost: {},
  financials: {
    array: {}
  },
  slot: {},
  role: {},
  notification: {
    success: {}
  },
  lots: {},
  buildings: {},
  dwellings: {},
  portfolios: {},
  funds: {}
};
var multiple_select = {
  placeholder: {}
};
var single_select = {};
var screener_bar = {
  main_button: {}
};
var modal = {
  notifications: {},
  downloads: {
    subtitle: {}
  },
  notification: {},
  history: {},
  preset: {},
  historic: {},
  leaving: {
    registry: {},
    buttons: {}
  },
  support: {
    select: {},
    error_message: {},
    success: {}
  }
};
var line_chart = {
  placeholder: {}
};
var empty_chart = {};
var administration = {
  companies: {
    col_name: {},
    headers: {},
    actions: {},
    cells: {}
  },
  collaborators: {
    headers: {}
  },
  breadcrumb: {}
};
var treemap = {
  label: {},
  unit: {}
};
var action = {
  label: {},
  portfolios: {
    name: {
      change_success: {}
    },
    remove: {
      loading: {},
      confirmation: {}
    }
  },
  users: {
    remove: {
      confirmation: {}
    }
  },
  companies: {}
};
var pyramid_chart = {
  label: {}
};
var asset = {
  description: {
    dwellings: {},
    lots: {},
    amenities: {
      dwellings: {},
      buildings: {}
    },
    buildings: {}
  }
};
var errors = {
  fii: {}
};
var buildings = {
  type: {}
};
var items = {
  charts: {
    gradient: {
      subject: {}
    }
  },
  array: {
    columns: {
      title: {}
    }
  }
};
var tile = {
  side_menu: {}
};
var gradient = {
  chart: {},
  label: {}
};
var badge = {};
var valuations = {
  actions: {}
};
var header = {
  navbar: {},
  dropdown_menu: {}
};
var input = {};
var view = {
  switch: {}
};
var unit = {
  chf_per_m2: "CHF/m\xB2"
};
var boarding_page = {
  show_recovery: {
    explain: {},
    label: {},
    button: {}
  },
  register: {
    error: {},
    notification: {
      changed_email: {},
      flow_expiration: {}
    }
  },
  verify_mail: {
    step: {
      sent_email: {
        success: {}
      },
      choose_method: {
        success: {}
      }
    }
  },
  otp: {
    failed: {},
    button: {},
    step: {}
  },
  forgot_password: {
    success: {},
    button: {}
  },
  login: {
    aal2: {},
    aal1: {},
    failed: {}
  },
  recovery_code: {
    faq: {
      lock: {},
      what_is_recovery: {}
    },
    form: {},
    reset: {
      success: {}
    }
  }
};
var policy = {
  cookies: {
    types_list: {}
  },
  eea_rights_list: {},
  data_list: {},
  data_usage: {},
  sharing_list: {}
};
var login = {};
var watchlist = {
  buildings: {},
  dwellings: {}
};
var download = {
  request_created: "Anfrage erstellt."
};
var profile_train = {
  tooltip: {}
};
var impressum = {};
var notification = {
  terminated_session: {}
};
var paywall = {
  button: {},
  message: {}
};
var dashboard = {
  trend: {},
  rent: {},
  indicators: {},
  indices: {},
  error: {
    feature: {}
  },
  empty: {}
};
var widget = {
  side_menu: {}
};
var status = {};
var option = {};
var boarding = {};
var footer = {};
var potfolios = {
  sections: {
    assets: {},
    social: {},
    environmental: {},
    financials: {},
    description: {}
  },
  tiles: {
    charges: {},
    evolution: {},
    revenue: {}
  },
  widget: {
    charges: {},
    revenue: {}
  }
};
var inputs = {
  placeholder: {}
};
var entity_selector = {
  button: {}
};
var error = {
  email: {},
  notfound: {}
};
var users = {
  role: {},
  roles: {}
};
var general = {
  app_wall: {
    redirect: {}
  }
};
var traits = {
  contact: {},
  name: {}
};
var search = {};
var de_default = {
  global,
  units,
  funds,
  portfolios,
  portfolio,
  actions,
  candle_stick_chart,
  balance_sheet,
  screener,
  table_header,
  table,
  setting,
  unsaved_screener,
  management,
  companies,
  asset_page,
  income_statement,
  double_range_input,
  overview,
  source_badge,
  common,
  multiple_select,
  single_select,
  screener_bar,
  modal,
  line_chart,
  empty_chart,
  administration,
  treemap,
  action,
  pyramid_chart,
  asset,
  errors,
  buildings,
  items,
  tile,
  gradient,
  badge,
  valuations,
  header,
  input,
  view,
  unit,
  boarding_page,
  policy,
  login,
  watchlist,
  download,
  profile_train,
  impressum,
  notification,
  paywall,
  dashboard,
  widget,
  status,
  option,
  boarding,
  footer,
  potfolios,
  inputs,
  entity_selector,
  error,
  users,
  general,
  traits,
  search
};

export {
  global,
  units,
  funds,
  portfolios,
  portfolio,
  actions,
  candle_stick_chart,
  balance_sheet,
  screener,
  table_header,
  table,
  setting,
  unsaved_screener,
  management,
  companies,
  asset_page,
  income_statement,
  double_range_input,
  overview,
  source_badge,
  common,
  multiple_select,
  single_select,
  screener_bar,
  modal,
  line_chart,
  empty_chart,
  administration,
  treemap,
  action,
  pyramid_chart,
  asset,
  errors,
  buildings,
  items,
  tile,
  gradient,
  badge,
  valuations,
  header,
  input,
  view,
  unit,
  boarding_page,
  policy,
  login,
  watchlist,
  download,
  profile_train,
  impressum,
  notification,
  paywall,
  dashboard,
  widget,
  status,
  option,
  boarding,
  footer,
  potfolios,
  inputs,
  entity_selector,
  error,
  users,
  general,
  traits,
  search,
  de_default
};
