import { ItemTrendType } from './types';
import '@/scss/components/Tile.css';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import UnitBadge from '../UnitBadge';

export default function TrendItem({ item }: { item: ItemTrendType }) {
  const cols = item.trends.length;
  const { t } = useTranslation();

  const classSuffixs = useMemo(() => {
    return item.trends.map((trend) => {
      if (trend.diff > 0) {
        if (trend.color === 'reverse') return 'red';
        return trend.color || 'green';
      } else if (trend.diff < 0) {
        if (trend.color === 'reverse') return 'green';
        return trend.color || 'red';
      } else {
        if (trend.color === 'reverse') return 'grey';
        return trend.color || 'grey';
      }
    });
  }, [item]);

  return (
    <>
      <span>{t(item.label)}</span>
      <span className={`trend-${cols}`}>
        {item.trends.map((trend, index) => (
          <span className="trend-col" key={index}>
            <span className={`tile-card-list-item-${classSuffixs[index]} diff`}></span>
            <span className="value-string">{trend.value || '-'}</span>
            <UnitBadge unit={trend.suffix} />
          </span>
        ))}
      </span>
    </>
  );
}
