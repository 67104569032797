import '@/scss/components/UnitBadge.css';
import { useTranslation } from 'react-i18next';

export default function UnitBadge(props: {
  unit?: string;
  variant?: 'default' | 'bold';
  color?: string;
}) {
  const { t } = useTranslation();
  const { unit, variant, color } = props;
  if (!unit) return null;
  return (
    <span
      className={`unit-badge ${variant}`}
      style={color ? { backgroundColor: `${color}`, color: 'var(--color-primary-text)' } : {}}
    >
      {t(unit)}
    </span>
  );
}
