import { useEffect } from 'react';
import piaApi from '@/features/pia/piaApi';
import { TrackerTypes } from '@/pages/WatchListPage';
import DropdownElement from './DropdownElement';
import { Skeleton } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export default function DropdownScroll({
  page,
  type,
  setLastFail,
}: {
  page: number;
  type: TrackerTypes;
  setLastFail?: (page: number) => void;
}) {
  const { t } = useTranslation();
  const { data: listItem, isLoading } = piaApi.useGetTrackersQuery({ page, type });

  useEffect(() => {
    if (!listItem?.length) setLastFail?.(page);
    else setLastFail?.(0);
  }, [page, listItem, setLastFail]);

  return (
    <div className={`${!listItem?.length && page !== 1 ? 'hidden' : ''}`}>
      {page === 1 && !isLoading && !listItem?.length && <h4>{t(`watchlist.${type}.empty`)}</h4>}
      {listItem?.map((id: number) => (
        <Skeleton visible={isLoading} key={`${id}-${type}`}>
          <DropdownElement type={type} id={id} />
        </Skeleton>
      ))}
    </div>
  );
}
