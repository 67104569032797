import { useCallback, useMemo, useState } from 'react';
import apiWrapper from '../api';
import { RTKResult } from './fiiErrorParser';
import API from '../api/types';

export default function UseIsDev(enterprise?: API.Enterprise) {
  const [getIsDev, { data: devData, ...rest }] = apiWrapper.useLazyGetCanQuery();
  const [role, setRole] = useState<undefined | 'developer'>();
  const [isDev, setIsDev] = useState<boolean>(false);
  const [checkFinished, setCheckFinished] = useState(false);

  const get = useCallback(async () => {
    const devRes = (await getIsDev(
      {
        namespace: 'developer',
        object: 'developer',
        relation: 'member',
      },
      false
    )) as RTKResult<boolean>;
    if (devRes.data) {
      setCheckFinished(true);
      setRole('developer');
      if (enterprise?.allow_api && devData) {
        setIsDev(true);
        return devRes;
      } else if (!enterprise && devData) {
        setIsDev(true);
        return devRes;
      } else {
        setIsDev(false);
        return devRes;
      }
    } else if (devRes.error) {
      setCheckFinished(true);
      setIsDev(false);
      return devRes;
    } else {
      setCheckFinished(true);
      setIsDev(false);
      return devRes;
    }
  }, [getIsDev, enterprise, devData]);

  const hook = useMemo<[typeof get, { checkFinished: boolean; isDev: typeof isDev } & typeof rest]>(
    () => [get, { checkFinished, isDev, role, ...rest }],
    [get, checkFinished, isDev, role, rest]
  );

  return hook;
}
