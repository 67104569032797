import '@/scss/components/ObjectList.css';
import { ItemStringType } from '../Widget/types';

import { AssemblyReference, Building, Close, Floorplan } from '@carbon/icons-react';
import { useMemo } from 'react';
import { ActionIcon, ScrollArea } from '@mantine/core';
import StringItem from '../Widget/StringItem';
import Pia from '@/features/pia/type';
import { useTranslation } from 'react-i18next';

export default function DropdownCol({
  collapsibleCol,
  deletefunc,
}: {
  collapsibleCol: { type: Pia.AssetTypes; contents: Array<ItemStringType> };
  deletefunc?: ((item: unknown) => void) | undefined;
}) {
  const { t } = useTranslation();
  const [Icon, title] = useMemo(() => {
    if (collapsibleCol.type === 'dwellings') {
      return [<Floorplan />, t('common.dwellings.pascalcase')];
    }
    if (collapsibleCol.type === 'buildings') {
      return [<Building />, t('common.buildings.pascalcase')];
    } else {
      return [<AssemblyReference />, t('common.lots.pascalcase')];
    }
  }, [collapsibleCol, t]);

  return (
    <div className="assets-dropdown-col">
      <div className="col-title">
        {Icon} <span className="col-title-text">{title}</span>
      </div>
      <ScrollArea h={120}>
        {(() => {
          const seenLabels = new Set();
          return collapsibleCol.contents
            .filter((row) => {
              const isUnique = !seenLabels.has(row.value);
              seenLabels.add(row.value);
              return isUnique;
            })
            .map((row, index) => (
              <div className="col-content" key={`content-${index}`}>
                <StringItem item={row} />
                {deletefunc && (
                  <ActionIcon
                    color="dark"
                    onClick={() => deletefunc(row)}
                    variant="transparent"
                    size="xs"
                  >
                    <Close />
                  </ActionIcon>
                )}
              </div>
            ));
        })()}
      </ScrollArea>
    </div>
  );
}
