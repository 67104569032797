import Api from '../index';
import API from '../types';

const downloadApi = Api.enhanceEndpoints({ addTagTypes: ['Downloads'] }).injectEndpoints({
  endpoints: (build) => ({
    listDownloads: build.query<API.DownloadList, { name?: string; type?: string; page?: number }>({
      query: ({ name, type, page }) => ({
        params: { name, type, page },
        url: 'downloads',
      }),
      providesTags: ['Downloads'],
      transformResponse: (data: { downloads: API.DownloadList }) => data.downloads,
    }),
    deleteDownload: build.mutation<void, string>({
      query: (id) => ({ url: `downloads/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Downloads'],
    }),
    refreshDownload: build.mutation<void, string>({
      query: (id) => ({ url: `downloads/${id}`, method: 'PUT' }),
      invalidatesTags: ['Downloads'],
    }),
  }),
});

export default downloadApi;
