import { ObjectListItemType } from '@/components/ObjectList/type';
import Portfolios from './types';
import { formatFullInt, formatPercentToString, verifyNumber } from '@/features/helpers/numbers';
import { Formater } from '@/components/Funds/Detail/helpers';

const portfolioOverviewDescriptionTuple: Array<
  [keyof Portfolios.PortfolioOverview, string, string, Formater]
> = [
  [
    'potential_market_value',
    'portfolios.overview.potential_market_value',
    'units.market_value',
    formatFullInt as Formater,
  ],
  [
    'potential_net_rent',
    'portfolios.overview.potential_net_rent',
    'units.net_rent',
    formatFullInt as Formater,
  ],
  [
    'potential_charges',
    'portfolios.overview.potential_charges',
    'units.charges',
    formatFullInt as Formater,
  ],
  [
    'potential_roa',
    'portfolios.overview.potential_roa',
    'units.roa',
    formatPercentToString as Formater,
  ],
];

const portfolioOverviewLegendTuple: Array<[keyof Portfolios.PortfolioOverview, string]> = [
  ['total_area', 'surface'],
  ['buildings_nb', 'buildings_nb'],
  ['dwellings_nb', 'dwellings_nb'],
];

export function formatOverview(
  portfolio: Portfolios.PortfolioOverview
): Omit<ObjectListItemType, 'icon'> {
  const { id, label: title, type: subtitle } = portfolio;
  const description = portfolioOverviewDescriptionTuple
    .map(([key, label, suffix, formater]) => {
      const value = verifyNumber(portfolio[key]);
      if (!value) return;

      const diff = verifyNumber(portfolio[(key + '_var') as typeof key]) || null;
      return {
        label,
        trends: [
          {
            value: value ? formater(value) : null,
            diff,
            suffix,
          },
        ],
      };
    })
    .filter(Boolean) as ObjectListItemType['description'];

  const legends = portfolioOverviewLegendTuple
    .map(([key, type]) => {
      const value = verifyNumber(portfolio[key]);
      if (!value) return;
      return { type, value };
    })
    .filter(Boolean) as ObjectListItemType['legends'];

  return {
    id,
    type: 'portfolios',
    title,
    subtitle,
    description,
    legends,
  };
}

export function formatOverviews({
  portfolios,
}: {
  portfolios: Array<Portfolios.PortfolioOverview>;
}): Array<Omit<ObjectListItemType, 'icon'>> {
  return portfolios.map(formatOverview);
}
