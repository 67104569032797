import { Outlet, useLocation, useNavigate, Location as ReactLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { selectSession } from '@/features/kratos/kratosSlice';
import { selectEnterprise } from '@/features/keto/ketoSlice';
import UseModalRoutes from '@/routes/Modals';
import { appendNewPath } from '@/features/api/navigationSlice';
import UseTitle from '@/features/helpers/UseTitle';
import { WebSocketProvider } from '@/features/contexts/websockets/WebSocketContext';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { PortalProps } from '@mantine/core';

export default function ParentLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const session = useAppSelector(selectSession);
  const enterprise = useAppSelector(selectEnterprise);
  const dispatch = useAppDispatch();

  UseTitle(location);

  const state = useMemo(() => {
    return location.state as { foregroundLocation?: ReactLocation };
  }, [location]);

  useEffect(() => {
    if (
      session?.status === 'fulfilled' &&
      ['outScope', 'slotOutScope'].includes(enterprise.state) &&
      location.pathname !== '/'
    ) {
      navigate(location, { state: { foregroundLocation: '/out-of-scope' } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, enterprise, navigate]);

  useEffect(() => {
    dispatch(appendNewPath(location.pathname));
  }, [location.pathname, dispatch]);

  return (
    <WebSocketProvider>
      <ModalsProvider>
        <Notifications
          position="bottom-right"
          zIndex={501}
          autoClose={3 * 1000}
          limit={3}
          portalProps={{ target: '.notifications-wrapper' } as PortalProps}
        />
        <Outlet />
        <UseModalRoutes foregroundLocation={state?.foregroundLocation} />
      </ModalsProvider>
    </WebSocketProvider>
  );
}
