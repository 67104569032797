import UnitBadge from '../UnitBadge';
import { ItemStringType } from './types';
import '@/scss/components/Tile.css';
import EllipsisText from '../EllipsisText';

export default function StringItem({ item }: { item: ItemStringType }) {
  return (
    <>
      {item.label && <EllipsisText label={item.label} />}
      <span
        className={[
          item?.label && 'value-string',
          item?.color && `tile-card-list-item-${item.color}`,
        ]
          .filter(Boolean)
          .join(' ')}
      >
        {item.value ?? '-'}
      </span>
      <UnitBadge unit={item.suffix} />
    </>
  );
}
