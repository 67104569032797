import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location as ReactLocation } from 'react-router-dom';

interface HookProps {
  element?: string;
  path?: string;
}

const app = 'quanthome_';
const sep = ' | ';
const dvd = ' ‒ ';
const rex = /((?<element>([^‒]{1,}) )‒ )?((?<path>([^|]{1,})) \| )?quanthome_/gi;

const recongnizedDirectory = [
  { label: 'header.navbar.portfolios', value: 'portfolios' },
  { label: 'common.buildings.pascalcase', value: 'buildings' },
  { label: 'common.dwellings.pascalcase', value: 'dwellings' },
  { label: 'common.lots.pascalcase', value: 'lots' },
  { label: 'header.navbar.map', value: 'map' },
  { label: 'header.navbar.funds', value: 'funds' },
  { label: 'header.navbar.screener', value: 'real-estate' },
  { label: 'header.navbar.watchlist', value: 'watchlist' },
  { label: 'header.dropdown_menu.settings', value: 'settings' },
  { label: 'header.dropdown_menu.back_office', value: 'administration' },
];

export default function UseTitle(location?: ReactLocation) {
  const { t } = useTranslation();
  const [element, setElement] = useState<string | undefined>();
  const [path, setPath] = useState<string | undefined>();
  const [prevdir, setPrevDir] = useState<string | undefined>();

  useEffect(() => {
    const result = rex.exec(document.title);
    const dir = result?.groups?.['path'];
    if (dir) setPrevDir(dir);
  }, [element, path]);

  const dir = useMemo(() => {
    if (path) return t(path);
    if (location) {
      const { pathname } = location;
      const paths = pathname.split('/').filter(Boolean);
      const directory = recongnizedDirectory.find(({ value }) => paths.includes(value));
      if (directory) return t(directory.label);
      return undefined;
    }
    return prevdir;
  }, [location, t, path, prevdir]);

  useEffect(() => {
    if (!location && !element && !path) return;
    const first = [element, dir].filter(Boolean).join(dvd);
    const title = [first, app].filter(Boolean).join(sep);
    document.title = title;
  }, [dir, path, element, location]);

  const updateTitle = useCallback(
    (props?: HookProps) => {
      setElement(props?.element);
      setPath(props?.path);
    },
    [setElement, setPath]
  );

  return updateTitle;
}
