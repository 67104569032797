import '@/scss/components/ObjectList.css';
import { Accordion } from '@mantine/core';
import ObjectListItem from './ObjectListItem';
import { CollapsibleObj, ObjectListItemType, ObjectListType } from './type';
import portfolioApi from '@/features/pia/portfolios';
import Pia from '@/features/pia/type';
import assetFundsApi from '@/features/pia/funds/asset';
import piaApi from '@/features/pia/piaApi';
import { useCallback } from 'react';
import { ItemStringType } from '../Widget/types';

export default function ObjectList(data: ObjectListType) {
  const [getPortfolioBuildings] = portfolioApi.useLazyGetBuildingOfPortfolioQuery();

  const [getPortfolioDwellings] = portfolioApi.useLazyGetDwellingOfPortfolioQuery();

  const [getFundBuildings] = assetFundsApi.useLazyGetBuildingGroupsQuery();

  const [getFundDwellings] = assetFundsApi.useLazyGetFundDwellingsQuery();

  const [getDwellingsOfBuilding] = piaApi.useLazyGetDwellingOfBuildingQuery();

  const handlePrefetch: (item: ObjectListItemType) => Promise<CollapsibleObj> = useCallback(
    async (item: ObjectListItemType) => {
      if (item.type === 'buildings') {
        const dwellings = await getDwellingsOfBuilding(item.id, true)
          .unwrap()
          .then((res) => {
            return [
              {
                type: 'dwellings' as Pia.AssetTypes,
                contents: res?.map((item) => {
                  return {
                    value:
                      typeof item.street_short === 'string'
                        ? item.street_short
                        : item.street_short.label,
                  };
                }) as ItemStringType[],
              },
            ];
          });
        return dwellings;
      } else if (item.type === 'portfolios') {
        const buildings = await getPortfolioBuildings({ id: item.id }, true)
          .unwrap()
          .then((res) => {
            return {
              type: 'buildings' as Pia.AssetTypes,
              contents: res?.map((item) => {
                return {
                  value:
                    typeof item.street_short === 'string'
                      ? item.street_short
                      : item.street_short.label,
                };
              }) as ItemStringType[],
            };
          });
        const dwellings = await getPortfolioDwellings({ id: item.id }, true)
          .unwrap()
          .then((res) => {
            return {
              type: 'dwellings' as Pia.AssetTypes,
              contents: res?.map((item) => {
                return {
                  value:
                    typeof item.street_short === 'string'
                      ? item.street_short
                      : item.street_short.label,
                };
              }) as ItemStringType[],
            };
          });
        return [buildings, dwellings];
      } else if (item.type === 'funds') {
        const buildings = await getFundBuildings({ id: item.id }, true)
          .unwrap()
          .then((res) => {
            return {
              type: 'buildings' as Pia.AssetTypes,
              contents: res?.map((item) => {
                return { value: item.label };
              }) as ItemStringType[],
            };
          });
        const dwellings = await getFundDwellings({ id: item.id }, true)
          .unwrap()
          .then((res) => {
            return {
              type: 'dwellings' as Pia.AssetTypes,
              contents: res?.map((item) => {
                return {
                  value:
                    typeof item.street_short === 'string'
                      ? item.street_short
                      : item.street_short.label,
                };
              }) as ItemStringType[],
            };
          });
        return [buildings, dwellings];
      } else return [];
    },
    [
      getDwellingsOfBuilding,
      getFundBuildings,
      getFundDwellings,
      getPortfolioBuildings,
      getPortfolioDwellings,
    ]
  );

  return (
    <Accordion className="object-list" defaultValue="customization" transitionDuration={300}>
      {data.objectItems.map((item) => {
        return (
          <ObjectListItem
            onOpen={async () => await handlePrefetch(item)}
            item={item}
            openableChildren={data.openableChildren}
            actions={data.actions}
            deleteFunction={data.deleteFunction}
            key={item.id}
          />
        );
      })}
    </Accordion>
  );
}
