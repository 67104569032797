import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { expireSession, deleteSession } from '../kratos/kratosSlice';
import { parseRTKError } from './fiiErrorParser';
import { Mutex, MutexInterface } from 'async-mutex';
import {
  setEnterpriseEnded,
  setEnterpriseEndTrial,
  setEnterpriseNoSlot,
  setEnterpriseUnpaid,
} from '../keto/ketoSlice';
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import sleep from './sleep';

export const appMutex = new Mutex();
const mutexWaiter = new Mutex();

const sessionCodes = ['no_session', 'session_inactive', 'session_refresh_required'];
const enterprisesCodes = [
  'unpaid_bill',
  'end_of_trial',
  'no_sub_record',
  'no_enterprise',
  'no_subscription_found',
  'ended_state',
];

const ddosLimiter = new Mutex();

async function waitDdos(time: number) {
  // If another request is waiting for the release return
  if (ddosLimiter.isLocked()) return;
  // Lock the ddos mutex
  const release = await ddosLimiter.acquire();
  setTimeout(release, time);
}

export default function fetchCustomQuery(options?: FetchBaseQueryArgs) {
  const baseQuery = fetchBaseQuery(options);

  // Separate function to perform the query and handle retries on 'TooManyRequestsError'
  async function performQueryWithRetry(
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions?: { bypassMutex?: boolean; oneAtATime?: boolean },
    retryCount = 0
  ) {
    const result = await baseQuery(args, api, extraOptions || {});
    // Handling 'TooManyRequestsError'
    if (result.error?.status === 429) {
      const headers = result.meta?.response?.headers;
      if (headers) {
        const resetTime =
          headers.get('Rate-Limit-Reset-Registered') || headers.get('Rate-Limit-Reset');
        if (resetTime) {
          const waitTime = Number(resetTime) * 1000 - Date.now();
          await waitDdos(waitTime);
          // Wait that another request unlock the mutex to pass
          await ddosLimiter.waitForUnlock();
          // Recursively call the function to retry the query
          return performQueryWithRetry(args, api, extraOptions, retryCount + 1);
        }
      }
    }
    return result;
  }

  const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions?: { bypassMutex?: boolean; oneAtATime?: boolean }
  ) => {
    let release: MutexInterface.Releaser | null = null;
    // Wait that all request are not block by a 429 to try the request
    await ddosLimiter.waitForUnlock();
    if (extraOptions?.oneAtATime) {
      await mutexWaiter.waitForUnlock();
      release = await mutexWaiter.acquire();
    }
    if (!extraOptions?.bypassMutex) await appMutex.waitForUnlock();

    let result = await performQueryWithRetry(args, api, extraOptions);

    if (result.error) {
      // const statusCode = result.error?.status;
      const error = parseRTKError(result.error);
      if (error?.code && sessionCodes.includes(`${error.code}`)) {
        // Expire session and lock Mutex
        if (error.code === 'session_refresh_required') api.dispatch(expireSession());
        else if (error.code === 'no_session' || error.code === 'session_inactive')
          api.dispatch(deleteSession());

        // Wait for reconnection (unlocked mutex)
        await appMutex.waitForUnlock();

        // Retry query
        const result = await baseQuery(args, api, extraOptions || {});
        release?.();
        return result;
      }

      if (error?.code === 'TooManyRequestsError') {
        const headers = result.meta?.response?.headers;
        if (headers) {
          // Access specific headers
          const reset_date = new Date(
            Number(headers.get('Rate-Limit-Reset-Registered') || headers.get('Rate-Limit-Reset')) *
              1000
          );
          await sleep(reset_date.valueOf() - Date.now().valueOf());
          const result = await baseQuery(args, api, extraOptions || {});
          release?.();
          return result;
        }
      }

      if (error?.code && enterprisesCodes.includes(`${error.code}`)) {
        if (error.code == 'end_of_trial') api.dispatch(setEnterpriseEndTrial());
        else if (error.code == 'ended_state') api.dispatch(setEnterpriseEnded());
        else api.dispatch(setEnterpriseUnpaid());
      }

      if (error?.code && error.code == 'no_slot') api.dispatch(setEnterpriseNoSlot());
    }
    release?.();
    return result;
  };

  return baseQueryWithReauth;
}
