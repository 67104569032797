import { notifications } from '@mantine/notifications';
import React, { createContext, useContext, ReactNode } from 'react';
import useWebSocket from 'react-use-websocket';
import { WebSocketContextValue } from './types';
import { useAppSelector } from '@/app/hooks';
import { selectSession } from '@/features/kratos/kratosSlice';
import { Button } from '@mantine/core';
import { handleNotification } from '@/features/notifications';
import API from '@/features/api/types';
import { useTranslation } from 'react-i18next';

const { VITE_WS_URI } = import.meta.env;

//creating context to use ws in global app
export const WebSocketContext = createContext<WebSocketContextValue | undefined>(undefined);

interface WebSocketProviderProps {
  children: ReactNode;
}

const MAXRETRY = 2;

function parseJson<R extends unknown>(str: string): R | undefined {
  try {
    return JSON.parse(str) as R;
  } catch {}
}

//config of ws hook
export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const socketUrl = VITE_WS_URI;
  const session = useAppSelector(selectSession);
  const { t } = useTranslation();

  const { sendMessage, lastMessage, lastJsonMessage, readyState, sendJsonMessage } = useWebSocket(
    socketUrl,
    {
      onMessage: (MessageEvent) => {
        // Try JSON parse:
        const data = parseJson<API.Notification>(MessageEvent.data);
        if (data) {
          handleNotification(data);
        }
      },
      share: true,
      shouldReconnect: (reconnectEvent) => {
        return !reconnectEvent.wasClean;
      },
      reconnectInterval: 5000,
      reconnectAttempts: MAXRETRY,
      retryOnError: true,
      onError: () => {},
      onReconnectStop: (attemptCount) => {
        if (MAXRETRY === attemptCount) {
          notifications.show({
            message: (
              <div>
                <h6>{t('common.connection_lost.message_1')}</h6>
                <h6>{t('common.connection_lost.message_2')}</h6>
                <h6>{t('common.connection_lost.message_3')}</h6>
                <br />
                <Button onClick={() => location.reload()}>
                  {t('common.connection_lost.reload_button')}
                </Button>
              </div>
            ),
            title: t('common.connection_lost.title'),
            color: 'red',
            radius: 'sm',
            autoClose: false,
          });
        }
      },
    },
    session?.status === 'fulfilled'
  );

  return (
    <WebSocketContext.Provider
      value={{
        sendMessage,
        lastMessage,
        readyState,
        lastJsonMessage,
        sendJsonMessage,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useWebSocketContext = (): WebSocketContextValue => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocketContext must be used within a WebSocketProvider');
  }
  return context;
};
